// prettier-ignore-start
import ActionCommentStatus from "./Base/ActionCommentStatus";
import ActionMessageForm   from "./Base/Forms/ActionMessageForm";
import ActionFormType      from "./ActionFormType";


const ActionMessageFormComment = () => {

    const common = ActionCommentStatus();


    return {
        type    : ActionFormType.popupForm,

        data     : common.data,
        formCols : common.formCols,
        form     : (props) => ActionMessageForm().form(props, common.formCols),
    }

};

export default ActionMessageFormComment;
