// prettier-ignore-start
import ActionFormType  from "./ActionFormType";
import { getPath }     from "../../../api/methods";
import { PATHS }       from "../../../constants/RoutesPaths";


const ActionMessageFormAppDataAnswersContinue = () => {
    return {
        type    : ActionFormType.gotoLocation,
        location: (app) => {
            const { id , payer_id, category_id, form_id } = app;

            return getPath(PATHS.orderNewSt4Form, {payer_id, category_id, form_id, application_id: id} )
        },
    }
};

export default ActionMessageFormAppDataAnswersContinue;
