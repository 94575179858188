// prettier-ignore-start
import { Company, SoftwareProduct } from "../../../../api/methods";
import { dateToday, isNotEmpty }    from "../../../../functions/common";
import LIT                          from "../../../../components/Lists/ListItemTypes";


const ActionAppDataContract = () => {

    const f_app_id              = 'id';                    // id заявки
    const f_deadline            = 'deadline';              //
    const f_work_deadline       = 'work_deadline';         // Срок для исполнителя
    const f_executor_company_id = 'executor_company_id';   // организация-исполнитель по договору
    const f_software_product_id = 'software_product_id';   // Программный комплекс
    const f_rooms_count         = 'rooms_count';
    const f_props               = 'props';

    const propTypeText          = 0;
    const propTypeSelect        = 1;

    const getPropName = (prop) => f_props +'['+ prop.id+']';
    const getDate = (date) => (date ? (date === 0 ? dateToday(): date ) : dateToday() );

    const data = (app, field = null) => {
        const result = {
            [f_app_id             ] : app[f_app_id             ] ?? 0,
            [f_deadline           ] : app[f_deadline           ] ?? 0,
            [f_work_deadline      ] : app[f_work_deadline      ] ?? 0,
            [f_executor_company_id] : app[f_executor_company_id] ?? null,
            [f_software_product_id] : app[f_software_product_id] ?? null,
            [f_rooms_count        ] : app[f_rooms_count        ] ?? 0,
            [f_props              ] : app[f_props              ] ?? [],
        }

        let app_props = {};
        if (app[f_props])
            app[f_props].map((prop) => {
                app_props = {...app_props, [getPropName(prop)] : prop.value_id}
            });

        return {...result, ...app_props};
    };

    const setExecutorCompaniesOptions = (success, fail, data) => Company        .listSelectOnApp({ app_id: data[ f_app_id ] }, ({data}) => success(data), fail);
    const setSoftwareProductsOptions  = (success, fail, data) => SoftwareProduct.getAllSimple   ({}, ({data}) => success(data), fail);


    const formCols = (props) => {
        let app_props = [];
        if (props.data && props?.data[f_props])
            props.data[f_props].map((prop) => {
                let result = {
                    name   : getPropName(prop),
                    title  : prop.title + (prop.is_required ? '*' : ''),
                    isValid: (val) => (! prop.is_required) || (prop.is_required && isNotEmpty(val))
                };

                if (prop.type_id === propTypeText  ) result = {...result, type: LIT.text  };
                if (prop.type_id === propTypeSelect) result = {...result, type: LIT.select, getOptions: (success, fail, data) => {
                    success( prop.options.map( ({id, title}) => ({id, name: title}) ) );
                }};

                app_props.push(result);
            });

        return [
            { name: f_executor_company_id, title: 'организация-исполнитель по договору', type: LIT.select         , isValid: (val) => isNotEmpty(val), getOptions: setExecutorCompaniesOptions },
            { name: f_software_product_id, title: 'программный комплекс'               , type: LIT.select         , isValid: (val) => isNotEmpty(val), getOptions: setSoftwareProductsOptions  },
            ...app_props,
            { name: f_rooms_count        , title: 'Кол-во помещений/наруж.установок'   , type: LIT.number_positive, isValid: (val) => isNotEmpty(val) && (val >= 0) },
            { name: f_deadline           , title: 'срок по договору, дней'             , type: LIT.number_positive, isValid: (val) => isNotEmpty(val) && (val > 0) },
            { name: f_work_deadline      , title: 'срок для исполнителя, дней'         , type: LIT.number_positive, isValid: (val) => isNotEmpty(val) && (val > 0) },
        ]
    }

    const prepareDataBeforeSave = (data) => {
        let result = {...data};
        delete result[f_props];

        return result;
    }

    return {
        data     : data,
        formCols : formCols,
        onSave   : prepareDataBeforeSave,
    }

};

export default ActionAppDataContract;
