import {useEffect}      from "react";
import {Application}    from "../api/methods";
import {useLoadRequest} from "./useDataRequest";
import {
    AccessRightType,
    useAccessRights,
    UseAccessRightsType
}                       from "./useAccessRights";


export const useAppAccessRights: (appId: number, defaultAccessRightValue: AccessRightType) => UseAccessRightsType = (appId, defaultAccessRightValue) => {

    const appAccessRights = useLoadRequest({
        doNow      : false,
        request    : Application.accessRights,
        requestData: { appId },
    })

    useEffect(() => {
        if (appId) appAccessRights.load();
    }, [appId]);


    const accessRights: UseAccessRightsType = useAccessRights(appAccessRights.data, defaultAccessRightValue);


    return {
        ...accessRights
    };

};
