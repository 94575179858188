// prettier-ignore-start
import React from "react";

import settingsTabType                      from "../tabType";
import SettingsSystemRolesList              from "./SettingsSystemRolesList";
import SettingsSystemRoleAccessRights       from "./SettingsSystemRoleAccessRights";
import SettingsSystemRoleStatuses           from "./SettingsSystemRoleStatuses";
import SettingsSystemRoleStatusActions      from "./SettingsSystemRoleStatusActions";
import SettingsSystemRoleStatusAccessRights from "./SettingsSystemRoleStatusAccessRights";


const SettingsSystemRoles = ({ sub = settingsTabType.systemRoles }) => {

    if (sub === settingsTabType.systemRoleAccessRights      ) return <SettingsSystemRoleAccessRights       />
    if (sub === settingsTabType.systemRoleStatuses          ) return <SettingsSystemRoleStatuses           />
    if (sub === settingsTabType.systemRoleStatusActions     ) return <SettingsSystemRoleStatusActions      />
    if (sub === settingsTabType.systemRoleStatusAccessRights) return <SettingsSystemRoleStatusAccessRights />

    return <SettingsSystemRolesList />
};

export default SettingsSystemRoles;
