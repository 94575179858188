// prettier-ignore-start
import React from "react";

import settingsTabType                from "../tabType";
import SettingsSystemUsersList        from "./SettingsSystemUsersList";
import SettingsSystemUserAccessRights from "./SettingsSystemUserAccessRights";


const SettingsSystemUsers = ({ sub = settingsTabType.systemUsers }) => {

    if (sub === settingsTabType.systemUserAccessRights) return <SettingsSystemUserAccessRights />

    return <SettingsSystemUsersList/>
}

export default SettingsSystemUsers;
