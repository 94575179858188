// prettier-ignore-start
import React              from 'react';

import ReactDOM           from "react-dom";
import { useHistory }     from "react-router-dom";

import {
    useAppDispatch,
    useAppSelector
}                         from "../../hooks/useRedux";
import { setPopUpFields } from "../../store/bonusesPopUp/actions";
import Button             from "../UI-kit/Buttons/Button";
import Text               from "../UI-kit/Text";
import Alert              from "./Alert";


function PopUp() {
    const values = useAppSelector(state => state.bonusesPopUp)
    const history = useHistory()
    const dispatch = useAppDispatch()
    const popUpNode = document.getElementById("popUp") as HTMLElement

    const handleClick = () => {
        if (values.reload) {
            history.go(0)
        } else {
            dispatch(setPopUpFields({visible: false, reload: false}))
        }
    }

    const button = <Button onClick={handleClick}>Закрыть</Button>

    return ReactDOM.createPortal(
        <Alert title={values.title} isShow={values.visible} buttons={button}>
            <Text>{values.msg}</Text>
        </Alert>,
        popUpNode
    );
}


export default PopUp;