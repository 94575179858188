// prettier-ignore-start
import LIT                       from "../../../../components/Lists/ListItemTypes";
import { dateToday, isNotEmpty } from "../../../../functions/common";


const ActionAppDataPayment = () => {

    const f_app_id       = 'app_id';            // id заявки
    const f_payment_date = 'date';              // дата платежа
    const f_payment_sum  = 'sum';               // сумма платежа
    const f_comment      = 'payment_comment';   // комментарий

    const data = (app, field = null) => ({
        [f_app_id]       : app['id'] ?? null,
        [f_payment_sum]  : 0,
        [f_payment_date] : dateToday(),
        [f_comment]      : '',
    });

    const formCols = (props) => ([
        { name: f_payment_date, title: 'дата платежа'     , type: LIT.date    , isValid: (val) => isNotEmpty(val) },
        { name: f_payment_sum , title: 'сумма платежа, р.', type: LIT.number  , isValid: (val) => isNotEmpty(val) }, //  && (val >= 0) - убрано для возможности корректировки в случае ошибок ввода
        { name: f_comment     , title: 'комментарий'      , type: LIT.textarea },
    ]);


    return {
        data     : data,
        formCols : formCols
    }

};

export default ActionAppDataPayment;
