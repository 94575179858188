import React from "react";
import styled from "styled-components";

import {accent, gray, lightgray, white} from "../../constants/Colours";
import { device } from "../../constants/Breakpoints";

const Input = styled.input`
  appearance: none;
  margin: 0;
  position: absolute;
`;

type LabelProps = {
  checked: boolean;
  disabled: boolean;
};

const Label = styled.label<LabelProps>`
  display: flex;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  height: 21px;
  font-size: 16px;
  vertical-align: middle;
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 23px;
    height: 23px;
    border: 1px solid ${lightgray};
    border-radius: 4px;
    transition: all 0.2s ease;
    opacity: ${props => props.disabled ? 0.6 : 1};
    background: ${(props) => (props.disabled && props.checked) ? gray : 
        (!props.checked && props.disabled) ? "none" : 
            (props.checked && !props.disabled) ? accent : "none"};
  }
  ::after {
    transition: all 0.2s ease;
    content: '✓';
    display: block;
    opacity: ${props => props.checked ? 1 : 0};
    color: ${white};
    
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(-50%, -50%);
  }
  @media ${device.laptop} {
    margin: 15px 0;
    padding-left: 35px;
  }
  @media ${device.tablet} {
    max-width: 300px;
    margin: 20px 0;
  }
`;

type InputCheckbox = {
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  name?: string;
  disabled?: boolean;
};

const InputCheckbox: React.FC<InputCheckbox> = ({
  placeholder = "",
  onChange,
  checked = false,
  name = "",
  disabled= false
}) => (
  <Label checked={checked} disabled={disabled}>
    <Input type="checkbox" onChange={onChange} checked={checked} name={name} disabled={disabled} />
    <span>{placeholder}</span>
  </Label>
);

export default InputCheckbox;
