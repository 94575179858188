import { child, element } from 'models/elements.model';
import { answerObj, userAnswer, userAnswers } from 'models/userAnswers.model';
import { isEmptyString, isNullOrUndefined } from './common/common';

function getAnswerInfo(element: element, answer: string) {
  const { name, parent_id, system_name_id } = element;

  const result: userAnswer = {
    Title: name,
    parentId: parent_id,
    answer,
  };

  if (system_name_id) {
    result.system_name_id = system_name_id;
  }

  return result;
}

function isEmptyArray(
  array: (answerObj | string)[],
  requiredElements: element[],
  requiredId: string
) {
  let result = false;
  if (!array.length) {
    result = true;
  }
  array.forEach((item) => {
    if (typeof item === 'string') {
      return;
    }

    const isCorrect = isCorrectObjectCheck(
      item,
      requiredElements.find((i) => requiredId === i.id)
    );
    if (!isCorrect) {
      result = true;
    }
  });
  return result;
}

function getRequiredEmptyFieldsIds(
  elements: element[],
  userAnswers: userAnswers
) {
  const emptyRequiredElementsIds: string[] = [];
  const findRequiredElements = findRequiredElementsDecorator();
  const requiredElements = findRequiredElements(elements);
  const requiredElementsIds = requiredElements.map((i) => i.id);

  requiredElementsIds.forEach((requiredId) => {
    const value = ((userAnswers[requiredId] as userAnswer)?.answer );

    // проверка что вообще ничего нет
    if (isNullOrUndefined(value)) {
      emptyRequiredElementsIds.push(requiredId);
      return;
    }

    // проверка пустой строки
    if (typeof value === 'string') {
      if (isEmptyString(value)) {
        emptyRequiredElementsIds.push(requiredId);
      }
      return;
    }

    // проверка массива объектов
    if (Array.isArray(value)) {
      if (isEmptyArray(value, requiredElements, requiredId)) {
        emptyRequiredElementsIds.push(requiredId);
      }
      value.forEach((obj) => {
        if (typeof obj == "string") {
          if (isEmptyString(obj)) {
            emptyRequiredElementsIds.push(requiredId);
          }
          return;
        }

        const isCorrect = isCorrectObjectCheck(
          obj,
          requiredElements.find((i) => requiredId === i.id)
        );
        if (!isCorrect) {
          emptyRequiredElementsIds.push(requiredId);
          return;
        }
      });
      return;
    }

    // Проверка объекта
    if (
      !isCorrectObjectCheck(
        value,
        requiredElements.find((i) => requiredId === i.id)
      )
    ) {
      emptyRequiredElementsIds.push(requiredId);
    }
  });
  return emptyRequiredElementsIds;
}

function isCorrectObjectCheck(obj: answerObj, element: element | undefined) {
  if (element) {
    const type = element.type;
    if (type === 8) {
      const keys = Object.keys(obj);
      return (
        !!obj['file'] ||
        keys.reduce((acc, curr) => {
          return acc + (obj[curr] ? 1 : 0);
        }, 0) === element.childrens.length
      );
    }
    return Object.values(obj).some((i) => i);
  }
  return false;
}

function findRequiredElementsDecorator() {
  const requiredElements: element[] = [];
  const findRequiredElements = (elements: element[]) => {
    elements.forEach((element) => {
      const { id, required, childrens } = element;
      const isRequired = required === 1;

      const isHidden = isDOMElementHidden(id);

      if (isRequired && !isHidden) {
        requiredElements.push(element);
      }

      const findFromChildren = ({ elems }: child) => {
        findRequiredElements(elems);
      };

      childrens.forEach(findFromChildren);
    });
    return requiredElements;
  };
  return findRequiredElements;
}

function isDOMElementHidden(elementId: string) {
  const element = document.querySelector(`div[data-id="${elementId}"]`);
  if (element == null) return true;
  return false;
}

function elementsDOMIdsInRightOrder() {
  const elements = document.querySelectorAll<HTMLElement>(`.element`);

  const elementsOrder: { [key: string]: number } = {};

  elements.forEach((el, index) => {
    const { id } = el.dataset as { id: string };

    elementsOrder[id] = index;
  });
  return elementsOrder;
}

function saveElementsOrder(userAnswers: userAnswers) {
  const orderOfElements = elementsDOMIdsInRightOrder();

  Object.keys(userAnswers).map((id) => {
    if (orderOfElements[id] === undefined) {
      delete (userAnswers[id] as userAnswer).orderIndex
    }
  });

  Object.keys(orderOfElements).map((id) => {
    const index = orderOfElements[id];
    const answer = userAnswers[id] as userAnswer;

    if (answer) {
      answer.orderIndex = index;
    }
  });
}

function cleanUnansweredElements(userAnswers: userAnswers) {
  Object.keys(userAnswers).map((id) => {
    const answer = userAnswers[id] as userAnswer;

    if (answer.answer === undefined || answer.answer === null) delete userAnswers[id];
  });
}



export default {
  getAnswerInfo,
  getRequiredEmptyFieldsIds,
  // markElementBySystemName,
  // findElementById,
  saveElementsOrder,
  cleanUnansweredElements,
};

// function markElementBySystemName(userAnswers: userAnswers, systemNames) {
//   const keys = Object.keys(userAnswers);
//   return keys.map((elementId) => {
//     const value = userAnswers[elementId];
//     const { Title } = value;

//     const systemNameIndex = systemNames.findIndex(
//       ({ title }) => title == Title
//     );

//     if (systemNameIndex != -1) {
//       userAnswers[elementId].system_name_id = systemNames[systemNameIndex].id;
//     }
//   });
// }

// function findElementById(elements: element[], idForSearch: string) {
//   let returnedElement = {};

//   let wasFounded = false;
//   elements.forEach((element) => {
//     const { id, childrens } = element;

//     if (wasFounded) {
//       return;
//     }

//     if (id == idForSearch) {
//       returnedElement = element;
//       wasFounded = true;
//       return;
//     }

//     const findFromChildren = ({ elems }: child) => {
//       returnedElement = findElementById(elems, idForSearch);
//     };

//     childrens.forEach(findFromChildren);
//   });
//   return returnedElement;
// }
