// prettier-ignore-start
import React from "react";

import { useParams } from "react-router-dom";

import {
    SystemAccessRightsAssigned,
    SystemStatuses
}                                              from "../../../api/methods";
import { PATHS }                               from "../../../constants/RoutesPaths";
import SettingsSystemAccessRightValuesAssigned from "./SettingsSystemAccessRightValuesAssigned";
import { useLoadRequest }                      from "../../../hooks/useDataRequest";


const SettingsSystemStatusAccessRights = () => {

    const { status_id } = useParams();

    const status = useLoadRequest({
        request     : SystemStatuses.getById,
        requestData : { id: status_id },
    });


    return <SettingsSystemAccessRightValuesAssigned
        type        = {'app.status'}

        title       = {<>Права на статусе "{status.data.title ?? '-'}"</>}
        backTo      = {PATHS.settings.systemStatuses}

        getListData = {(data, success, fail) => SystemAccessRightsAssigned.getByStatusId({status_id         }, success, fail)}
        onNew       = {(data, success, fail) => SystemAccessRightsAssigned.addToStatus  ({...data, status_id}, success, fail)}
    />

};

export default SettingsSystemStatusAccessRights;
