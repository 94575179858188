// prettier-ignore-start
import React from "react";

import { useParams }      from "react-router-dom";

import {
    getPath,
    SystemAccessRightsAssigned,
    SystemRoleStatuses
}                         from "../../../api/methods";
import { PATHS }          from "../../../constants/RoutesPaths";
import { useLoadRequest } from "../../../hooks/useDataRequest";

import SettingsSystemAccessRightValuesAssigned from "./SettingsSystemAccessRightValuesAssigned";


const SettingsSystemRoleStatusAccessRights = () => {
    const { role_status_id } = useParams();

    const roleStatus = useLoadRequest({
        request     : SystemRoleStatuses.getById,
        requestData : { id: role_status_id },
    });


    return <SettingsSystemAccessRightValuesAssigned
        type        = {'app.role_status'}
        title       = {<>Роль "{roleStatus.data.role?.title ?? '-'}" / статус "{roleStatus.data?.title ?? '-'}" / Права доступа:</>}
        backTo      = {(roleStatus.data?.id && getPath(PATHS.settings.systemRoleStatuses, {role_id: roleStatus.data?.role_id}))}

        getListData = {(data, success, fail) => SystemAccessRightsAssigned.getByRoleStatusId({role_status_id         }, success, fail)}
        onNew       = {(data, success, fail) => SystemAccessRightsAssigned.addToRoleStatus  ({...data, role_status_id}, success, fail)}
    />

};

export default SettingsSystemRoleStatusAccessRights;
