import styled from "styled-components";


// prettier-ignore-start

export const TopRightLabel = styled.span`
  display   : inline-block;
  font-size : smaller;
  position  : absolute;
  top       : 0px;
  right     : 0px;
  background: none;
  margin    : 0px 2px 0px 0px !important;
  padding   : 0px 0px 0px 0px !important;
`;


