import styled from "styled-components";
import { accent } from "../../../constants/Colours";

export const Preloader = styled.div`
  display: block;
  width: 80px;
  height: 80px;
  margin: 40px 40px;
  :after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${accent};
    border-color: ${accent} transparent ${accent} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
