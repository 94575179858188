import React, {useState} from "react";

import Subtitle2 from "../../UI-kit/Subtitle2";
import Button from "../../UI-kit/Buttons/Button";

import ThirdOrderButton from "../../UI-kit/Buttons/ThirdOrderButton";

// prettier-ignore-start

/*
// ВВОДНЫЕ (ПРИМЕР)

  //   NB!!не использовать имя поля = 'file' - системный флаг загрузки нового файла/файлов
  print_file : { type: fieldType.fileImage, dataName: 'print_file', text: T.addCompanyStamp}, // скан печати
*/

export const getFormFileImageUrlKeyName =
    (key) =>
        key + '_url';

export const getImageFullURL =
    (filename) =>
        process.env.REACT_APP_API_URL.replace("/api/", "") + filename;

// вывод поля файла с изображением
const FormFileImage = ({
   name = '',
   dataName = '',
   text = '',
   data = [],
   updateData = (newData) => {},
   onChange = () => {},
   onBlur = () => {},
   props = {},
}) => {
    const title = text ?? '';

    const imageNewSrc   = data[dataName] ?? '';
    const imageNewIsSet = imageNewSrc !== '';

    const [imageNewSrcURL, setImageNewSrcURL] = useState('');

    const imageSrc      = data[getFormFileImageUrlKeyName(dataName)] ?? '';
    const imageIsSet    = imageSrc !== '';

    const imageURL = (imageIsSet
        ? getImageFullURL(imageSrc)
        : getImageFullURL('_no_image.jpg')
    );

    function onClickAddFile(e) {
        document.getElementById(name).click();
    }

    const handleAddFile = (e) => {
        const [file] = e.target.files;

        if (file === undefined) return;

        const newData = {
            [dataName]: file,
            file      : (data['file'] ?? 0) + 1,
        };

        var reader = new FileReader();

        reader.onload = ( (file) => {
            return (e) => setImageNewSrcURL(e.target.result);
        })(file);

        reader.readAsDataURL(file);

        updateData( newData );
    };

    const onClickClearNewFile = () => {
        document.getElementById(name).value = '';

        const newData = {
            [dataName]: '',
            file      : data['file'] - 1,
        };

        updateData( newData );
    }

    return (
        <div key={name}>
            {title !=='' && <Subtitle2>{title}</Subtitle2>}

            <div style={{height: (imageIsSet || imageNewIsSet ? 182 : 0), display: 'flex', alignItems: 'center'}}>
                {imageIsSet &&
                    <span><ThirdOrderButton onClick = {onClickAddFile}>
                        <img
                            src   = {imageURL}
                            style = {{ height: 180, border: "1px solid rgb(192, 192, 255)" }}
                            alt   = ''
                        />
                    </ThirdOrderButton></span>
                }

                {imageIsSet && imageNewIsSet && <span> &nbsp;&gt;&gt;&nbsp; </span>}

                {imageNewIsSet && <>
                    <span><ThirdOrderButton onClick = {onClickAddFile}>
                        <img
                            src   = {imageNewSrcURL}
                            style = {{height: 180, border: "1px solid rgb(192, 192, 255)"}}
                            alt   = ''
                        />
                    </ThirdOrderButton></span>
                    <span >
                        {imageNewSrc.name}
                        <ThirdOrderButton onClick={() => onClickClearNewFile()}>&times;</ThirdOrderButton>
                    </span>
                </>}
            </div>
            <br />

            {imageIsSet && imageNewIsSet && <div>*<small>будет заменено при сохранении</small></div>}

            <Button onClick = {onClickAddFile}>
                Выбрать изображение
                {/*imageIsSet ? 'Заменить изображение' : 'Выбрать изображение'*/}
            </Button>

            <input
                onChange = {handleAddFile}
                name     = {name}
                id       = {name}
                type     = 'file'
                style    = {{ display: "none" }}
            />
        </div>
    );

}

export default FormFileImage;