import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import Button from "./Button";

const MainCustomerButton = styled(Button)`
  @media ${device.tablet} {
    display: none;
  }
`;

export default MainCustomerButton;
