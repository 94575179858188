// prettier-ignore-start
import React from "react";

import { useParams }               from "react-router-dom";

import TemplatesList               from "../TemplatesList";
import { Template, Form, getPath } from "../../../api/methods";
import { useLoadRequest }          from "../../../hooks/useDataRequest";
import { PATHS }                   from "../../../constants/RoutesPaths";


const SettingsCategoryFormTemplatesList = () => {

    const { category_id, form_id } = useParams();

    const form = useLoadRequest({
        request     : Form.get,
        requestData : {id: form_id},
    })

    return <TemplatesList
        title       = { 'Форма '+ (form.data?.name ?? '') +'. Базовые шаблоны' }
        backTo      = { getPath(PATHS.settings.categoryForms, {category_id}) }
        popupPrefix = { 'formTemplatesList' }
        getListData = { (d, s, f) => Template.listForm   ({                   form_id}, s, f) }
        onNew       = { (d, s, f) => Template.assign2Form({...d, category_id, form_id}, s, f) }
    />

};

export default SettingsCategoryFormTemplatesList;
