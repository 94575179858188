import { useEffect, useState }  from "react";
import { isEmpty }              from "../functions/common";

// prettier-ignore-start

/*
 * Usage example:
    const validator = useValidation({
        email : (value) => isValidEmailCheck(value) || !value,
        phone : (value) => isValidPhoneCheck(value) || !value,
        date  : [ (value) => isValidDateCheck (value) || !value, null ],
        town  : (value, towns) => towns.includes(value),
    }, towns_list);
*/
const defaultState = true;

export function useValidation(
    validators = {},
    data = null,
    auto = false
) {
    const names = Object.keys(validators);

    const hasValidator        = (name) => validators[name] !== undefined;
    const isValidatorFunc     = (name) => (typeof validators[name] === 'function');

    const getValidatorFunc    = (name) => hasValidator(name)
        ? (isValidatorFunc(name) ? validators[name] : validators[name][0])
        : () => defaultState;

    const getValidatorDefault = (name) => hasValidator(name) && !isValidatorFunc(name)
        ? validators[name][1]
        : defaultState;

    const isValidDefaults = names.reduce( (result, name) => ({
        ...result, [name]: getValidatorDefault(name)
    }), {} );

    const [isValid, setIsValid] = useState( isValidDefaults );

    useEffect(() => {
        if (auto) checkData(data);
    }, [data]);

    // validation
    const checkResult = (name, value) => {
        const validator = getValidatorFunc(name);

        return (validator)
            ? validator(value, data)
            : true;
    };

    const check = (name, value) => {
        if (! hasValidator(name)) return true;

        const result = checkResult(name, value);
        setIsValid({ ...isValid, [name]: result });

        return result;
    };

    const checkEvent = (e) => {
        let { value, name, type, checked } = e.target;
        if (type === 'checkbox') value = checked;

        return check(name, value);
    }

    const checkData = (data) => setIsValid( names.reduce(
        (result, name) => ({ ...result, [name]: checkResult(name, data[name]) }),
        isValid
    ) );

    const hasInvalidValues = Object.values(isValid).includes(false);

    const isValidFunc = (name = undefined) => isEmpty(name)
            ? (! hasInvalidValues)
            : ( isValid[name] ?? true )


    return {
        isValid : isValidFunc,

        isWrong : (name = undefined) => ! isValidFunc(name),

        check   : check,
        event   : checkEvent,
        data    : checkData,
    };

}

// prettier-ignore-end