import React from 'react';

import { Link }           from "react-router-dom";
import coins              from '../../assets/svg/coins.svg';
import { gray }           from "../../constants/Colours";
import { useAppSelector } from '../../hooks/useRedux';


export default function BonusesValue() {

    const data = useAppSelector(state => state.bonuses.status)

    return (
        <Link to='/bonuses' style={{color: gray}}>
            <div
                style = {{
                    fontWeight: 'normal',
                    display   : 'flex',
                    alignItems: 'center',
                }}
            >
                <img
                    src   = {coins}
                    alt   = 'бонусы'
                    style = {{
                        width      : '25px',
                        height     : '25px',
                        marginRight: '5px',
                    }}
                />
                <span
                    style = {{
                        fontSize   : '16px',
                        fontWeight : 'bold',
                        marginRight: '7px'
                    }}
                >
                    {data?.bonuses_count}
                </span> бонус (ов)
            </div>
        </Link>
    );

}
