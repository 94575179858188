// prettier-ignore-start
import React from "react";

import { PATHS }                  from "../../../constants/RoutesPaths";
import Tabbed                     from "../../../components/Tabbed";
import tabType                    from "../tabType";
import SettingsSystemActions      from "./SettingsSystemActions";
import SettingsSystemRoles        from "./SettingsSystemRoles";
import SettingsSystemAccessRights from "./SettingsSystemAccessRights";
import SettingsSystemStatuses     from "./SettingsSystemStatuses";
import SettingsSystemMails        from "./SettingsSystemMails";
import SettingsSystemStructure    from "./SettingsSystemStructure";
import SettingsSystemUsers        from "./SettingsSystemUsers";
import SettingsSystemCommon       from "./SettingsSystemCommon";
import SettingsSystemTemplates    from "./Templates/SettingsSystemTemplates";


const SettingsSystem = ({ tabs = [] }) => {

    const tab     = tabs[0] ?? tabType.systemRoles;
    const subTabs = tabs.slice(1);


    return <Tabbed
        title = ''
        tab   = {tab}
        tabs  = {{
            [tabType.systemStructure]      : { title: 'Структура'   , route: PATHS.settings.systemStructure    , component: SettingsSystemStructure                                             },
            [tabType.systemStructure+'_']  : { title: '' },
            [tabType.systemUsers]          : { title: 'Пользователи', route: PATHS.settings.systemUsers        , component: SettingsSystemUsers       , componentProps: {sub: subTabs[0] ?? ''} },
            [tabType.systemUsers +'_']     : { title: '' },
            [tabType.systemRoles]          : { title: 'Роли'        , route: PATHS.settings.systemRoles        , component: SettingsSystemRoles       , componentProps: {sub: subTabs[0] ?? ''} },
            [tabType.systemStatuses]       : { title: 'Статусы'     , route: PATHS.settings.systemStatuses     , component: SettingsSystemStatuses    , componentProps: {sub: subTabs[0] ?? ''} },
            [tabType.systemStatuses +'_']  : { title: '' },
            [tabType.systemAccessRights]   : { title: 'Права'       , route: PATHS.settings.systemAccessRights , component: SettingsSystemAccessRights, componentProps: {sub: subTabs[0] ?? ''} },
            [tabType.systemActions]        : { title: 'Действия'    , route: PATHS.settings.systemActions      , component: SettingsSystemActions     , componentProps: {sub: subTabs[0] ?? ''} },
            [tabType.systemActions +'_']   : { title: '' },
            [tabType.systemEmails]         : { title: 'Уведомления' , route: PATHS.settings.systemEmails       , component: SettingsSystemMails                                                 },
            [tabType.systemEmails +'_']    : { title: '' },
            [tabType.systemTemplates]      : { title: 'Шаблоны'     , route: PATHS.settings.systemTemplateTypes, component: SettingsSystemTemplates   , componentProps: {sub: subTabs[0] ?? ''} },
            [tabType.systemTemplates +'_'] : { title: '' },
            [tabType.systemCommon]         : { title: 'Настройки'   , route: PATHS.settings.systemCommon       , component: SettingsSystemCommon                                                },
        }}
    />

};

export default SettingsSystem;
