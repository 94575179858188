import { structItem, element } from 'models/elements.model';
import T from './types';

export const setElements = (elements: element[]) => ({
  type: T.SET_EDITOR_ELEMENTS,
  payload: elements,
});

export const setStructToElement = (id: string, struct: structItem) => ({
  type: T.SET_STRUCT_ELEMENT,
  id,
  struct,
});

export const deleteStructElement = (
  id: string,
  structElementId: structItem
) => ({
  type: T.DELETE_ELEMENT_FROM_STRUCT,
  id,
  structElementId,
});

export const typeName = (id: string, text: string) => ({
  type: T.TYPE_NAME,
  id,
  text,
});

export const typeTip = (id: string, text: string) => ({
  type: T.TYPE_TIP,
  id,
  text,
});

export const pasteHref = (id: string, text: string) => ({
  type: T.CHANGE_HREF,
  id,
  text,
});

export const deleteHref = (id: string) => ({
  type: T.CHANGE_HREF,
  id,
  text: '',
});

export const setRepeatChildrenCountToElement = (
  id: string,
  repeat: number
) => ({
  type: T.SET_REPEAT_CHILDREN_COUNT_TO_ELEMENT,
  id,
  repeat,
});

export const modificateIdsOfSuperComplexLists = () => ({
  type: T.MODIFICATE_IDS_OF_SUPER_COMPLEXT_LISTS,
});
export const deleteSuperComplexElements = (element: element) => ({
  type: T.DELETE_SUPERCOMPLEX_ELEMENTS,
  element,
});

export const changeElementRepeat = (id: string, count: number) => ({
  type: T.CHANGE_REPEAT,
  id,
  count,
});


export const clearElements = () => ({
  type: T.CLEAR_STATE
})