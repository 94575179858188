// prettier-ignore-start

const DEFAULT_PATH = '/';

export const PATHS = {
    default                       : DEFAULT_PATH,

    login                         : '/auth',
    register                      : '/register',
    agreement                     : '/agreement',
    registerSuccess               : '/register/success',
    forgotPassword                : '/auth/forgot-password',
    resetPassword                 : '/auth/reset-password/:token',

    payers                        : '/payers',
    payerAdd                      : '/payer/add',
    payerInfo                     : '/payer/:payer_id',

    formEditSetCategory           : '/form/category',
    formEdit                      : '/form/:form_id',
    formEditPreview               : '/form/:form_id/preview',

    users                         : '/users/',
    userInfo                      : '/user/:user_id',
    userCategories                : '/user/:user_id/categories',
    userCategoryForms             : '/user/:user_id/category/:category_id/forms',
    userBonuses                   : '/user/:user_id/bonuses',
    userRatings                   : '/user/:user_id/ratings',

    bonuses                       : '/bonuses',

    orders                        : '/orders',

    orderEdit                     : '/order/edit/:form_id/:application_id',

    orderNewSt1SetPayer           : '/order/new',
    orderNewSt6Success            : '/order/new/thanks',
    orderNewSt1SetPayerNew        : '/order/new/payer/add',
    orderNewSt2SetCategory        : '/order/new/:payer_id',
    orderNewSt3SetForm            : '/order/new/:payer_id/:category_id',
    orderNewSt4Form               : '/order/new/:payer_id/:category_id/:form_id/:application_id',
    orderNewSt5Delivery           : '/order/new/:payer_id/:category_id/:form_id/:application_id/delivery',

    orderInfo                     : '/order/:order_id/info',
    orderInfoDistributionExecutor : '/order/:order_id/info/distribution/executor',
    orderForm                     : '/order/:order_id/form',
    orderChat                     : '/order/:order_id/chat',
    orderHistory                  : '/order/:order_id/history',
    orderDelivery                 : '/order/:order_id/delivery/:back_type',
    orderPayerInfo                : '/order/:order_id/payer/:payer_id',

//    editorCreatingNew       : "/editor/new/creating/:id",
//    editorPreviewNew        : "/editor/new/preview/:id",

    settings: {
        default                         : "/settings",
        common                          : '/settings/common',
        categories                      : "/settings/categories",
        categoryTemplates               : "/settings/category/:category_id/templates",
        categoryForms                   : "/settings/category/:category_id/forms",
        categoryFormTemplates           : "/settings/category/:category_id/form/:form_id/templates",
        executorGroups                  : "/settings/groups",
        executorGroupUsers              : "/settings/group/:group_id/users",
        company                         : "/settings/company",
        companyNew                      : "/settings/company/new",
        companyEdit                     : "/settings/company/:company_id",
        companyTemplates                : "/settings/company/:company_id/templates",
        companyResultTemplates          : "/settings/company/:company_id/templates/result",
        companyCategoryTemplates        : "/settings/company/:company_id/category/:category_id/templates",
        companyForms                    : "/settings/company/:company_id/forms",
        companyFormTemplates            : "/settings/company/:company_id/form/:form_id/templates",
        lists                           : '/settings/lists',
        listsBonuses                    : '/settings/lists/bonuses',
        listsPromocodes                 : "/settings/lists/promocodes",
        listsSources                    : "/settings/lists/sources",
        listsDeliveryMethods            : "/settings/lists/delivery",
        listsDeliveryRegions            : "/settings/lists/delivery/:method_id/region",
        listsDeliveryTowns              : "/settings/lists/delivery/region/:region_id/towns",
        listsSoftwareProducts           : "/settings/lists/software-products",
        listsRatings                    : "/settings/lists/ratings",
        listsRatingRoles                : "/settings/lists/rating/:rating_id/roles",
        listsRatingScores               : "/settings/lists/rating/:rating_id/scores",
        listsRatingScoreValues          : "/settings/lists/rating/score/:rating_score_id/values",
        system                          : '/settings/system',
        systemTemplateFormats           : '/settings/system/template/formats',
        systemTemplateGenerators        : '/settings/system/template/generators',
        systemTemplateTypes             : '/settings/system/template/types',
        systemTemplateTypeTemplates     : '/settings/system/template/type/:template_type_id',
        systemTemplateTypeTemplateFiles : '/settings/system/template/type/:template_type_id/:template_id/files',
        systemRoles                     : '/settings/system/roles',
        systemRoleAccessRights          : '/settings/system/roles/:role_id/access_rights/',
        systemRoleStatuses              : '/settings/system/roles/:role_id/statuses/',
        systemRoleStatusActions         : '/settings/system/roles/status/:role_status_id/actions',
        systemRoleStatusAccessRights    : '/settings/system/roles/status/:role_status_id/access_rights',
        systemAccessRights              : '/settings/system/access_rights',
        systemAccessRightValues         : '/settings/system/access_right/:access_right_id/values',
        systemAccessRightTargets        : '/settings/system/access_right/:access_right_id/targets',
        systemStatuses                  : '/settings/system/statuses',
        systemStatusMails               : '/settings/system/statuses/:status_id/mails/',
        systemStatusRoles               : '/settings/system/statuses/:status_id/roles/',
        systemStatusAccessRights        : '/settings/system/statuses/:status_id/access_rights',
        systemStatusRoleActions         : '/settings/system/statuses/role/:role_status_id/actions',
        systemStatusRoleAccessRights    : '/settings/system/statuses/role/:role_status_id/access_rights',
        systemActions                   : '/settings/system/actions',
        systemActionMails               : '/settings/system/actions/:action_id/mails/',
        systemEmails                    : '/settings/system/emails',
        systemCommon                    : '/settings/system/common',
        systemUsers                     : '/settings/system/users',
        systemUserAccessRights          : '/settings/system/user/:user_id/access_rights',
        systemStructure                 : '/settings/system/structure',
    },

};
