import T from "./types";

const initialState = false;

export default function isHiddenMobileButton(state = initialState, action) {
  switch (action.type) {
    case T.SET_IS_SHOW_BUTTON:
      return action.isHiddenMobileButton;
    default:
      return state;
  }
}
