import React from 'react';
import { storageData } from "../../functions/auth";
import Tooltip         from "./Tooltip";


export default function RatingValues() {

    const user_ratings = JSON.parse(localStorage.getItem(storageData.ratings ) ?? '[]');


    if (user_ratings.length === 0) return null;
    return <span style={{fontWeight: 'normal', display: "flex", gap: 10}}>
        {(user_ratings.length === 1)
            ? <>рейтинг: {user_ratings[0].value}</>
            : <>рейтинги: {user_ratings.map( ({ value, rating }) => <Tooltip label={rating?.title ?? ''}>value</Tooltip> ).join(', ')}</>
        }
    </span>

}
