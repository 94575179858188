// prettier-ignore-start
import React from "react";

import { useHistory, useParams }    from "react-router-dom";

import { Category, Form, getPath }  from "../../../api/methods";
import { useLoadRequest }           from "../../../hooks/useDataRequest";
import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";
import { PATHS }                    from "../../../constants/RoutesPaths";
import { ifNotEmpty }               from "../../../functions/common";
import BackTitleButton              from "../../Orders/BackTitleButton";
import List                         from "../../../components/Lists/List";
import LIF                          from "../../../components/Lists/ListItemFormats";
import LIT                          from "../../../components/Lists/ListItemTypes";
import { isValidJsonCheck }         from "../../../functions/validators";


const SettingsCategoryFormsList = () => {

    const history    = useHistory();
    const userAccess = useUserAccessRightChecks();

    const { category_id } = useParams();

    const category = useLoadRequest({
        request     : Category.getById,
        requestData : {id: category_id},
    })

    const gotoTemplates = (id) => history.push( getPath( PATHS.settings.categoryFormTemplates, { category_id, form_id: id } ) );


    return <>
        <BackTitleButton title={'Формы категории ' + ifNotEmpty(category.data?.name, '"'+category.data?.name+'"', '' )} backTo={PATHS.settings.categories}/>

        <List
            popupPrefix  = {'categoryForms'}
            getListData  = {(d, s, f) => Form.list({ category_id }, s, f)}

            buttons      =  {[
                {type: 'new'},
            ]}

            cols         = {[
                { name: 'is_published'        , title: 'опубликовано', format: LIF.check        , type: LIT.check   , canAddAndEdit: false, default: 0, align: 'center' },
                { name: 'name'                , title: 'название'    , format: LIF.linkEdit     , type: LIT.text    , canAddAndEdit: true             , align: 'left'  , isValid: (val) => val !== "" },
                { name: 'props'               , title: 'параметры'   , format: LIF.hidden       , type: LIT.textarea, canAddAndEdit: true , default: '{}'              , isValid: (val) => isValidJsonCheck(val) },

                { name: 'applications_count'  , title: 'заяв'        , format: LIF.usageCounter },
                { name: 'base_templates_count', title: 'шблн'        , format: LIF.usageCounter },
            ]}

            actions = {[
                {title: 'шаблоны', onClick: gotoTemplates, canDo: () => userAccess.isAllowSysSettingsTemplates },
            ]}

            onNew        = {(d,s,f) => Form.add({...d, category_id}, s, f)}
            onEdit       = {Form.update}
            onDelete     = {Form.delete}

            canDelete    = {(item) => (item['applications_count'] === 0) && (item['base_templates_count'] === 0)}

            textOnDelete = {(item) => <>Удалить форму<b>{item?.name ?? ""}</b>?</>}
        />
    </>

};

export default SettingsCategoryFormsList;
