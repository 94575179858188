// prettier-ignore-start
import React from "react";

import {
    Settings,
    SystemRoles,
    SystemStatuses
}                         from "../../../api/methods";

import { useLoadRequest } from "../../../hooks/useDataRequest";

import { Preloader }      from "../../../components/UI-kit/Preloader/Preloader";
import InputsGrid         from "../../../components/UI-kit/InputsGrid";
import Subtitle2          from "../../../components/UI-kit/Subtitle2";
import ElementSelect      from "../../../components/Fields/ElementSelect";
import ElementInput       from "../../../components/Fields/ElementInput";


const defaultSettings = null;

const mailSendTypedList = [
    {id: 0, name: 'отключены'                            },
    {id: 1, name: 'включены'                             },
    {id: 2, name: 'тестовый получатель - в скрытой копии'},
    {id: 3, name: 'только тестовый получатель (debug)'   },
];


const SettingsSystemCommon = () => {

    const settings = useLoadRequest({
        request      : Settings.get,
        defaultResult: defaultSettings
    })

    const onChange = (e) => {
        const { value, name } = e.target;
        settings.setData({ ...settings.data, [name]: value });
    };

    const saveSettings = (e) => {
        const { value, name } = e.target;
        Settings.update( { [name]: value } );
    };


    if (settings.isLoading) return <Preloader />;
    return <>
        <Subtitle2>Системные настройки</Subtitle2>

        <InputsGrid maxWidth="710px" columns="1fr" gap="0">
            <wrapper disabled={false}>
                <ElementSelect title='Роль пользователя при регистрации'    name='user_default_role_id'  value={settings.data?.user_default_role_id}  onChange={saveSettings} props={{getOptions: SystemRoles.select   , getOptionsData: { hasUnset: 1, onlyActive: 1 }}} />
                <ElementSelect title='Статус заявки при создании в системе' name='app_default_status_id' value={settings.data?.app_default_status_id} onChange={saveSettings} props={{getOptions: SystemStatuses.select, getOptionsData: { hasUnset: 1, onlyActive: 1 }}} />
                <ElementSelect title='Статус новой поданной заявки'         name='app_sent_status_id'    value={settings.data?.app_sent_status_id}    onChange={saveSettings} props={{getOptions: SystemStatuses.select, getOptionsData: { hasUnset: 1, onlyActive: 1 }}} />
                <br />
                <ElementInput  title='Роли бонусной программы'              name='roles_bonus_program'   value={settings.data?.roles_bonus_program}   onChange={onChange} onBlur={saveSettings} />
                <ElementInput  title='Роли рейтинговой системы'             name='roles_rating'          value={settings.data?.roles_rating}          onChange={onChange} onBlur={saveSettings} />
                <ElementInput  title='Роли уведомлений админа'              name='roles_mail_to_admin'   value={settings.data?.roles_mail_to_admin}   onChange={onChange} onBlur={saveSettings} />
                <br />
                <ElementSelect title='Почтовые уведомления'                 name='mail_send_type_id'     value={settings.data?.mail_send_type_id}     onChange={saveSettings} props={{getOptions: (d,s) => s({data: mailSendTypedList}) }} />
                <ElementInput  title='Получатели почтовых уведомлений'      name='mail_send_receivers'   value={settings.data?.mail_send_receivers}   onChange={onChange} onBlur={saveSettings} />
            </wrapper>
        </InputsGrid>
    </>

};

export default SettingsSystemCommon;
