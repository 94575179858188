import React from "react";
import styled from "styled-components";
import Text from "../components/UI-kit/Text";
import Title from "../components/UI-kit/Title";

import chrome from "../assets/img/chrome.png";
import edge from "../assets/img/edge.png";
import firefox from "../assets/img/firefox.png";
import opera from "../assets/img/opera.png";
import { device } from "../constants/Breakpoints";

const Container = styled.div`
  text-align: center;

  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & ${Title} {
    margin-top: 0;
    font-size: 44px;
  }

  @media ${device.laptopL} {
    padding-top: 100px;
    height: inherit;
  }

  @media ${device.tablet} {
    padding-top: 0px;
    & ${Title} {
      margin-top: 0;
      font-size: 24px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const Logotype = styled.div`
  position: absolute;

  left: 40px;
  top: 30px;

  cursor: default;
  font-size: 20px;
  font-weight: bold;
  @media ${device.tablet} {
    text-align: center;
    position: inherit;
    margin-bottom: 30px;
  }
`;

const BrowserList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 12px;

  & ${Text} {
    font-size: 20px;
    text-align: center;
  }

  @media ${device.tablet} {
    & ${Text} {
      font-size: 16px;
      text-align: center;
    }
  }
`;

const Browsers = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const BrowserHref = styled.a`
  background-color: #fafafa;

  border-radius: 10px;

  margin: 10px;
  padding: 20px 40px 30px 40px;
  text-decoration: none;
  font-weight: 600;

  color: #333;
  &:hover {
    background-color: #f7f9ff;
    color: rgb(79, 140, 232);
  }

  div {
    margin-top: 20px;
  }

  img {
    width: 150px;
    height: 150px;
  }

  @media ${device.tablet} {
    margin: 10px;
    padding: 10px;
    width: calc(50% - 40px);
    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const BrowserComponent = ({ href, src, label }) => (
  <BrowserHref class="browser" target="_blank" rel="noopener" href={href}>
    <img src={src} />
    <div>{label}</div>
  </BrowserHref>
);

const NotSupportedBrowserPage = () => {
  return (
    <>
      <Logotype>Фабрика расчетов</Logotype>
      <Container>
        <Title>Браузер не поддерживается</Title>
        <BrowserList>
          <Text>
            Фабрика расчетов на текущий момент поддерживает следующие браузеры
          </Text>
          <Browsers>
            <BrowserComponent
              href="https://www.google.ru/intl/ru/chrome/?brand=CHBD&gclid=CjwKCAjwq832BRA5EiwACvCWsY6u8HqS_02GiWaxk3MlM28MQBttjaDI9XgQM082fTWu21dP6GAeyBoCgCgQAvD_BwE&gclsrc=aw.ds"
              src={chrome}
              label="Google Chrome 70+"
            />
            <BrowserComponent
              href="https://www.microsoft.com/ru-ru/edge"
              src={edge}
              label="Microsoft Edge 15+"
            />
            <BrowserComponent
              href="https://www.mozilla.org/ru/firefox/new/"
              src={firefox}
              label="Firefox 60+"
            />
            <BrowserComponent
              href="https://www.opera.com/ru"
              src={opera}
              label="Opera 50+"
            />
          </Browsers>
        </BrowserList>
      </Container>
    </>
  );
};

export default NotSupportedBrowserPage;