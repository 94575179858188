// prettier-ignore-start
import React, {useState, useEffect} from 'react';

import styled               from "styled-components"
import _                    from "lodash";

import {BonusesAdminStatus} from "../../../api/methods";

import {Settings}           from "../../../models/bonuses.model"
import {green, red}         from "../../../constants/Colours";
import Loader               from "../../../components/UI-kit/Loader";
import Button               from "../../../components/UI-kit/Buttons/Button";
import Subtitle             from "../../../components/UI-kit/Subtitle";
import FloatingInput        from "../../../components/UI-kit/FloatingInput";
import Text                 from "../../../components/UI-kit/Text";
import Stack                from "../../../components/UI-kit/layout/Stack"


const BonusesListItem = styled(Stack)`
  margin-bottom: 10px;
  p {
    width: 150px;
  }
`

const bonusesSettings: Record<keyof Settings, string> = {
    registration_bonuses_count: "За регистрацию",
    app_in_row_hours          : "Время удержания очереди заявок, ч",
    //app_in_row_bonuses_count  : "За заявку подряд"
}

function BonusesSettings() {

    const [settings, setSettings] = useState<Partial<Settings>>({})
    const [values  , setValues  ] = useState<Partial<Settings>>({})
    const [msg     , setMsg     ] = useState<{value: string, color: string}>({value: "", color: green})
    const onDataReceived = (data: { data: Settings }) => {
        setSettings(data.data)
        setValues(data.data)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        if (!isNaN(+value) && +value >= 0) {
            setValues(prev => ({...prev, [name]: +value}))
        }
    }

    useEffect(() => {
        BonusesAdminStatus.getSettings({}, onDataReceived)
    }, [])

    const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        setValues(settings)
    }

    const onSuccess = () => {
        setMsg({value: "Успешно", color: green})
        setSettings(values)
    }

    const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        BonusesAdminStatus.changeSettings(
            values,
            onSuccess,
            () => setMsg({value: "Ошибка", color: red}))
    }


    return (
        <>
            <Subtitle style={{marginBottom: 20}}>Сумма начисления:</Subtitle>
            <form>
                {Object.keys(settings).length ? Object.keys(bonusesSettings).map((key) => {
                    return (
                        <BonusesListItem>
                            <Text withoutMargin>{bonusesSettings[key]}</Text>
                            <FloatingInput name={key} value={String(values[key])} onChange={onChange}/>
                        </BonusesListItem>)
                }) : <Loader/>}
                <Button style={{marginRight: "10px"}}
                        disabled={_.isEqual(values, settings)}
                        onClick={(e) => onSave(e)}
                >Сохранить</Button>
                <Button disabled={_.isEqual(values, settings)} onClick={(e) => onCancel(e)}>Отменить</Button>
                {msg && <Text color={msg.color}>{msg.value}</Text>}
            </form>
        </>

    );
}

export default BonusesSettings;