// prettier-ignore-start
import React, { useEffect, useState }    from 'react';
import { useHistory }                    from 'react-router-dom';
import { useDispatch }                   from 'react-redux';

import { Auth }                          from '../api/methods';
import { T }                             from '../lang/ru';
import { formDataFromInputInfoForState } from '../functions/common/common';
import { setIsHiddenMobileButton }       from '../store/isHiddenMobileButton/action';

import { INPUT_CONFIGS }                 from '../constants/InputConfigs';
import { PATHS }                         from '../constants/RoutesPaths';
import { green, yellow }                 from '../constants/Colours';

import Button                            from '../components/UI-kit/Buttons/Button';
import SecondOrderButton                 from '../components/UI-kit/Buttons/SecondOrderButton';
import FloatingInput                     from '../components/UI-kit/FloatingInput';
import Text                              from '../components/UI-kit/Text';
import Title                             from '../components/UI-kit/Title';


const ResetPassword: any = (props: any) => {

    const history  = useHistory ();
    const dispatch = useDispatch();

    const [data     , changeDataField] = useState( formDataFromInputInfoForState(INPUT_CONFIGS.resetPassword) );

    const [isSuccess, setIsSuccess   ] = useState<boolean>(false);
    const [isError  , setIsError     ] = useState<boolean>(false);

    useEffect(() => {
        changeDataField({ email: '', password: '' });
        dispatch( setIsHiddenMobileButton(false) );
        localStorage.clear();
    }, []);

    const onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
        const { value, name } = e.target;

        changeDataField({ ...data, [name]: value });
    };

    const resetPassword = () => {
        const { token } = props.match.params;
        const newData = { token, password: data.password };

        Auth.resetPassword(
            newData,
            () => {
              history.push(PATHS.login);
              setIsSuccess(true );
              setIsError  (false);
            },
            () => {
              setIsSuccess(false);
              setIsError  (true );
            }
        );
    };

    const goToAuth: () => void = () => history.push(PATHS.login);

    const { password, passwordRepeat } = data;
    const isWrongPassword = (password !== passwordRepeat) && (password !== '') && (passwordRepeat !== '');


    return <>
        <Title>Введите новый пароль</Title>

        <FloatingInput
            required     = {true}
            placeholder  = {"Пароль"}
            key          = {"password"}
            name         = {"password"}
            type         = {"password"}
            value        = {data["password"]}
            onChange     = {onChangeHandler}
            wrong        = {isWrongPassword}
        />
        <FloatingInput
            wrongMessage = {T.inCorrectPassword}
            wrong        = {isWrongPassword}
            key          = {'passwordRepeat'}
            name         = {'passwordRepeat'}
            value        = {data['passwordRepeat']}
            onChange     = {onChangeHandler}
            placeholder  = {'Повторите пароль'}
            type         = {'password'}
            required     = {true}
        />

        {isSuccess && <Text color={green}  withoutMargin={true}>Новый пароль успешно установлен</Text>}
        {isError   && <Text color={yellow} withoutMargin={true}>Не удалось установить новый пароль. Пожалуйста, повторите попытку позднее.</Text>}

        <Button
            disabled = {isSuccess}
            style    = {{ marginRight: 20 }}
            onClick  = {resetPassword}
        >
            Сбросить пароль
        </Button>
        <SecondOrderButton onClick={goToAuth}>Назад</SecondOrderButton>
    </>

};

export default ResetPassword;
