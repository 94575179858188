import React, { useState } from 'react';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { white, yellow, gray, red } from '../../constants/Colours';

const CustomLink = styled(Scroll.Link)`
  transition: 0.2s ease all;
  width: 40px;
  padding: 5px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${yellow};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  &:hover {
    background: ${(props) => (props.disabled ? yellow : red)};
  }
`;

const Wrapper = styled.div`
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  font-size: 18px;
  color: ${white};
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const FindEmptyField = () => {
  const emptyFieldsIds = useSelector((state) => state.emptyFieldsIds);
  const [currentElement, setCurrentElement] = useState(0);
  const elementRelativeToViewport = useSelector(
    (state) => state.answersInViewport
  );

  const changeCurrentElement = (value) => {
    if (emptyFieldsIds[currentElement + value]) {
      setCurrentElement(currentElement + value);
    } else if (value === 1) {
      setCurrentElement(emptyFieldsIds.length - 1);
    } else {
      setCurrentElement(0);
    }
  };

  return (
    <>
      {emptyFieldsIds.length > 1 ? (
        <Wrapper>
          <CustomLink
            smooth={true}
            duration={300}
            onClick={() => changeCurrentElement(-1)}
            disabled={
              currentElement === 0 &&
              elementRelativeToViewport[emptyFieldsIds[0]]
            }
            to={emptyFieldsIds[currentElement - 1 < 0 ? 0 : currentElement - 1]}
          >
            &#8593;
          </CustomLink>
          <CustomLink
            smooth={true}
            duration={300}
            onClick={() => changeCurrentElement(+1)}
            disabled={
              currentElement === emptyFieldsIds.length - 1 &&
              elementRelativeToViewport[
                emptyFieldsIds[emptyFieldsIds.length - 1]
              ]
            }
            to={
              emptyFieldsIds[
                currentElement + 1 >= emptyFieldsIds.length
                  ? emptyFieldsIds.length - 1
                  : currentElement + 1
              ]
            }
          >
            &#8595;
          </CustomLink>
        </Wrapper>
      ) : null}
    </>
  );
};

export default FindEmptyField;
