// prettier-ignore-start
import React from 'react';

import styled from 'styled-components';
import { device } from '../../constants/Breakpoints';
import {
  accent,
  gray,
  green,
  lightgray,
  ultraLightgray,
  yellow,
} from '../../constants/Colours';
import { T } from '../../lang/ru';
import Tooltip from './Tooltip';

type WrapperProps = {
  wrong       : boolean;
  wrongMessage: string;
};

const Wrapper = styled.div`
  position   : relative;
  display    : flex;
  align-items: center;
  transition : all 200ms linear;
  margin-top : ${(props: WrapperProps) => props.wrongMessage !== '' ? '10px' : '0px'};
`;

type InputProps = {
  wrong   : boolean;
  correct : boolean;
  disabled: boolean;
};

export const Input = styled.input`
  font-size       : 16px;
  padding         : 15px 15px 15px 20px;
  margin          : 10px 0;
  display         : block;
  width           : 300px;
  background-color: ${(props: InputProps) => props.disabled ? ultraLightgray : 'white'};
  border-radius   : 4px;
  border          : 1px solid
    ${(props: InputProps) =>
      props.disabled
        ? lightgray
        : props.wrong
        ? yellow
        : props.correct
        ? green
        : lightgray};
  &:disabled {
    color: ${gray};
  }
  &:focus {
    border-color: ${accent};
    outline     : none;
  }
`;

type TextareaProps = {
  width     ?: string;
  isTextArea : boolean;
};

export const TextArea = styled.textarea`
  font-size       : 16px;
  padding         : 15px 15px 15px 20px;
  width           : 300px;
  margin          : 10px 0;
  resize          : none;
  display         : block;
  max-width       : 500px;
  width           : ${(props: TextareaProps) => props.width};
  height          : 54px;
  background-color: white;
  border          : 1px solid lightgray;
  border-radius   : 4px;
  &:focus {
    border-color  : ${accent};
    outline       : none;
  }
`;

type PlaceHolderLabelProps = {
  isOnTop: boolean;
};

const PlaceHolderLabel = styled.label`
  font-weight   : normal;
  position      : absolute;
  pointer-events: none;
  left          : 20px;

  top           : ${(props: PlaceHolderLabelProps) => (props.isOnTop ? '12px' : '25px')};
  font-size     : ${(props: PlaceHolderLabelProps) => (props.isOnTop ? '12px' : '18px')};
  color         : ${gray};

  transition    : 0.2s ease all;

  ${Input}:focus ~ & {
    top       : 11px;
    font-size : 12px;
    width     : 230px;
    background: white;
  }
`;

const WrongLabel = styled.label`
  position : absolute;
  left     : 20px;
  top      : -10px;
  font-size: 12px;
  color    : ${yellow};
`;

const RequiredSign = styled.div`
  display        : flex;
  justify-content: center;
  align-items    : center;
  color          : ${yellow};
  border         : 1px solid ${yellow};
  border-radius  : 100px;
  min-width      : 20px;
  min-height     : 20px;
  width          : 20px;
  height         : 20px;
  ::after {
    content: '!';
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export type FloatingInputProps = {
  name          : string;
  value         : string;
  onChange     ?: (e: any) => void;
  required     ?: boolean;
  onBlur       ?: (e: any) => void;
  placeholder  ?: string;
  type         ?: string;
  wrong        ?: boolean;
  disabled     ?: boolean;
  wrongMessage ?: string;
  isTextarea   ?: boolean;
  style        ?: any;
  width        ?: string;
  onKeyPress   ?: any;
  tabIndex     ?: number;
  min          ?: number;
  max          ?: number;
};

const FloatingInput = ({
  required     = false,
  placeholder  = '',
  value        = '',
  type         = 'text',
  onChange     = () => {},
  name         = '',
  onBlur       = () => {},
  wrong        = false,
  wrongMessage = '',
  style        = {},
  isTextarea   = false,
  width        = '',
  onKeyPress   = () => {},
  tabIndex     = 0,
  disabled     = false,
  min          = undefined,
  max          = undefined,
}: FloatingInputProps): JSX.Element => {

  return (
    <Wrapper wrong={wrong} wrongMessage={wrongMessage}>
      {wrong === true ? <WrongLabel>{wrongMessage}</WrongLabel> : null}

      {isTextarea
        ? <TextArea
            isTextArea = {isTextarea}
            name       = {name}
            value      = {value}
            onKeyPress = {onKeyPress}
            onChange   = {onChange}
            onBlur     = {onBlur}
            width      = {width}
            style      = {style}
          />
        : <Input
            name       = {name}
            value      = {value}
            type       = {type}
            disabled   = {disabled}
            required   = {required}
            correct    = {value !== ''}
            wrong      = {wrong}
            onKeyPress = {onKeyPress}
            onChange   = {onChange}
            onBlur     = {onBlur}
            width      = {width}
            style      = {style}
            tabIndex   = {tabIndex}
            min        = {min}
            max        = {max}
          />
      }

      <PlaceHolderLabel isOnTop={value !== ''}>{placeholder}</PlaceHolderLabel>

      {required === true
        ? <Tooltip
              margin = {{ left: 15 }}
              label  = {T.fieldRequired}
          >
            <RequiredSign />
          </Tooltip>
        : null
      }
    </Wrapper>
  );

};

export default FloatingInput;

// export default React.memo(FloatingInput);
// prettier-ignore-end
