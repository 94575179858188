// prettier-ignore-start
import React, { useState } from "react";

import { useHistory }   from "react-router-dom";
import {Form, getPath} from "../../../../../api/methods";
import { PATHS }        from "../../../../../constants/RoutesPaths";
import ThirdOrderButton from "../../../../../components/UI-kit/Buttons/ThirdOrderButton";
import {TrBody}         from "../../../../../components/Lists/Parts/TrBody";


const FormsTableRow = ({
    id,
    isPublished,
    name,
    loadForms,
}) => {
    const [isSuccessUpdate, setSuccessUpdate] = useState(false);

    let changeFormNameSuccess
    let changeFormName

    const history = useHistory();

    const editForm = () => {
        history.push( getPath(PATHS.formEdit, {form_id: id}) );
    };

    const deleteForm = () => {
        const isWantDelete = window.confirm(`Вы действительно хотите удалить форму ${name}?`);

        if (isWantDelete) {
            handleDeleteConfirm();
        }
    };

    const copyForm = () => {
        Form.copy({ id, name: "" }, loadForms);
    };

    const handleDeleteConfirm = () => {
        Form.delete({ id }, loadForms);
    };

    changeFormName = (e) =>
        Form.update({ name: e.target.value, id }, changeFormNameSuccess);

    changeFormNameSuccess = () => {
        setSuccessUpdate(true);
    };

    const togglePublish = () => {
        Form.togglePublish({ id, is_published: !isPublished ? 1 : 0 }, loadForms);
    }

    return (
        <TrBody>
            <td>
              {/*<TextareaAutoResize
                style={{
                  width: 400,
                  paddingTop: 14,
                  paddingBottom: 20,
                  borderColor: isSuccessUpdate ? green : "transparent",
                }}
                placeholder={"Введите название форм"}
                onBlur={changeFormName}
                defaultValue={name}
              />*/}
                <ThirdOrderButton
                    style   = {{ width: "fit-content"}}
                    onClick = {editForm}
                >
                  {name}
                </ThirdOrderButton>
            </td>
            <td style={{whiteSpace: "nowrap"}}>
                <ThirdOrderButton
                    style   = {{ width: "fit-content", marginRight: 50 }}
                    onClick = {copyForm}
                >
                    Копировать
                </ThirdOrderButton>

                <ThirdOrderButton
                    style   = {{ width: "fit-content" }}
                    onClick = {deleteForm}
                >
                    Удалить
                </ThirdOrderButton>

                <ThirdOrderButton
                    style   = {{ marginLeft: 20 }}
                    onClick = {togglePublish}
                >
                    {isPublished ? "Скрыть" : "Опубликовать"}
                </ThirdOrderButton>
            </td>
        </TrBody>
    );
};

export default FormsTableRow;
