// prettier-ignore-start
import React from "react";

import tabType from "./OrdersListAdminPanelTabType";
import UserCategoriesList from "./UserCategoriesList";
import UserCategoryFormsList from "./UserCategoryFormsList";
import UsersList from "./UsersList";
import UserBonuses from "./Bonuses/UserBonuses";


const Users = ({ sub = tabType.usersList }) => {

    if (sub === tabType.userCategories   ) return <UserCategoriesList    />
    if (sub === tabType.userCategoryForms) return <UserCategoryFormsList />
    if (sub === tabType.userBonuses      ) return <UserBonuses />

    return <UsersList />

};

export default Users;
