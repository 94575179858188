import React from "react";
import styled from "styled-components";
import { lightgray, gray, black } from "../../constants/Colours";

const Option = styled.option`
  color: ${black};
  &:disabled {
    color: ${gray};
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 337px;
`;

const Label = styled.label`
  :before {
    content: "";
    display: "block";
    position: absolute;
    right: 10px;
    top: 50%;
    width: 7px;
    height: 7px;
    border-top: 1px solid ${gray};
    border-right: 1px solid ${gray};
    transform: translateY(-50%) rotate(135deg);
    pointer-events: none;
  }
`;

type SelectProps = {
  value: number;
};

const Select = styled.select`
  width: 190px;
  height: 30px;
  border: 1px solid ${lightgray};
  color: ${(props: SelectProps) => (props.value === -1 ? gray : black)};
  font-size: ${(props: SelectProps) => (props.value === -1 ? "18px" : "16px")};
  padding: 4px 4px 4px 20px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

type MiniSelect = {
  value: number;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { id: number; name: string }[];
  title?: string;
  style?: any;
  defaultValue: number;
  disabled?: boolean
};

const MiniSelect: React.FC<MiniSelect> = ({
  value = -1,
  defaultValue = -1,
  name = "",
  onChange,
  options = [],
  title = "",
  style = {},
  disabled = false
}) => (
  <Wrapper style={style}>
    <Select
      value={value}
      onChange={onChange}
      name={name}
      style={{ fontSize: 16, width: "100%" }}
      disabled={disabled}
    >

      {options.map(({ id, name }) => (
        <Option key={id} value={id} selected={defaultValue === id}>
          {name}
        </Option>
      ))}
    </Select>

    <Label />
  </Wrapper>
);

export default MiniSelect;
