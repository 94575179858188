import styled from "styled-components";
import { ultraLightgray } from "../../constants/Colours";

export const Card = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${ultraLightgray};
`;
