// prettier-ignore-start
import React from "react";

import { useParams }      from "react-router-dom";

import { Company }        from "../../../api/methods";
import { useLoadRequest } from "../../../hooks/useDataRequest";
import TemplatesList      from "../TemplatesList";


const CompanyTemplatesList = ({
    popupPrefix = '',
    title = '',
    getListData = (d, s, f) => {},
    onNew       = (d, s, f) => {},
    backTo= ''
}) => {

    const { company_id } = useParams();

    const company = useLoadRequest({
        request    : Company.get,
        requestData: {id: company_id},
    })

    return <TemplatesList
        title        = { title + '. ' + (company?.data.name ?? '') }
        backTo       = { backTo }
        popupPrefix  = { popupPrefix }
        getListData  = { (d, s, f) => getListData({...d, company_id}, s, f) }
        onNew        = { (d, s, f) => onNew      ({...d, company_id}, s, f) }
    />

};

export default CompanyTemplatesList;
