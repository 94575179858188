// prettier-ignore-start
import React, { useEffect } from 'react';

import {
    find,
    isEmpty,
    ifEmpty,
    isNotEmpty,
    toSelectOptions,
    numbToLocaleCurrency, round0
} from "../../../../functions/common";
import { Application }    from '../../../../api/methods';
import { Delivery }       from '../../../../api/methods';
import { Col, Row }       from "styled-bootstrap-grid";
import { T }              from '../../../../lang/ru';
import { Preloader }      from "../../../../components/UI-kit/Preloader/Preloader";
import Subtitle2          from '../../../../components/UI-kit/Subtitle2';
import FloatingInput      from '../../../../components/UI-kit/FloatingInput';
import FloatingSelect     from '../../../../components/UI-kit/FloatingSelect';
import Radio              from '../../../../components/UI-kit/Radio';
import InputCheckbox      from "../../../../components/UI-kit/InputCheckbox";
import { useLoadRequest } from "../../../../hooks/useDataRequest";
import {useValidation} from "../../../../hooks/useValidation";


const DeliveryFormFields = (
    app_id           = 0,
    is_editable      = false,
    show_preloader   = true,
    has_track_number = false,
    validate         = false,
) => {

    // const userAccess = useUserAccessRightChecks();
    // const isAllowed  = userAccess.isAllowAppDataDelivery;
    // const isEditable = userAccess.isAllowAppDataDeliveryEdit;

    // данные методов доставки
    const delivery = useLoadRequest({
        request       : Delivery.getAll,
        defaultResult : [],
    });

    // данные доставки заявки
    const data = useLoadRequest({
        request       : Application.getDeliveryById,
        requestData   : { id: app_id },
        defaultResult : {
            id                              : null,
            delivery_is_needed              : 0,
            delivery_method_id              : null,
            delivery_method_name            : '',
            delivery_address_is_from_payer  : 0,
            delivery_address                : '',
            delivery_copies_count           : '',
            delivery_print_one_copy_price   : 0,
            delivery_price_calculated       : '',
            delivery_print_price_calculated : '',
            delivery_time_calculated        : '',
            delivery_town_id                : null,
            delivery_town_name              : null,
            delivery_region_id              : null,
            delivery_region_name            : null,
            delivery_track_number           : '',
            payer_id                        : null,
            payer_type_id                   : 0,
            payer_address_juridical         : '',
            payer_address_post              : '',
        },
        onSuccess : ({data}) => validator.data(data)
    });

    // проверка полей
    const validator = useValidation({
        delivery_address   : (value, data) => !data['delivery_is_needed'] || (data['delivery_is_needed'] && (isNotEmpty(value) || (data['delivery_address_is_from_payer'] === 1))),
        delivery_method_id : (value, data) => !data['delivery_is_needed'] || (data['delivery_is_needed'] && isNotEmpty(value)),
    }, data.data, true);

    const doValidate = (name, value) => (validate) ? validator.check(name, value) : true;

    const doValidateAll = () => { if (validate && data.isLoaded) validator.data(data.data); }

    // используемые данные
    const {
        // id,
        delivery_is_needed,
        delivery_method_id,
        // delivery_method_name,
        delivery_address_is_from_payer,
        delivery_address,
        delivery_copies_count,
        delivery_print_one_copy_price,
        delivery_price,
        delivery_price_calculated,
        delivery_print_price,
        delivery_print_price_calculated,
        delivery_time,
        delivery_time_calculated,
        delivery_town_id,
        // delivery_town_name,
        delivery_region_id,
        // delivery_region_name,
        delivery_track_number,
        // payer_id,
        payer_type_id,
        payer_address_juridical,
        payer_address_post,
    } = data.data;

    // подготовка данных списков {
    const has_delivery = ( (delivery_is_needed === 1) && (delivery_method_id !== null) && (delivery_method_id > 0) );

    const delivery_method  = find( +delivery_method_id, delivery.data ?? [], has_delivery);
    const delivery_price_is_fixed = (delivery_method !== null) ? delivery_method.is_fixed_price === 1 : null;

    const delivery_regions = ( delivery_method ) ? delivery_method.regions ?? [] : [];
    const delivery_region  = find( +delivery_region_id, delivery_regions );

    const delivery_towns   = ( delivery_region ) ? delivery_region.towns ?? [] : [];
    const delivery_town    = find( +delivery_town_id, delivery_towns );

    const delivery_methods_select = toSelectOptions( delivery.data ?? [] );
    const delivery_regions_select = toSelectOptions( delivery_regions );
    const delivery_towns_select   = toSelectOptions( delivery_towns   );

    // }
    useEffect(() => {
        updatePriceValues();
    }, [delivery_is_needed, delivery_method_id, delivery_town_id, delivery_copies_count]);

    useEffect(() => {
        doValidateAll();
    }, [data.isLoaded, delivery_is_needed]);


    const updatePriceValues = () => {
        let price       = null;
        let time        = null;

        if (delivery_method !== null) {
            const price_multiplier = delivery_method.price_multiplier;

            if (delivery_price_is_fixed) {
                price = round0(delivery_method.price * price_multiplier);
                time  = delivery_method.time;
            } else {
                if (delivery_town !== null) {
                    price = round0(delivery_town.price * price_multiplier);
                    time  = delivery_town.time;
                }
            }
        }

        if (isEmpty(time)) time = 'по запросу';

        data.updateValues( {
            'delivery_price_calculated'      : price,
            'delivery_time_calculated'       : time,
            'delivery_print_price_calculated': round0( (delivery_print_one_copy_price ?? 0) * delivery_copies_count),
        } );
    }

    const onChange = (e) => {
        let { value, name, type, checked } = e.target;

        if (type === 'checkbox') value = checked ? 1 : 0;

        doValidate(name, value);

        let values = { [name]: value };

        if (name === 'delivery_method_id')
            values = { ...values,
                'delivery_region_id': null,
                'delivery_town_id'  : null,
            };

        if (name === 'delivery_region_id') {
            // если в списке городов только один вариант - сразу его выбираем
            const hasSingleTown = (delivery_towns !== null) && (delivery_towns.length === 1);

            values = { ...values,
                'delivery_town_id': hasSingleTown ? delivery_towns[0].id : null,
            };
        }

        data.updateValues( values );
    };

    const payer_address     = ifEmpty(payer_address_juridical, ifEmpty(payer_address_post, '') );
    const has_payer_address = isNotEmpty(payer_address);

    const get_bool = (value) => (value === 1     ) ? 'true' : 'false';
    const get_int  = (value) => (value === 'true') ? 1      : 0      ;

    const isLoading       = data.isLoading || delivery.isLoading;
    const isLoaded        = data.isLoaded  && delivery.isLoaded ;
    const isVisibleRegion = delivery_regions.length > 0;
    const isVisibleTown   = delivery_towns.length   > 0;

    const outValue = (title, value, def = '') => <>
        <Subtitle2>{title}</Subtitle2>
        {ifEmpty(value, def)}
    </>

    const formFields = (isDisabled) => isLoading
        ? (show_preloader && <Preloader />)
        : <>
            {is_editable
                ? <>
                    <Subtitle2>{T.needDelivery}</Subtitle2>
                    <Radio
                        radios     = {T.isNeedDeliveryRadio}
                        nameRadio  = "deliveryIsNeeded"
                        setValue   = {(value) => data.updateValue('delivery_is_needed', get_int(value))}
                        radioValue = {get_bool(delivery_is_needed)}
                        disabled   = {isDisabled}
                    />
                </>
                : ( (delivery_is_needed === 0)
                    ? <p><strong>{T.noDelivery}:</strong></p>
                    : null
                )
            }

            {(delivery_is_needed === 1) && <>
                {is_editable
                    ? <FloatingInput
                        name        = "delivery_copies_count"
                        placeholder = {T.deliveryCopiesCount}
                        value       = {delivery_copies_count}
                        onChange    = {onChange}
                        onBlur      = {(e) => { if (e.target.value < 1) e.target.value = 1; onChange(e); }}
                        type        = "number"
                        min         = "1"
                        disabled   = {isDisabled}
                    />
                    : outValue(T.deliveryCopiesCount, delivery_copies_count, 'не задано')
                }

                {is_editable
                    ? <>
                        <FloatingSelect
                            title        = {delivery_method_id ? T.typeDeliveryTitle : null}
                            name         = "delivery_method_id"
                            value        = {delivery_method_id ? delivery_method_id : -1}
                            onChange     = {onChange}
                            options      = {delivery_methods_select}
                            placeholder  = {delivery_method_id ? null : T.typeDeliveryTitle}
                            disabled     = {isDisabled}
                        />
                        {isVisibleRegion && <FloatingSelect
                            title        = {delivery_region_id ? T.deliveryRegion : null}
                            name         = "delivery_region_id"
                            value        = {delivery_region_id ? delivery_region_id : -1}
                            onChange     = {onChange}
                            options      = {delivery_regions_select}
                            placeholder  = {delivery_region_id ? null : T.deliveryRegion}
                            disabled     = {isDisabled}
                        />}
                        {isVisibleTown && <FloatingSelect
                            title        = {delivery_town_id ? T.deliveryTown : null}
                            name         = "delivery_town_id"
                            value        = {delivery_town_id ? delivery_town_id : -1}
                            onChange     = {onChange}
                            options      = {delivery_towns_select}
                            placeholder  = {delivery_town_id ? null : T.deliveryTown}
                            disabled     = {isDisabled}
                        />}
                    </>
                    : <>
                                           {outValue(T.typeDeliveryTitle, delivery_method?.name, 'не задан' )}
                        {isVisibleRegion && outValue(T.deliveryRegion   ,delivery_region?.name , 'не указан')}
                        {isVisibleTown   && outValue(T.deliveryTown     ,delivery_town?.name   , 'не указан')}
                    </>
                }

                {(is_editable && (delivery_price_calculated !== null)) && <p style={{marginLeft: "20px", marginTop: is_editable ? "0px" : "10px", marginBottom: "10px"}}>
                    <Row>
                        <Col col={1} style={{minWidth: "100px"}}>
                            Доставка<br />
                            Распечатка<br />
                            <strong> Итого</strong><br />
                            <strong> Срок</strong>
                        </Col>
                        <Col col={1} style={{minWidth: "100px"}}>
                            : {numbToLocaleCurrency(is_editable ? delivery_price_calculated       : delivery_price       )}<br />
                            : {numbToLocaleCurrency(is_editable ? delivery_print_price_calculated : delivery_print_price )}<br />
                            <strong>: {numbToLocaleCurrency(is_editable ? delivery_print_price_calculated + delivery_price_calculated : delivery_print_price + delivery_price)}</strong><br />
                            <strong>: {is_editable ? delivery_time_calculated : delivery_time}</strong>
                        </Col>
                    </Row>
                </p>}

                {is_editable
                    ? <Row>
                        <Col col="auto">
                            <FloatingInput
                                // isTextarea  = {true}
                                name         = "delivery_address"
                                placeholder  = {T.deliveryAddress}
                                wrong        = {validate && validator.isWrong('delivery_address')}
                                wrongMessage = "Адрес обязателен"
                                onChange     = {onChange}
                                value        = {(delivery_address_is_from_payer && has_payer_address) ? payer_address : (delivery_address ?? '') }
                                disabled     = {(delivery_address_is_from_payer || isDisabled)}
                            />
                        </Col>

                        {has_payer_address && <Col col="auto" style={{ display: 'flex', alignItems: 'center' }}>
                            <InputCheckbox
                                name        = "delivery_address_is_from_payer"
                                placeholder = {
                                    payer_type_id == null
                                        ? T.deliveryAddressMatchesPhis
                                        : payer_type_id === 1
                                            ? T.deliveryAddressMatchesPhis
                                            : T.deliveryAddressMatches
                                }
                                onChange    = {onChange}
                                checked     = {delivery_address_is_from_payer}
                                disabled    = {isDisabled}
                            />
                        </Col>}
                    </Row>
                    : outValue(T.deliveryAddress, delivery_address, 'не указан')
                }

                {has_track_number && ( is_editable
                    ? <FloatingInput
                        name        = "delivery_track_number"
                        placeholder = {T.trackNumber}
                        onChange    = {onChange}
                        value       = {delivery_track_number ?? ''}
                        disabled    = {isDisabled}
                    />
                    : ( isNotEmpty(delivery_track_number) ? outValue(T.trackNumber, delivery_track_number) : null )
                )}
            </>}
        </>;


    return {
        isLoading: isLoading,
        isLoaded : isLoaded,

        data     : data.data,

        form     : formFields,

        isValid  : validator.isValid,
    }

};

export default DeliveryFormFields;
