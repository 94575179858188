// prettier-ignore-start
import React from "react";

import { useHistory }                 from "react-router-dom";

import { T }                          from "../../../lang/ru";
import { Category, Company, getPath } from "../../../api/methods";
import { PATHS }                      from "../../../constants/RoutesPaths";
import { isValidJsonCheck }           from "../../../functions/validators";
import { useUserAccessRightChecks }   from "../../../hooks/useUserAccessRightChecks";
import List                           from "../../../components/Lists/List";
import LIF                            from "../../../components/Lists/ListItemFormats";
import LIT                            from "../../../components/Lists/ListItemTypes";


const SettingsCategories = () => {

    const history    = useHistory();
    const userAccess = useUserAccessRightChecks();

    const optionsExecutorCompanies = (setOptions, fail, item) => Company.listSelectOnCategory( {category_id: item.id}, ({ data }) => setOptions(data), fail );
    const gotoTemplates  = (id) => history.push( getPath( PATHS.settings.categoryTemplates, { category_id: id } ) );
    const gotoForms      = (id) => history.push( getPath( PATHS.settings.categoryForms    , { category_id: id } ) );

    const clean0 = (value) => (value === 0) ? '-' : value;

    const formatCounters = (id, item, itemProps) => <small>
        {clean0(item['forms_count'])}<br/>{clean0(item['applications_count'])}<br/>{clean0(item['base_templates_count'])}
    </small>


    return <List
        //title        = {T.editSources}
        popupPrefix  = {'category'}
        getListData  = {Category.getAll}

        buttons      =  {[
            {type: 'new'},
        ]}

        cols         = {[
            {name: 'id'                      , title: 'id'                        , format: LIF.text},
            {name: 'name'                    , title: 'название'                  , format: LIF.linkEdit, type: LIT.text           , canAddAndEdit: true , isValid: (val) => val !== ""           , style: { textAlign:'left' } },
            {name: 'executor_company_id'     , title: 'Организация-исполнитель'   , format: LIF.hidden  , type: LIT.select         , canAddAndEdit: true , isValid: () => true                    , default: -1   , getOptions: optionsExecutorCompanies },
            {name: 'executor_company_name'   , title: 'Организация-исполнитель'   , format: LIF.text    , type: LIT.text           , canAddAndEdit: false },
            {name: 'postfix'                 , title: 'постфикс'                  , format: LIF.text    , type: LIT.text           , canAddAndEdit: true , isValid: (val) => val !== ""           , default: ''   },
            {name: 'app_in_row_bonuses_count', title: 'бонусов за "заявку подряд"', format: LIF.text    , type: LIT.number_positive, canAddAndEdit: true , isValid: (val) => val >= 0             , default: 0    },
            {name: 'delivery_print_price'    , title: 'сумма за распечатку отчета', format: LIF.text    , type: LIT.number_positive, canAddAndEdit: true , isValid: (val) => val >= 0             , default: 0    },
            {name: 'props'                   , title: 'параметры'                 , format: LIF.hidden  , type: LIT.textarea       , canAddAndEdit: true , isValid: (val) => isValidJsonCheck(val), default: '{}' },

            {name: 'forms_count'             , title: <>форм<br/>заявок<br/>шаблонов<br/></>            , format: LIF.text    , customFormatHandler: formatCounters },
        ]}

        actions = {[
            {title: 'формы'  , onClick: gotoForms    , canDo: () => true },
            {title: 'шаблоны', onClick: gotoTemplates, canDo: () => userAccess.isAllowSysSettingsTemplates },
        ]}

        onNew        = {Category.add}
        onEdit       = {Category.update}
        onDelete     = {Category.delete}

        canDelete    = {(item) => (item['applications_count'] === 0) && (item['forms_count'] === 0) && (item['base_templates_count'] === 0)}

        textOnDelete = {(item) => <>{T.deleteCategory}<b>{item?.name ?? ""}</b>?</>}
    />

};

export default SettingsCategories;
