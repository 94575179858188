import React from 'react';

import SmallInputElement from './SmallInputElement';

export const CRUDStructElement = ({ struct, deleteStruct, elementId }) => {
  return (
    <>
      {struct.map(({ name, id }) => (
        <div key={id}>
          <SmallInputElement
            name={name}
            id={id}
            key={id}
            struct={struct}
            deleteStruct={deleteStruct}
            elementId={elementId}
          />
        </div>
      ))}
    </>
  );
};
