import {TrColored} from "./OrderTableRowColors";
import styled from "styled-components";


// prettier-ignore-start
const ButtonArchiveContainer = styled.div`
    position       : absolute;
    display        : none;
    border         : none !important;
    right          : -195px;
    align-items    : center;
    justify-content: center;
    padding        : 10px 15px !important;
    &:hover {
        display: flex;
    }
`;


export const OrdersListTableRow = styled(TrColored)`
    td:first-child {
        white-space: nowrap !important;
    }
    .admin-registry-text {
        margin : 0 0 10px;
        padding: 0 !important;
        
        &:nth-child(last) {
            margin: 0;
        }
    }
    
    ${(props) =>
    props.disabled
        ? `td {
                position      : relative;
                filter        : blur(2px);
                pointer-events: none;
            }`
        : null
    }
  
    &:hover {
        & ${ButtonArchiveContainer} {
            display: flex;
        }
    }
`;

// prettier-ignore-end
