import styled from 'styled-components'
import { black } from '../../constants/Colours'

type Props = {
    src: string,
    height: number,
    animation?: "SPIN",
}

const ImgFluid = styled.img`
    width:100%;
    height: ${(props: Props) => props.height + "px"}; 
    animation: ${(props: Props) => props.animation === "SPIN" ? "spin 2s linear infinite" : ""};  
 
    @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }
`

export default ImgFluid