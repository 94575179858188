import React, { useEffect, useState } from 'react';

import { useHistory }              from 'react-router-dom';

//import { useDispatch }             from 'react-redux';
import styled                      from 'styled-components';

import {getPath, Payers, User} from '../../api/methods';
import { T }                       from '../../lang/ru';
import {useUserAccessRightChecks}  from "../../hooks/useUserAccessRightChecks";
//import { setIsHiddenMobileButton } from '../../../store/isHiddenMobileButton/action';

import { PATHS }                   from '../../constants/RoutesPaths';
//import { green }                   from '../../../constants/Colours';

import { formatDate }              from '../../functions/common/common';
import { authSuccess }             from '../../functions/auth';
//import {isNotEmpty}                from "../../../functions/common";
//import { isValidDateCheck }        from '../../../functions/validators';

import Button                      from '../../components/UI-kit/Buttons/Button';
//import Text                        from '../../../components/UI-kit/Text';
//import { LinkButton }              from '../../../components/UI-kit/Buttons/LinkButton';
import { Preloader }               from '../../components/UI-kit/Preloader/Preloader';
import PersonTable                 from './PersonTable';
import BackTitleButton from "../Orders/BackTitleButton";


type Props = {
  match: {
    params: {
      payer_id: string;
      order_id?: string;
    };
  };
};


const PayerInfo: React.FC<Props> = (props) => {

  const userAccess:any = useUserAccessRightChecks();

  const history    = useHistory();
//  const dispatch   = useDispatch();

  const payer_id = props.match.params.payer_id;
  const order_id = props.match.params.order_id ?? null;

  const [payer             , setPayer             ] = useState<any>(null);
//  const [isEditCustomerInfo, setIsEditCustomerInfo] = useState<boolean>(false);
//  const [isUpdateProccess  , setIsUpdateProccess  ] = useState<boolean>(false);
//  const [isSuccessUpdate   , setIsSuccessUpdate   ] = useState<boolean>(false);

//  const startEdit = () => setIsEditCustomerInfo(true);
//  const endEdit   = () => setIsEditCustomerInfo(false);

  useEffect(() => {
    getPayerInfo();

//    dispatch(setIsHiddenMobileButton(false));
  }, []);

  const getPayerInfo = () => Payers.getById({ id: String(payer_id) }, getPayerInfoSuccess, getPayerInfoFail);

  const getPayerInfoFail = () => history.push('/');

  const getPayerInfoSuccess: (res: any) => void = ({ data }) => {
    const info = data[0];
    const { created_at, updated_at } = info;

    //delete info.user_id;
    info.created_at = updateDateFormat(created_at);
    info.updated_at = updateDateFormat(updated_at);

    setPayer(info);
  };

  const updateDateFormat = (dateString: any) => {
    const date = formatDate(dateString);
    const [, time] = dateString.split(' ');
    const [hour, minute] = time.split(':');
    return `${date} ${hour}:${minute}`
  };

//  const goToEditCustomer   = () => history.push(PATHS.customer.addNewCustomer + `/${payer_id}`);
//  const goToAddNewCustomer = () => history.push(PATHS.customer.addNewCustomer);

  const pathBack = order_id
    ? getPath(PATHS.orderInfo, {order_id})
    : PATHS.orders;

  const loginAsUser = () =>
      User.loginAs({ id: payer.user_id }, authSuccess, () => alert('Не удалось войти под пользователя') );

  /*
    const [errorText, setErrorText] = useState('');

    const saveNewInfo = () => {
      startUpdate();
      const data = replaceNullsValues({ ...payer });

      const isServerDate = data.contract_date.split('-').length == 3;
      const isValidDate = isServerDate || isValidDateCheck(data.contract_date);

      if (!isValidDate) {
        setErrorText('Формат даты договора ДД.ММ.ГГГГ');
        setIsUpdateProccess(false);
        return;
      }

      data.contract_date = data.contract_date.split('.').reverse().join('-');

      Payers.update(data, finishUpdate, error);

      function error() {
        setErrorText('Не удалось сохранить информацию');
        setIsUpdateProccess(false);
      }
    };

    const replaceNullsValues = (obj: any) => {
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        const value = obj[key];
        if (value == null) {
          obj[key] = '';
        }
      });
      return obj;
    };

    const startUpdate = () => {
      setIsUpdateProccess(true);
      setIsSuccessUpdate(false);
    };

    const finishUpdate = () => {
      setErrorText('');
      setIsUpdateProccess(false);
      setIsSuccessUpdate(true);
      endEdit();
      getPayerInfo();
    };

  */
  const handleChangeCustomerInfo = (e: any) => {
    const { value, name } = e.target;

    setPayer({ ...payer, [name]: value });
  };

  const names = {
    Email             : 'Email',
    adress            : 'Адрес',
    bank              : 'Банк',
    bik_bank          : 'БИК Банка',
    date_get          : 'Дата получения',
    inn               : 'ИНН',
    kor               : 'Кор. счёт',
    kpp               : 'КПП',
    legal_adress      : 'Юридический адрес',
    base              : 'Дейсвтующий на основании',
    middlename        : 'Отчество',
    name              : 'Имя',
    name_ip           : 'Наименование ИП',
    name_org          : 'Наименование организации',
    number            : 'Cчёт',
    ogrn              : 'ОГРН',
    phone             : 'Телефон',
    position          : 'Должность',
    ras               : 'Расчетный счёт',
    serial            : 'Серия',
    surname           : 'Фамилия',
    // type              : "Является",
    created_at        : 'Дата регистрации',
    updated_at        : 'Дата обновления',
    id                : 'Номер заказчика',
    who_give          : 'Кто выдал',
    contract_number   : 'Номер договора',
    contract_date     : 'Дата договора',
    user_f_i_o_short  : 'Имя пользователя',
    user_bonuses_count: 'Баланс бонусов пользователя'
  };

  const listDisabledToUpdate = ['id', 'created_at', 'updated_at', 'user_f_i_o_short', 'user_bonuses_count'];
  const listToShow           = ['contract_date', 'contract_number'];
  const listToHide           = (! userAccess.isAllowPayerUserInfo) ? ['user_f_i_o_short', 'user_bonuses_count'] : []


  if (! userAccess.isAllowPayer) return null;
  return (
      <>
          <BackTitleButton backTo={pathBack} title={T.infoAboutCustomer} />

          {userAccess.isAllowUserLoginAs && <Button onClick={loginAsUser}>Войти под пользователя</Button>}

          {payer
              ? <>
                  <PersonTable
                    handleChange         = {handleChangeCustomerInfo}
                    titles               = {names}
                    listDisabledToUpdate = {listDisabledToUpdate}
                    listToShow           = {listToShow}
                    listToHide           = {listToHide}
                    infoCustomer         = {payer}
                    loadUserInfo         = {getPayerInfo}
                    saveFunc             = {Payers.update}
                  />


{/*
                  {isNotEmpty(errorText) && <Text style={{ marginTop: 10 }}>{errorText}</Text>}

                  {isSuccessUpdate &&
                      <Text style={{ marginTop: 10 }} color={green}>
                          Информация о заказчике успешно обновлена
                      </Text>
                  }
*/}
              </>
              : <Preloader />
          }
      </>
  );
};

export default PayerInfo;
