import React, { useEffect, useState } from "react";

import Title from "../UI-kit/Title";
import Button from "../UI-kit/Buttons/Button";

import {usePreLoader} from "../../hooks/usePreLoader";
import {formMode, isFileType} from "./constants";
import FormFieldsList from "./Parts/FormFieldsList";
import {getFormFileImageUrlKeyName} from "./Parts/FormFileImage";
import {useSaving} from "../../hooks/useSaving";
import BackTitleButton from "../../routes/Orders/BackTitleButton";
import {ifEmpty, isNotEmpty} from "../../functions/common";
import {useHistory} from "react-router-dom";

// prettier-ignore-start

export const prepareFields = (fieldsList) => {  // обогащение списка полем с собственным именем. (name)
    let result = {};
    Object.keys(fieldsList).map( (key) => {
        result[key] = {...fieldsList[key], name: key};
    } )
    return result;
}

/*
///////////////////////////////////////////////////////
// ВВОДНЫЕ (ПРИМЕР)

// поля формы
//   NB!!не использовать имя поля = 'file' - системный флаг загрузки нового файла/файлов
const fieldsList_example = prepareFields({
  nameCompany             : { type: fieldType.input    , dataName: 'name'               , text: T.nameCompany             }, // Наименование
  print_file              : { type: fieldType.fileImage, dataName: 'print_file'         , text: T.addCompanyStamp         }, // скан печати
});

// структура формы
const fieldsStruct = [
    { type: fieldType.group, text: T.enterCompanyInformation, props: {useGrid: true},  contains: [
        fieldsList.nameCompany,
    ]},
    fieldsList.print_file,
];
///////////////////////////////////////////////////////
*/


const Form = ({
    mode = formMode.new, // edit || new

    fieldsList = {},
    fieldsStruct = [],

    doGet    = (success, fail) => {},
    doNew    = (data, success, fail) => {},
    doUpdate = (data, success, fail) => {},

    backTo   = null,

    textTitleNew= '',
    textTitleEdit= '',
    textBack = '',
}) => {
    const history   = useHistory();
    const preloader = usePreLoader( false );
    const saving    = useSaving();

    // значения по-умолчанию
    const getFieldsDefaultValues = () => {
        let result = {};
        Object.values(fieldsList).map( (field) => result[field.dataName] = '' )
        return result;
    }
    const fieldsDefaultValues = getFieldsDefaultValues();

    const [data, setData] = useState(fieldsDefaultValues);

    const isEditMode = (mode === formMode.edit);

    const doSave = isEditMode ? doUpdate : doNew;

    const goBack = () => history.push(backTo);

    const onClickSave = (e) => {
        saving.activate();
        doSave(
            data,
            () => {
                saving.deactivate(true);
                goBack();
            },
            () => {
                saving.deactivate(false);
            }
        );
    }

    /*
     const getPostFields = () => {
         let result = [];
         Object.entries(fieldsList).map( (key, value) => result.push(value.dataName) );
         return result;
     }
     const postFields = getPostFields();
     /**/

    useEffect(() => {
        if (isEditMode) loadData();
    }, []);

    const loadData = () => {
        preloader.activate();
        doGet(loadDataSuccess, loadDataFail);
    }

    const loadDataSuccess = ({ data }) => {
        // обработка данных. заполняем данными по-умолчанию
        let newData = fieldsDefaultValues;

        // - если значение не задано, то оно остается равным значению по-умолчанию
        // - если это файловая ссылка, то к ключу добавляем '_url' и сохраняем там ссылку на ранее загруженный файл
        //     новый выбранный файл будет сохраняться по оригинальному ключу
        Object.values(fieldsList).map( (field) => {
            const value  = data[field.dataName] ?? '';
            const isEmpty = value === null || value === 'null' || value === '';

            if (isFileType(field.type)) {
                if (! isEmpty) {
                    newData[getFormFileImageUrlKeyName(field.dataName)] = value;
                }
            } else {
                if (! isEmpty) newData[field.dataName] = value;
            }
        } );


        setData(newData);

        preloader.deactivate();
    };

    const loadDataFail = () => {
        preloader.deactivate();
    }

    const updateDataField = (updateData) => {
        setData({ ...data, ...updateData });
    }

    const doUpdateDataField = (e) => {
        const { name, value } = e.target;
        updateDataField( { [fieldsList[name].dataName]: value } );
    }

    const textTitle = isEditMode ? textTitleEdit : textTitleNew;

    return (
        <>
            {(isNotEmpty(backTo))  // isNotEmpty(textBack) &&
                ? <BackTitleButton title={ifEmpty(textTitle, textBack)} backTo={backTo}/>
                : (textTitle !== '') && <Title>{textTitle}</Title>
            }

            {isEditMode && preloader.isActive
                ? preloader.wait()
                : <FormFieldsList
                    fieldsStruct = {fieldsStruct}
                    data         = {data}
                    updateData   = {updateDataField}
                    onChange     = {doUpdateDataField}
                    onBlur       = {doUpdateDataField}
                />
            }

            <Button onClick={goBack} style={{marginLeft: '0px', marginTop: '30px'}}>Отмена</Button> <Button onClick={onClickSave}>Сохранить</Button>
            {saving.spinner()}
        </>
    );

}

export default Form;