import React from "react";
import styled from "styled-components";
import { lightgray, white } from "../../constants/Colours";
import Subtitle from "../UI-kit/Subtitle";

const AlertBlock = styled.div`
  content: "";
  position: fixed;
  display: flex;
  justify-content: center;
  visibility: hidden;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(155, 155, 155, 0);
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  &.show {
    background: rgba(155, 155, 155, 0.8);
    visibility: visible;
  }
`;

const AlertBody = styled.div`
  min-width: 350px;
  background: ${white};
  border-radius: 10px;
  opacity: -1;
  position: absolute;
  //top: -100%;
  top: 25%;
  transition: all 0.1s linear;
  border: 1px solid ${lightgray};
  padding: 25px;
  &.show {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }
  & ${Subtitle} {
    margin-top: 0;
  }
`;

const Alert = ({ title, isShow, buttons, children }) => {
  return (
    <AlertBlock className={isShow ? "show" : ""}>
      <AlertBody className={isShow ? "show" : ""}>
        {title && (title !== '') && <Subtitle>{title}</Subtitle>}
        {children}
        {buttons}
      </AlertBody>
    </AlertBlock>
  );
};

export default Alert;
