import styled from "styled-components";
import { accent, darkaccent, gray, red } from "../../../constants/Colours";
import Button from "./Button";

type Props = {
  marginLeft?: boolean;
  marginRight?: boolean;
  marginTop?: string;
  color?: string;
};

const SecondOrderButton = styled(Button)`
  color: ${(props: Props) => (props.color ? props.color : accent)};
  padding: 5px 0px;
  margin: 0;
  border: none;
  margin-left: ${(props: Props) => (props.marginLeft ? "30px" : "none")};
  margin-right: ${(props: Props) => (props.marginRight ? "30px" : "none")};
  margin-top: ${(props: Props) => (props.marginTop ? props.marginTop : "none")};
  background: transparent;
  &.delete-button {
    color: ${gray};
    :hover {
      color: ${red};
    }
  }
  :hover {
    background: transparent;
    color: ${darkaccent};
  }
  :active,
  :focus {
    background-color: transparent;
    background-size: 100%;
    color: ${darkaccent};
    transition: background 0s;
  }
`;

export default SecondOrderButton;
