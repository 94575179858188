// prettier-ignore-start
import React from "react";

import {Source} from "../../../api/methods";
import { T }    from "../../../lang/ru";

import List     from "../../../components/Lists/List";
import LIF      from "../../../components/Lists/ListItemFormats";
import LIT      from "../../../components/Lists/ListItemTypes";


const SettingsListsSources = () => {
    return (
        <List
            //title        = {T.editSources}
            popupPrefix  = {'source'}
            getListData  = {Source.getAll}
            cols         = {[
                {name: 'name'              , title: 'название', format: LIF.linkEdit    , type: LIT.text  , canAddAndEdit: true, isValid: (val) => val !== ""},
                {name: 'applications_count', title: 'заявок'  , format: LIF.usageCounter},
            ]}
            buttons      =  {[
                {type: 'new'},
            ]}

            onNew        = {Source.add}
            onEdit       = {Source.update}
            onDelete     = {Source.delete}

            canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteSource}<b>{item?.name ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsSources;
