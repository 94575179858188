import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../components/UI-kit/Buttons/Button';
import SecondOrderButton from '../components/UI-kit/Buttons/SecondOrderButton';
import FloatingInput from '../components/UI-kit/FloatingInput';
import Text from '../components/UI-kit/Text';
import Title from '../components/UI-kit/Title';

import { Auth } from '../api/methods';
import { INPUT_CONFIGS } from '../constants/InputConfigs';
import { PATHS } from '../constants/RoutesPaths';
import { formDataFromInputInfoForState } from '../functions/common/common';
import { isValidEmailCheck } from '../functions/validators';
import { T } from '../lang/ru';
import { green, yellow } from '../constants/Colours';
import { useDispatch } from 'react-redux';
import { setIsHiddenMobileButton } from '../store/isHiddenMobileButton/action';
import Subtitle2 from '../components/UI-kit/Subtitle2';

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, changeDataField] = useState(
    formDataFromInputInfoForState(INPUT_CONFIGS.forgotPassword)
  );
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isAuthError, setIsAuthError] = useState<boolean>(false);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    changeDataField({ email: '', password: '' });
    dispatch(setIsHiddenMobileButton(false));
    localStorage.clear();
  }, []);

  const onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e
  ) => {
    const { value, name } = e.target;
    changeDataField({ ...data, [name]: value });
  };

  const resetPassword = () => {
    Auth.forgotPassword(data, success, error);
    function success() {
      setIsSuccess(true);
      setIsError(false);
    }
    function error() {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  const authFail: () => void = () => setIsAuthError(true);
  const goToAuth: () => void = () => history.push(PATHS.login);

  const checkEmailValid: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e
  ) => {
    setIsValidEmail(isValidEmailCheck(e.target.value));
  };

  const handleEnter = (e: any) => (e.charCode == 13 ? resetPassword() : null);

  return (
    <>
      <Title>Восстановление пароля</Title>
      <Subtitle2>
        На указанный электронный адрес будет отправлено письмо для
        восстановления пароля
      </Subtitle2>

      <FloatingInput
        wrongMessage={T.inCorrectEmail}
        wrong={!isValidEmail}
        name="email"
        value={data.email}
        onBlur={checkEmailValid}
        onChange={onChangeHandler}
        placeholder={'Email'}
        required={true}
        onKeyPress={handleEnter}
      />

      {isSuccess && (
        <Text color={green} withoutMargin={true}>
          Письмо для восстановления пароля успешно отправлено
        </Text>
      )}
      {isError && (
        <Text color={yellow} withoutMargin={true}>
          Не удалоось отправить письмо для восстановления пароля. Пожалуйста,
          проверьте введенный Email или попробуйте позже
        </Text>
      )}

      <Button
        disabled={isSuccess}
        style={{ marginRight: 20 }}
        onClick={resetPassword}
      >
        Отправить письмо для восстановления пароля
      </Button>
      <SecondOrderButton onClick={goToAuth}>Назад</SecondOrderButton>
    </>
  );
};

export default ForgotPassword;
