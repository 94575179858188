import React from "react";

import { Link } from "react-router-dom";


// prettier-ignore-start

export const noSpaceStyle = {
    margin : '0 0 0 0',
    padding: '0 0 0 0'
};

const fixedStyle = {
    className: 'fixed-col',

    ...noSpaceStyle,
}

const OrdersListTableRowData = ({
    isFixed = false,
    isNoBr = false,
    to = null,
    children,
}) => {

    const fixed = (isFixed)
        ? fixedStyle
        : {};

    const childrenNoBr = (isNoBr)
        ? <nobr style={noSpaceStyle}>{children}</nobr>
        : children;

    const childrenLink = (to)
        ? <Link className="link" to={to}>{childrenNoBr}</Link>
        : childrenNoBr;


    return <td {...fixed}>{childrenLink}</td>

}

export default OrdersListTableRowData;

// prettier-ignore-end
