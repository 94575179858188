import styled from "styled-components";
import { lightgray } from "../../constants/Colours";

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  color: ${lightgray};
  background-color: ${lightgray};
  border: none;
`;
