import React from "react";

import FloatingInput from "../../UI-kit/FloatingInput";

// prettier-ignore-start

/*
// ВВОДНЫЕ (ПРИМЕР)

{ type: fieldType.input, dataName: 'name', text: 'Наименование'}, // Наименование
*/

const FormInput = ({
    name = '',
    dataName = '',
    text = '',
    data = [],
    updateData = (newData) => {},
    onChange = () => {},
    onBlur = () => {},
    props = {},
}) => {
    return (
        <FloatingInput
            key         = {name}
            name        = {name}
            value       = {data[dataName]}
            placeholder = {text}
            onBlur      = {onBlur}
            onChange    = {onChange}
        />
    );

}

export default FormInput;