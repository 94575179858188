// prettier-ignore-start
import React, {useEffect, useState} from "react";

import { useParams }             from "react-router-dom";
import {User, Category, getPath} from "../../../../api/methods";
import { PATHS }                 from "../../../../constants/RoutesPaths";
import LIF                       from "../../../../components/Lists/ListItemFormats";
import LIT                       from "../../../../components/Lists/ListItemTypes";
import ListProps                 from "../../../../components/Lists/ListProps";


const UserCategoryFormsList = () => {

    const requestIds = useParams();

    const [userInfo    , setUserInfo    ] = useState({});
    const [categoryInfo, setCategoryInfo] = useState({});

    const { user_id, category_id } = requestIds;

    useEffect(() => {
        loadUserInfo();
        loadCategoryInfo();
    }, []);


    const loadUserInfo     = () => User.getById    ({id: user_id    }, ({data}) => setUserInfo    (data));
    const loadCategoryInfo = () => Category.getById({id: category_id}, ({data}) => setCategoryInfo(data));

    const userFIO = () => (userInfo.surname) ? [userInfo.surname, userInfo.name, userInfo.middlename].join(' ') : '';


    return (
        <ListProps
            backLink        = {getPath(PATHS.userCategories, {user_id})}

            title           = {<>Эксперт {userFIO()}<br />&nbsp;&raquo; Категория &quot;{categoryInfo?.name}&quot;<br />&nbsp;&nbsp;&nbsp;&raquo; Формы</>}
            popupPrefix     = {'userCategoryFormsList'}
            isActiveColName = 'is_active'
            name            = 'форма'
            confirmName     = 'формы'

            cols            = {[
                {name: 'name', title: 'форма', format: LIF.linkEdit, type: LIT.text, canAddAndEdit: false, align: 'left'},
            ]}

            getNotAssigned  = {(setOptions                    ) => User.getNotAssignedForms({...requestIds                                           }, ({data}) => setOptions(data) )}
            getListData     = {(data           , success, fail) => User.getAssignedForms   ({...requestIds                                           }, success, fail)}
            onNew           = {({id, is_active}, success, fail) => User.assignForm         ({...requestIds, form_id: id, is_active: is_active ? 1 : 0}, success, fail)}
            onEdit          = {({id, is_active}, success, fail) => User.updateAssignedForm ({...requestIds, form_id: id, is_active: is_active ? 1 : 0}, success, fail)}
            onDelete        = {({id           }, success, fail) => User.deleteAssignedForm ({...requestIds, form_id: id                              }, success, fail)}

            newAll          = { (success) => User.assignAllForms    (requestIds, success) }
            deleteAll       = { (success) => User.deleteAllForms    (requestIds, success) }
            activateAll     = { (success) => User.activateAllForms  (requestIds, success) }
            deactivateAll   = { (success) => User.deactivateAllForms(requestIds, success) }

            canDelete       = {() => true}
            textOnDelete    = {(item) => <>Удалить у пользователя форму<b>{item?.name ? <>:<br />{item.name}</>: ''}</b>?</>}

            actions = {[
                //{title: 'параметры'       , onClick: (id) => gotoExpertCategoryFormProps(id), canDo: () => true },
            ]}
        />
    );
};

export default UserCategoryFormsList;