// prettier-ignore-start
import React from "react";

import { T }   from "../../../../../lang/ru";


export const Wishes = () => {

    const textCountLimit = 50;
    const textCountCut   = 40;

    return {

        head : (rowData) => <>{T.wishes}</>,

        body : (rowData) => {
            const text = rowData.wishes ?? '';

            return (text.length < textCountLimit)
                ? text
                : text.substring(0, textCountCut) + '...';  // <Tooltip label={text}>{text.substring(0, textCountCut)}...</Tooltip>
        },
    };

};