// prettier-ignore-start
import React from 'react';
import { useHistory }             from 'react-router-dom';

import ym                         from 'react-yandex-metrika';
import ReactGA                    from 'react-ga';

import { PATHS }                  from '../../../constants/RoutesPaths';
import { T }                      from '../../../lang/ru';
import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";

import MainCustomerButton         from '../../../components/UI-kit/Buttons/MainCustomerButton';


const OrderNewButton: React.FC = () => {

    const userAccess:any = useUserAccessRightChecks();

    const history  = useHistory();

    if (! userAccess.isAllowAppNewButton) return null;

    const gotoNewOrder: () => void = () => {
        ym('reachGoal', 'zakaz');
        ReactGA.ga('send', 'event', 'zakaz', 'click');

        history.push(PATHS.orderNewSt1SetPayer);
    };


    return <MainCustomerButton
        margin  = "10px 0 35px 0"
        onClick = {gotoNewOrder}
    >
        {T.orderCalculation}
    </MainCustomerButton>

};

export default OrderNewButton;
