// prettier-ignore-start
import React from "react";

import {useHistory}              from "react-router-dom";
import {getPath, SystemStatuses} from "../../../api/methods";

import {PATHS}                   from "../../../constants/RoutesPaths";
import List                      from "../../../components/Lists/List";
import LIF                       from "../../../components/Lists/ListItemFormats";
import LIT                       from "../../../components/Lists/ListItemTypes";
import ListFormat_Color          from "../../../components/Lists/Parts/ListFormat_Color";


const SettingsSystemStatusesList = () => {

    const history = useHistory();

    const gotoStatusRoles        = (status_id) => history.push(getPath(PATHS.settings.systemStatusRoles       , {status_id}));
    const gotoStatusAccessRights = (status_id) => history.push(getPath(PATHS.settings.systemStatusAccessRights, {status_id}));
    const gotoStatusMails        = (status_id) => history.push(getPath(PATHS.settings.systemStatusMails       , {status_id}));

    const formatColor        = (id, item, itemProps) => ListFormat_Color(item.calc_color);
    const formatActionsCount = (id, item, itemProps) => item.role_actions_count + item.role_actions_fail_count;


    return <List
        popupPrefix  = {'systemStatuses'}
        getListData  = {SystemStatuses.getAll}
        cols         = {[
          {name: 'id'                 , title: 'id'                  , format: LIF.linkEdit, type: LIT.number, canAdd: true , canEdit: false},

          {name: 'is_active'          , title: 'активность'          , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0 },
          {name: 'title'              , title: 'название'            , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'description'        , title: 'описание'            , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'is_colored'         , title: 'использовать цвет'   , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 1 },
          {name: 'color'              , title: ''                    , format: LIF.linkEdit, type: LIT.color , canAddAndEdit: true , default: '', customFormatHandler: formatColor},
          {name: 'order_id'           , title: 'позиция сортировки'  , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: true , default: 0 },
          {name: 'roles_count'        , title: 'роли'                , format: LIF.usageCounter},
          {name: 'role_actions_count' , title: 'дйст'                , format: LIF.usageCounter, customFormatHandler: formatActionsCount},
          {name: 'access_rights_count', title: 'прав'                , format: LIF.usageCounter},
          {name: 'mails_count'        , title: 'писем'               , format: LIF.usageCounter},
        ]}
        actions = {[
            {title: 'роли'  , onClick: gotoStatusRoles        },
            {title: 'права' , onClick: gotoStatusAccessRights },
            {title: 'письма', onClick: gotoStatusMails        },
        ]}
        buttons      =  {[
            {type: 'new'},
        ]}

        onNew        = {SystemStatuses.add   }
        onEdit       = {SystemStatuses.update}
        onDelete     = {SystemStatuses.delete}

        canDelete    = {(item) => (item['roles_count'] + item['role_actions_count'] + item['access_rights_count'] + item['mails_count']) === 0}

        textOnDelete = {(item) => <>Удалить статус <b>{item?.title ?? ''}</b>?</>}
    />

};

export default SettingsSystemStatusesList;
