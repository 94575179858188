// prettier-ignore-start
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory               } from "react-router-dom";
import styled                       from "styled-components";

import { Application     } from "../../../api/methods";
import { T               } from "../../../lang/ru";
import { PATHS           } from "../../../constants/RoutesPaths";

import Alert               from "../../../components/Alert/Alert";
import Button              from "../../../components/UI-kit/Buttons/Button";
import ButtonsBlock        from "../../../components/Alert/ButtonsBlock";
import SecondOrderButton   from "../../../components/UI-kit/Buttons/SecondOrderButton";
import Text                from "../../../components/UI-kit/Text";

import { setIsShowPopup  } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";


const BoldText = styled.span`
    font-weight: bold;
`;


const OrderDeletePopup = ({ id }) => {
    const dispatch = useDispatch();
    const history  = useHistory();

    const { isShowDeleteApplication } = useSelector(getIsShowPopups);

    const goToDefaultScreen = () => history.push(PATHS.default);

    const deleteApplication = () => Application.delete({ id }, deleteApplicationSuccess);
    const deleteApplicationSuccess = () => {
        closePopup();
        goToDefaultScreen();
    };

    const closePopup = () => dispatch(setIsShowPopup("isShowDeleteApplication", false));

    const nameText = <BoldText>{`ID ${id}`}</BoldText>;


    const buttons = (
        <ButtonsBlock>
            <Button onClick={deleteApplication}>{T.yes}</Button>
            <SecondOrderButton className="delete-button" onClick={closePopup}>
                {T.cancel}
            </SecondOrderButton>
        </ButtonsBlock>
    );

    return (
        <Alert
            title   = {T.deleting}
            isShow  = {isShowDeleteApplication}
            buttons = {buttons}
        >
            <Text>
                {T.deleteApplication}
                {nameText}?
            </Text>
        </Alert>
    );
};

export default OrderDeletePopup;
