// prettier-ignore-start
import React from "react";

import { TemplateFormat }    from "../../../../api/methods";
import { PATHS }             from "../../../../constants/RoutesPaths";
import List                  from "../../../../components/Lists/List";
import LIF                   from "../../../../components/Lists/ListItemFormats";
import LIT                   from "../../../../components/Lists/ListItemTypes";
import { isValidJsonCheck }  from "../../../../functions/validators";
import { isNotEmpty }        from "../../../../functions/common";


const SettingsSystemTemplateFormatsList = () => {

    return <List
        title        = 'Форматы'
        popupPrefix  = {'systemTemplateFormatsList'}
        getListData  = {TemplateFormat.list}

        buttons      =  {[
            {type: 'group', buttons: [
                {type: 'back', to: PATHS.settings.systemTemplateTypes},
            ]},
            {type: 'new'},
        ]}

        cols         = {[
            {name: 'id'             , title: 'id'      , format: LIF.linkEdit, type: LIT.number  , canAdd: true, canEdit: false},
            {name: 'name'           , title: 'имя'     , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: ''       , align: 'left', isValid: (val) => isNotEmpty(val) },
            {name: 'title'          , title: 'название', format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: ''       , align: 'left', isValid: (val) => isNotEmpty(val) },
            {name: 'props'          , title: 'свойства', format: LIF.hidden  , type: LIT.textarea, canAddAndEdit: true , default: '{}'     , align: 'left', isValid: (val) => isValidJsonCheck(val) },
            {name: 'description'    , title: 'описание', format: LIF.linkEdit, type: LIT.textarea, canAddAndEdit: true , default: ''       , align: 'left'},

            {name: 'templates_count', title: 'шаблонов', format: LIF.usageCounter},
        ]}

        onNew        = {TemplateFormat.create}
        onEdit       = {TemplateFormat.update}
        onDelete     = {TemplateFormat.delete}

        canDelete    = { (item) => (item['templates_count'] === 0) }

        textOnDelete = { (item) => <>Удалить формат <b>{item?.title ?? ''}</b>?</> }
    />

};

export default SettingsSystemTemplateFormatsList;
