// prettier-ignore-start
import ActionMessageForm     from "./Base/Forms/ActionMessageForm";
import ActionAppDataAct      from "./Base/ActionAppDataAct";
import ActionFormType        from "./ActionFormType";


const ActionMessageFormAppDataAct = () => {

    const data     = ActionAppDataAct().data;
    const formCols = ActionAppDataAct().formCols;

    const form = (props) => ActionMessageForm().form({...props, onRun: ActionAppDataAct().onSave}, formCols);

    return {
        type    : ActionFormType.popupForm,

        data    : data,
        formCols: formCols,
        form    : form,
    }

};

export default ActionMessageFormAppDataAct;
