// prettier-ignore-start
import React from 'react';

import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";

import OrdersListFiltered           from "./OrdersListFiltered";
import OrdersListAdminPanel         from "./AdminPanel/OrdersListAdminPanel";
import OrdersListCustomer           from "./OrdersListCustomer";


const OrdersList = ({tabs = []}) => {

    const userAccess = useUserAccessRightChecks();

    if (userAccess.isAppListAdmin   ) return <OrdersListAdminPanel tabs={tabs} />
    if (userAccess.isAppListShort   ) return <OrdersListFiltered               />
    if (userAccess.isAppListCustomer) return <OrdersListCustomer               />

    return null;

};

export default OrdersList;

