// prettier-ignore-start


export const Deadlines = () => {

/*
  const updateWorkDeadlineInput = (e) => {
    const { value } = e.target;
    const data = { id, days: value };

    startUpdateApplication();
    Application.setWorkDeadlineDays(data, finishUpdateApplication);
  };

    <th className="short">{T.deadlineOfExecution}</th>
    <th className="short">{T.deadlineOfContract}</th>

      <td>
        <OrderTableInput
          name        = "work_deadline"
          value       = {application.work_deadline}
          onChange    = {handleChangeInput}
          onBlur      = {updateWorkDeadlineInput}
          placeholder = {T.deadlineOfExecution}
        />
      </td>

      <td>
        <OrderTableInput
          name        = "deadline"
          value       = {application.deadline}
          onChange    = {handleChangeInput}
          onBlur      = {updateInput}
          placeholder = {T.deadlineOfContract}
        />
      </td>

*/
    return {
        head : (rowData) => 'Срок в договоре / для исполнителя',
            //[T.deadlineOfExecution, T.deadlineOfContract],

        body : (rowData) => {
            const {work_deadline='', deadline=''} = rowData;

            return deadline +' / '+ work_deadline
                //[deadline, work_deadline];
        },

        headProps : (rowData) => ({ className: "fixed-row short" }),

    };

};