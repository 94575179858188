// prettier-ignore-start
import { userAnswers } from 'models/userAnswers.model';
import T               from './types';
import { element }     from "../../models/elements.model";

export const setUserAnswer = (key: string, value: string, save = true) => ({
    type: T.SET_USER_ANSWER,
    key,
    value,
    save,
});

export const setAllAnswers = (answers: userAnswers) => ({
    type   : T.SET_ALL_ANSWERS,
    payload: answers,
});

export const saveAnswers = () => ({
    type: T.SAVE_ANSWERS,
});

export const clearAnswers = () => ({
    type: T.CLEAR_ANSWERS
})

export const deleteAnswer = (elements: element[]) => ({
    type: T.DELETE_9_ANSWER,
    elements,
});
