// prettier-ignore-start
import ActionMessageForm  from "./Base/Forms/ActionMessageForm";
import ActionAppDataPrice from "./Base/ActionAppDataPrice";
import ActionFormType     from "./ActionFormType";


const ActionMessageFormAppDataPrice = () => {

    const data     = ActionAppDataPrice().data;
    const formCols = ActionAppDataPrice().formCols;

    const form = (props) => ActionMessageForm().form(props, formCols);

    return {
        type    : ActionFormType.popupForm,

        data    : data,
        formCols: formCols,
        form    : form,
    }

};

export default ActionMessageFormAppDataPrice;
