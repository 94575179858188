// prettier-ignore-start
import React from "react";

import { Template }                    from "../../api/methods";
import { useUserAccessRightChecks }    from "../../hooks/useUserAccessRightChecks";
import { isNotEmpty, toSelectOptions } from "../../functions/common";
import { isValidJsonCheck }            from "../../functions/validators";
import LIF                             from "../../components/Lists/ListItemFormats";
import List                            from "../../components/Lists/List";
import LIT                             from "../../components/Lists/ListItemTypes";
import BackTitleButton                 from "../Orders/BackTitleButton";


const TemplatesList = ({
    popupPrefix = '',
    title = '',
    getListData = (d, s, f) => {},
    onNew       = (d, s, f) => {},
    backTo= ''
}) => {

    const userAccess = useUserAccessRightChecks();

    if (! userAccess.isAllowSysSettingsTemplates) return null;

    const optionsTemplates = (success) => Template.list({}, ({data}) => success( toSelectOptions(data, 'id', (item) => (item.is_active?'':'- ')+item.type_title +', '+item.title ) ));


    return <>
        <BackTitleButton title={title} backTo={backTo}/>
        <List
            popupPrefix  = {popupPrefix}
            getListData  = {getListData}
            title        = {''}

            buttons      =  {[
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'id'                 , title: 'id'                , format: LIF.linkEdit},
                {name: 'is_active'          , title: 'активность'        , format: LIF.check   , type: LIT.check   , canAddAndEdit: true , default: 1    },
                {name: 'template_is_active' , title: 'шаблон'            , format: LIF.check   ,                     canAddAndEdit: false },
                {name: 'template_type_title', title: 'тип'               , format: LIF.linkEdit,                     canAddAndEdit: false,                 align: 'left' },
                {name: 'template_title'     , title: 'название'          , format: LIF.linkEdit,                     canAddAndEdit: false,                 align: 'left' },
                {name: 'template_id'        , title: 'шаблон'            , format: LIF.hidden  , type: LIT.select  , canAddAndEdit: true , default: null ,                isValid: (val) => isNotEmpty(val), getOptions: optionsTemplates },
                {name: 'props'              , title: 'параметры'         , format: LIF.text    , type: LIT.textarea, canAddAndEdit: true , default: '{}' , align: 'left', isValid: (val) => isValidJsonCheck(val) },
            ]}

            onNew        = {onNew}
            onEdit       = {Template.updateAssigned}
            onDelete     = {Template.unassign      }

            textOnDelete = { (item) => <>Отвязать шаблон <b>{ (item?.template_type_title ?? '')+', '+(item?.template_title ?? '') }</b>?</> }
        />
    </>

};

export default TemplatesList;
