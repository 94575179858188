// prettier-ignore-start
import React from 'react';

import { useParams } from 'react-router-dom';
import Questionnaire from "../Questionnaire/Questionnaire";
import { getPath }   from "../../../api/methods";
import { PATHS }     from "../../../constants/RoutesPaths";
import BackTitleButton from "../../Orders/BackTitleButton";


type paramsType = {
  form_id        : string,
}

type propsType = {
  match: {
    params: paramsType;
  };
};


const OrderFormPreview: React.FC<propsType> = (props) => {
  const { form_id }: paramsType = useParams();

  return <>
      <BackTitleButton title='Предпросмотр опросной формы' backTo={getPath(PATHS.formEdit, { form_id } )}/>
      <Questionnaire
        isPreview      = { true }
        autosave       = { false }
        form_id        = { form_id }
        backTo         = { getPath(PATHS.formEdit, { form_id }) }
      />
  </>

};

export default OrderFormPreview;
