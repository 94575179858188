// prettier-ignore-start
import ActionFormType  from "./ActionFormType";
import { getPath }     from "../../../api/methods";
import { PATHS }       from "../../../constants/RoutesPaths";


const ActionMessageFormAppDataAnswers = () => ({
    type    : ActionFormType.gotoLocation,
    location: (app) => getPath(PATHS.orderEdit, {form_id: app.form_id, application_id: app.id} ),
});

export default ActionMessageFormAppDataAnswers;
