// prettier-ignore-start
import React from "react";

import { useParams } from 'react-router-dom';
import { getPath, Template, TemplateFormat, TemplateGenerator, TemplateType } from "../../../../api/methods";
import { isEmpty, isNotEmpty, toSelectOptions } from "../../../../functions/common";
import { PATHS }               from "../../../../constants/RoutesPaths";
import { useLoadRequest }      from "../../../../hooks/useDataRequest";
import { isValidJsonCheck }    from "../../../../functions/validators";
import List                    from "../../../../components/Lists/List";
import LIF                     from "../../../../components/Lists/ListItemFormats";
import LIT                     from "../../../../components/Lists/ListItemTypes";
import ListFormat_2Lines       from "../../../../components/Lists/Parts/ListFormat_2Lines";


const SettingsSystemTemplateTypeTemplateFilesList = () => {

    const { template_type_id, template_id } = useParams();

    const template      = useLoadRequest({ request: Template.get    , requestData: { template_id      } })
    const template_type = useLoadRequest({ request: TemplateType.get, requestData: { template_type_id } })

    const optionsGenerators = (success) => TemplateGenerator.list({}, ({data}) => success( toSelectOptions(data, 'id', 'name') ));
    const optionsFormats    = (success) => TemplateFormat.list   ({}, ({data}) => success( toSelectOptions(data, 'id', 'title') ));

    const formatFile = (data) => (isEmpty(data?.file_name) || isNotEmpty(data?.file))
        ? ''
        : ListFormat_2Lines(<a href={"#"}>{data?.file_name}</a>, isEmpty(data?.file) ? 'загруженный шаблон' : 'заменяемый шаблон', true);


    return <List
        title        = {'Файлы типа "' +(template_type.data?.title ?? '')+ '" шаблона "' +(template.data?.title ?? '')+ '"'}
        popupPrefix  = {'systemTemplateFilesList'}
        getListData  = { (d, s, f) => Template.listFiles({...d, template_id}, s, f) }

        buttons      =  {[
            {type: 'group', buttons: [
                    {type: 'back', to: getPath(PATHS.settings.systemTemplateTypeTemplates, {template_type_id} ) },
                ]},
            {type: 'new'},
        ]}

        cols         = {[
            {name: 'id'             , title: 'ID'                      , format: LIF.linkEdit,               },
            {name: 'is_active'      , title: 'активен'                 , format: LIF.check   , type: LIT.check   , canAddAndEdit: true , default: 1    , align: 'center'},
            {name: 'file'           , title: 'Выберите файл шаблона...', format: LIF.hidden  , type: LIT.file    , canAdd : true       , default: null , isValid: (val) => isNotEmpty(val) },
            {name: 'file'           , title: 'Выберите файл шаблона...', format: LIF.hidden  , type: LIT.file    , canEdit: true       , default: null },
            {name: 'file_name'      , title: 'Файл'                    , format: LIF.hidden  , type: LIT.info    , canAddAndEdit: true , customFormatHandler: formatFile},
            {name: 'file_name'      , title: 'загружен файл'           , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: false, default: ''   , align: 'left', isValid: (val) => isNotEmpty(val) },
            {name: 'file_src'       , title: 'сохранен как'            , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: false, default: ''   , align: 'left'},
            // {name: 'file_new'           , title: <b>Файл шаблона</b>, format: LIF.hidden  , type: LIT.info    , canAddAndEdit: true },
            {name: 'generator_id'   , title: 'генератор'               , format: LIF.hidden  , type: LIT.select  , canAddAndEdit: true , default: 1    , getOptions: optionsGenerators },
            {name: 'generator_name' , title: 'генератор'               , format: LIF.linkEdit,                     canAddAndEdit: false, default: ''   , align: 'left'},
            {name: 'format_id'      , title: 'формат генерации'        , format: LIF.hidden  , type: LIT.select  , canAddAndEdit: true , default: 1    , getOptions: optionsFormats },
            {name: 'format_name'    , title: 'формат генерации'        , format: LIF.linkEdit,                     canAddAndEdit: false, default: ''   , align: 'left'},
            {name: 'result_filename', title: 'имя файла результата'    , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: ''   , align: 'left'},
            {name: 'props'          , title: 'параметры'               , format: LIF.hidden  , type: LIT.textarea, canAddAndEdit: true , default: '{}' , isValid: (val) => isValidJsonCheck(val) },
            {name: 'description'    , title: 'описание'                , format: LIF.linkEdit, type: LIT.textarea, canAddAndEdit: true , default: ''   , align: 'left'},
        ]}

        onNew        = { (d, s, f) => Template.addFile   ({...d, template_id               }, s, f) }
        onEdit       = { (d, s, f) => Template.updateFile({...d, template_id, file_id: d.id}, s, f) }
        onDelete     = { (d, s, f) => Template.deleteFile({      template_id, file_id: d.id}, s, f) }

        textOnDelete = { (item) => <>Удалить файл шаблона <b>{item?.file_name ?? ''}</b>?</> }
    />

};

export default SettingsSystemTemplateTypeTemplateFilesList;
