// prettier-ignore-start
import React, { useEffect } from 'react';

import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";

import Table                  from '../../../components/UI-kit/Table/Table';
import Text                   from "../../../components/UI-kit/Text";

import OrdersListTableRowHead from "./components/OrdersListTableRowHead";
import OrdersListTableRowData from "./components/OrdersListTableRowData";
import { OrdersListTableRow } from "./components/OrdersListTableRow";

import { defaultResult      } from "./components/contents/Common";
import { OrderNumber        } from "./components/contents/OrderNumber";
import { SystemNames        } from "./components/contents/SystemNames";
import { Payer              } from "./components/contents/Payer";
import { Status             } from "./components/contents/Status";
import { ContactPerson      } from "./components/contents/ContactPerson";
import { Comments           } from "./components/contents/Comments";
import { Deadlines          } from "./components/contents/Deadlines";
import { Price              } from "./components/contents/Price";
import { PaymentStatus      } from "./components/contents/PaymentStatus";
import { CategoryForm       } from "./components/contents/CategoryForm";
import { AssignedWorkers    } from "./components/contents/AssignedWorkers";
import { RoomsCount         } from "./components/contents/RoomsCount";
import { Wishes             } from "./components/contents/Wishes";
import { Promocodes         } from "./components/contents/Promocodes";
import { SoftwareProduct    } from "./components/contents/SoftwareProduct";
import { StatusInWorkDate   } from "./components/contents/StatusInWorkDate";
import { Delivery           } from "./components/contents/Delivery";
import { Source             } from "./components/contents/Source";


const OrdersListTable = ({
    data = [],
    setData = () => {},
    ready = false,
    setReady = () => {}
}) => {
    const userAccess = useUserAccessRightChecks();

    useEffect(() => {
        setReady(true);
    }, []);


    const headData = data[0];

    let content = [];
    const contentAdd = (value, allowAccess = '') => {
        if (! Array.isArray(allowAccess)) allowAccess = [allowAccess];

        const allow = allowAccess.reduce(
            (result, value) => result && ((value === '') || (userAccess[value])),
            true
        );

        if (allow) content.push({...defaultResult, ...value});
    };

    contentAdd (OrderNumber     ());
    contentAdd (Status          ());
    contentAdd (PaymentStatus   (), 'isAllowAppListPaymentStatus'    );
    contentAdd (StatusInWorkDate(), 'isAllowAppListStatusInWorkDate' );
    contentAdd (Payer           (), ['isAllowPayer', 'isAllowAppListPayer']);
    contentAdd (SystemNames     ());
    contentAdd (CategoryForm    ());
    contentAdd (Comments        (), 'isAllowAppListComments'         );
    contentAdd (AssignedWorkers (), 'isAllowAppListWorkers'          );
    contentAdd (SoftwareProduct (), 'isAllowAppListSoftwareProduct'  );
    contentAdd (RoomsCount      (), 'isAllowAppListRoomsCount'       );
    contentAdd (Deadlines       (), 'isAllowAppListDeadlines'        );
    contentAdd (Price           (), 'isAllowAppListPrice'            );
    contentAdd (Promocodes      (), 'isAllowAppListPromocode'        );
    contentAdd (Delivery        (), 'isAllowAppListDelivery'         );
    contentAdd (Source          (), 'isAllowAppListSource'           );
    contentAdd (ContactPerson   (), 'isAllowAppListContactPerson'    );
    contentAdd (Wishes          (), 'isAllowAppListWishes'           );


    if (! ready          ) return <></>;
    if (data.length === 0) return <Text>Данные отсутствуют</Text>
    return (
        <div style={{ overflow: 'visible' }}>
            <Table style={{ position: 'absolute', left: 50}} className="miniPadding"> {/* className="miniPadding"*/}
                <thead>
                    <tr>
                        {content.map((handler, handlerIndex) => {
                            const itemHeadData  = handler.head     (headData);
                            const itemHeadProps = handler.headProps(headData);

                            return ( Array.isArray(itemHeadData)
                                ? itemHeadData.map( (item, index) =>
                                  <OrdersListTableRowHead {...itemHeadProps} key={'head_'+ handlerIndex+'_'+index}>
                                      {item}
                                  </OrdersListTableRowHead>
                                )
                                : <OrdersListTableRowHead {...itemHeadProps} key={'head_'+ handlerIndex}>
                                      {itemHeadData}
                                  </OrdersListTableRowHead>
                            );
                        })}
                    </tr>
                </thead>

                <tbody>
                    {data?.map( (order, index) => (
                        <OrdersListTableRow style={userAccess.isAllowAppListColored ? {backgroundColor: '#'+order.status_color} : {} } key={order.id}>
                            {content.map((handler, handlerIndex) => {
                                const itemBodyData  = handler.body     (order);
                                const itemBodyProps = handler.bodyProps(order);

                                return Array.isArray(itemBodyData)
                                    ? itemBodyData.map( (item, index) =>
                                      <OrdersListTableRowData {...itemBodyProps} key={'order'+order.id +'_'+ handlerIndex+'_'+index}>
                                          {item}
                                      </OrdersListTableRowData>
                                    )
                                    : <OrdersListTableRowData {...itemBodyProps} key={'order'+order.id +'_'+ handlerIndex}>
                                          {itemBodyData}
                                      </OrdersListTableRowData>
                            })}
                        </OrdersListTableRow>
                    ) )}
                </tbody>
            </Table>
        </div>
    );
};

export default OrdersListTable;
