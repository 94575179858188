// prettier-ignore-start
import ActionComment from "./ActionComment";


const ActionCommentStatus = () => {

    const fieldName = 'status_comment';

    const data     = (app  ) => ActionComment().data    (app  , fieldName);
    const formCols = (props) => ActionComment().formCols(props, fieldName, 'комментарий к статусу');

    return {
        data     : data,
        formCols : formCols,
    }

};

export default ActionCommentStatus;
