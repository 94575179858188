// prettier-ignore-start
import React from "react";

import settingsTabType                             from "../../tabType";
import SettingsSystemTemplateTypesList             from "./SettingsSystemTemplateTypesList";
import SettingsSystemTemplateGeneratorsList        from "./SettingsSystemTemplateGeneratorsList";
import SettingsSystemTemplateTypeTemplatesList     from "./SettingsSystemTemplateTypeTemplatesList";
import SettingsSystemTemplateFormatsList           from "./SettingsSystemTemplateFormatsList";
import SettingsSystemTemplateTypeTemplateFilesList from "./SettingsSystemTemplateTypeTemplateFilesList";


const SettingsSystemTemplates = ({ sub = settingsTabType.systemTemplateTypes }) => {

    if (sub === settingsTabType.systemTemplateGenerators        ) return <SettingsSystemTemplateGeneratorsList        />
    if (sub === settingsTabType.systemTemplateFormats           ) return <SettingsSystemTemplateFormatsList           />

    if (sub === settingsTabType.systemTemplateTypeTemplates     ) return <SettingsSystemTemplateTypeTemplatesList     />
    if (sub === settingsTabType.systemTemplateTypeTemplateFiles ) return <SettingsSystemTemplateTypeTemplateFilesList />
    // if (sub === settingsTabType.systemTemplateTypes          ) return <SettingsSystemTemplateTypesList             />

    return <SettingsSystemTemplateTypesList />
};

export default SettingsSystemTemplates;
