// prettier-ignore-start
import React from "react";
import {T} from "../../../../../lang/ru";
import {TableSubTitle} from "../TableSubTitle";
import {isNotEmpty} from "../../../../../functions/common";


export const ContactPerson = () => {

    return {
        head : (rowData) => <>
                {T.deliveryContactPerson}
            </>,

        body : (rowData) => {
            const {contact_name='', contact_phone='', contact_email=''} = rowData;

            return <TableSubTitle>
                {[contact_name, contact_phone, contact_email].filter(isNotEmpty).map((value, index) =>
                    <>{((index > 0) && <br />)}{value}</>
                )}
            </TableSubTitle>
        },
    };

};