// prettier-ignore-start
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {Application, getPath} from '../../../../api/methods';
import { PATHS }         from '../../../../constants/RoutesPaths';
import {green, red} from '../../../../constants/Colours';
import { T }             from '../../../../lang/ru';

import Button            from '../../../../components/UI-kit/Buttons/Button';
import SecondOrderButton from '../../../../components/UI-kit/Buttons/SecondOrderButton';
import Text              from '../../../../components/UI-kit/Text';
import { useUserAccessRightChecks } from "../../../../hooks/useUserAccessRightChecks";
import DeliveryFormFields from "./DeliveryFormFields";
import {useUpdateRequest} from "../../../../hooks/useDataRequest";
import {isNotEmpty} from "../../../../functions/common";
import {WaitSpinner} from "../../../../components/UI-kit/Preloader/WaitSpinner";
import BackTitleButton from "../../BackTitleButton";


const OrderDelivery = () => {

    const history    = useHistory();
    const userAccess = useUserAccessRightChecks();

    const { order_id, back_type } = useParams();
    const [isSaved, setIsSaved] = useState(null);

    const isBackToList = back_type === 'list';
    const isAllowed    = userAccess.isAllowAppDataDelivery;

    const delivery = (isAllowed)
        ? DeliveryFormFields(
            order_id,
            userAccess.isAllowAppDataDeliveryEdit,
            true,
            false,
            true,
        )
        : {};

    const updateDelivery = useUpdateRequest({
        request    : Application.updateDelivery,
        requestData: {
            app_id                          : order_id,
            delivery_is_needed              : delivery.data.delivery_is_needed,
            delivery_copies_count           : delivery.data.delivery_copies_count,
            delivery_method_id              : delivery.data.delivery_method_id,
            delivery_town_id                : delivery.data.delivery_town_id,
            delivery_region_id              : delivery.data.delivery_region_id,
            delivery_address_is_from_payer  : delivery.data.delivery_address_is_from_payer,
            delivery_address                : delivery.data.delivery_address,
            delivery_price_calculated       : delivery.data.delivery_price_calculated,
            delivery_print_price_calculated : delivery.data.delivery_print_price_calculated,
            delivery_time_calculated        : delivery.data.delivery_time_calculated,
            delivery_track_number           : delivery.data.delivery_track_number,
        },
        onSuccess  : () => setIsSaved(true),
        onFail     : () => setIsSaved(true),
    });

    useEffect(() => {
        if (isNotEmpty(isSaved) && isSaved)
            setTimeout(() => setIsSaved(false), 2000);
    }, [isSaved]);


    if (! isAllowed) return null; //////////////////////////////////////////////////////////////////////////////////////


    const back = (isBackToList) ? {
        path   : PATHS.orders,
        text   : T.backToRequests
    } : {
        path   : getPath(PATHS.orderInfo, {order_id}),
        text   : T.back
    }

    const isDisabled             = updateDelivery.isUpdating || !delivery.isLoaded;
    const isDisabledSubmitButton = isDisabled || !delivery.isValid();


    return <>
        <BackTitleButton title={T.deliveryTitle} backTo={back.path}/>

        {delivery.form(isDisabled)}

        <br /><br />

        {userAccess.isAllowAppDataDeliveryEdit &&
            <Button
                onClick  = {() => updateDelivery.do()}
                disabled = {isDisabledSubmitButton}
            >
                {updateDelivery.isUpdating ? <WaitSpinner /> : T.save}
            </Button>
        }

        <SecondOrderButton
            onClick  = {() => history.push( back.path )}
            disabled = {isDisabled}
            marginLeft
        >
            {back.text}
        </SecondOrderButton>

        {isSaved && <Text style={{ marginTop: 10 }} color={updateDelivery.hasFails ? red : green}>
            {updateDelivery.hasFails ? 'Ошибка при сохранении.' : 'Изменения успешно сохранены.'}
        </Text>}
    </>

};

export default OrderDelivery;
