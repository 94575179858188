// prettier-ignore-start
import React from "react";

import settingsTabType          from "../tabType";
import SettingsCompanyForm      from "./SettingsCompanyForm";
import SettingsCompanyList      from "./SettingsCompanyList";
import SettingsCompanyTemplates from "./SettingsCompanyTemplates";
import SettingsCompanyForms     from "./SettingsCompanyForms";


const SettingsCompany = ({ sub = settingsTabType.companyList }) => {

    if (sub === settingsTabType.companyNew       ) return <SettingsCompanyForm mode='new'  />
    if (sub === settingsTabType.companyEdit      ) return <SettingsCompanyForm mode='edit' />
    if (sub === settingsTabType.companyTemplates ) return <SettingsCompanyTemplates />
    if (sub === settingsTabType.companyForms     ) return <SettingsCompanyForms />

    return <SettingsCompanyList />;

};

export default SettingsCompany;
