// prettier-ignore-start
import React from "react";

import {PATHS}                    from "../../constants/RoutesPaths";
import {useUserAccessRightChecks} from "../../hooks/useUserAccessRightChecks";

import tabType                    from "./tabType";

import Tabbed                     from "../../components/Tabbed";
import SettingsCompany            from "./Company/SettingsCompany";
import SettingsCategories         from "./Categories/SettingsCategories";
import SettingsLists              from "./Lists/SettingsLists";
import SettingsExecutorGroups     from "./ExecutorGroups/SettingsExecutorGroups";
import SettingsCommon             from "./SettingsCommon";
import SettingsSystem             from "./System/SettingsSystem";


const Settings = ({ tabs = [] }) => {

    const userAccess = useUserAccessRightChecks();

    const tab     = tabs[0] ?? tabType.common;
    const subTabs = tabs.slice(1);


    return <Tabbed
        title = 'Настройки'
        tab   = {tab}
        tabs  = {{
            [tabType.common        ] : { title: 'Системные'              , route: PATHS.settings.common        , component: SettingsCommon        },
            [tabType.lists         ] : { title: 'Списки'                 , route: PATHS.settings.lists         , component: SettingsLists         , componentProps: {tabs: subTabs} },
            [tabType.categories    ] : { title: 'Категории форм'         , route: PATHS.settings.categories    , component: SettingsCategories    },
            [tabType.executorGroups] : { title: 'Группы исполнителей'    , route: PATHS.settings.executorGroups, component: SettingsExecutorGroups, componentProps: {sub: subTabs[0] ?? ''} },
            [tabType.company       ] : { title: 'Организации-исполнители', route: PATHS.settings.company       , component: SettingsCompany       , componentProps: {sub: subTabs[0] ?? ''} },

            ...((userAccess.isAllowSysSettingsCore) ? {
                [tabType.system+'_'    ] : { title: ''                   },
                [tabType.system        ] : { title: <>&#9776;</>         , route: PATHS.settings.system        , component: SettingsSystem        , componentProps: {tabs: subTabs} },
            } : {})
        }}
    />

};

export default Settings;
