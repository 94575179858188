import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import {useProgress} from '../../../../hooks/useProgress';
import {useSaving} from "../../../../hooks/useSaving";
import {useUserAccessRightChecks} from "../../../../hooks/useUserAccessRightChecks";
import { Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import {Message as M} from '../../../../api/methods';
import Button from '../../../../components/UI-kit/Buttons/Button';
import Text from '../../../../components/UI-kit/Text';
import { device } from '../../../../constants/Breakpoints';
import { gray, yellow } from '../../../../constants/Colours';
import { changeBackgroundColorRoot } from '../../../../functions/changeStyles';
import { T } from '../../../../lang/ru';
import { setIsShowPopup } from '../../../../store/popups/actions';
import DeleteMessage from './DeleteMessage';
import Message from './Message';
import SendMessage from '../../../../components/UI-kit/sendMessage';
import {Preloader} from "../../../../components/UI-kit/Preloader/Preloader";
import {useLoadRequest} from "../../../../hooks/useDataRequest";


const OrderChatBlock = styled.div`
  margin-top: 20px;
  @media ${device.tablet} {
    & ${Button} {
      font-size: 13px;
    }
  }
`;

const MessagesBlock = styled.div`
  max-height: 500px;
  overflow: auto;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  background: #f8f8f8;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.03);
`;

const NoMessageText = () => (
  <div
    style={{
      padding: '20px 40px 10px 40px',
    }}
  >
    <Text color={gray}>{T.noMessages}</Text>
  </div>
);

const OrderChat = ({ id }) => {

  const dispatch    = useDispatch();
  const loading     = useProgress();
  const sending     = useSaving("отправка...", "отправлено", "не отправлено" );
  const userAccess  = useUserAccessRightChecks();
  const allMessages = useLoadRequest({
    request      : M.getAllMessage,
    requestData  : { id },
    defaultResult: [],
  });

  const [newMessage   , setNewMessage   ] = useState('');
  const [deleteMessage, setDeleteMessage] = useState();
  const [attachedFile , setAttachedFile ] = useState(null);
  const [isSending    , setIsSending    ] = useState(false);
  const [privateMsg   , setPrivateMsg   ] = useState(false);

  const isEmptyMessage = newMessage.length === 0;

  const isFileAttached = attachedFile != null;


  useEffect(() => {
    changeBackgroundColorRoot('grey');
    return () => changeBackgroundColorRoot('white');
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollMessagesContainerToBottom();
    }, 10);
  }, [allMessages.data]);


  const scrollMessagesContainerToBottom = () => {
    let msgsContainer = document.getElementById('messagesBlock');
    if (msgsContainer) {
      msgsContainer.scrollTop = msgsContainer.scrollHeight;
    }
  };

  const sendMessage = () => {
    startSendingMessage();
    const onLoad = isFileAttached ? loading.onProgress : undefined;
    const data = [
      { id, message: newMessage, file: attachedFile },
      successSendMessage,
      failSendMessage,
      onLoad,
    ];

    if (privateMsg) {
      M.sendPrivate(...data);
    } else {
      M.send(...data);
    }
  };

  const failSendMessage = () => {
    finishSendingMessage(false);
  }

  const successSendMessage = ({ data }) => {
    setAttachedFile(null);
    setNewMessage('');
    allMessages.reload();
    finishSendingMessage(true);
  };

  const startSendingMessage = () => {
    setIsSending(true);
    sending.activate();

    if (isFileAttached) loading.activate();
  };

  const finishSendingMessage = (res = true) => {
    setIsSending(false);
    loading.deactivate();
    sending.deactivate(res);
  };

  const onChangeTextarea = (e) => {
    setNewMessage(e.target.value);
  };

  const onClickDelete = (id) => {
    M.delete({ id }, deleteMessageSuccess);
  };

  const deleteMessageSuccess = ({ data }) => {
    allMessages.reload();
    dispatch(setIsShowPopup('isShowDeleteMessage', false));
  };

  function onClickAddFile(key) {
    document.getElementById(key).click();
  }

  const handleLoadFile = (e, index) => {
    const { files, name } = e.target;
    const [file] = files;
    setAttachedFile(file);
  };

  if (! userAccess.isAllowChat) return null;


  return (
    <OrderChatBlock>
      <MessagesBlock id="messagesBlock">
          {allMessages.data.length
              ? allMessages.data.map((message, index) =>
                  ((! message.private) || userAccess.isAllowChatPrivate) &&
                      <Message
                          message          = {message}
                          index            = {index}
                          setDeleteMessage = {setDeleteMessage}
                      />
              )
              : (allMessages.isLoading)
                  ? <Preloader />
                  : <NoMessageText />
          }
      </MessagesBlock>

      <Row style={{ margin: '20px 0 0 0', alignItems: 'center', gap: '10px', marginHeight: '10px' }}>
          <SendMessage
              value            = {newMessage}
              style            = {{borderColor: isFileAttached && isEmptyMessage ? yellow : null}}
              placeholder      = {'Введите текст сообщения'}
              onChange         = {onChangeTextarea}
              allowPrivateMsg  = {userAccess.isAllowChatPrivate}
              privateMsg       = {privateMsg}
              changePrivateMsg = {() => setPrivateMsg((prev) => !prev)}
          />

          <Button
              private  = {privateMsg}
              disabled = {isEmptyMessage || isSending}
              onClick  = {sendMessage}
          >
              {sending.isActive ? <>&gt;</> : T.send}
          </Button>

          {sending.spinner()}

          {userAccess.isAllowChatAttachFile && (<>
              {(! isFileAttached)
                  ? (
                      <Button
                          disabled = {isSending}
                          private  = {privateMsg}
                          onClick  = {() => onClickAddFile('file')}
                      >
                          Прикрепить файл
                          <input
                              id       = {'file'}
                              name     = {'file'}
                              onChange = {handleLoadFile}
                              type     = "file"
                              style    = {{display: 'none'}}
                          />
                      </Button>
                  ) : (
                      <Button
                          success
                          disabled = {isSending}
                          margin   = {'0 0 0 0'}
                          onClick  = {() => setAttachedFile(null)}
                      >
                        Отменить вложение
                      </Button>
                  )
              }
              {loading.progressBar()}
          </>)}
      </Row>

      <br />

      {userAccess.isAllowChatDelete && <DeleteMessage
        id        = {deleteMessage}
        onClickOk = {onClickDelete}
      />}
    </OrderChatBlock>
  );
};

export default OrderChat;
