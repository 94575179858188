import {useEffect, useState} from "react";


// prettier-ignore-start
export function useDataRequest ({
    request          = (d, s, f) => {},
    requestData   = {},
    defaultResult = {},
    hasResult        = (data) => ( Array.isArray(data) ? data.length > 0 : data != null ),
    doNow    = false, // выполнить сейчас же

    onDo             = (data) => {},
    onSuccess        = (data) => {},
    onFail           = (data) => {},
}) {

    const [state, setState ] = useState({
        data       : defaultResult,
        requesting : false,
        requested  : false,
        failed     : null,
        fails      : [],
    });

    useEffect(() => {
        if (doNow) doSingleRequest();
    }, []);


    const doSingleRequest = (data = {}) => {
        if (state.requested) return;

        doRequest(data);
    }

    const doRequest = (data = {}) => {
        if (state.requesting) return;

        onDo(state.data);

        setState({ ...state, requested: false, requesting: true, data: defaultResult });

        request(
            // data
            {...requestData, ...data},

            // success
            ( data ) => {
                // console.log('SUCCESS', data.data);

                setState({
                    data       : data.data,
                    requesting : false,
                    requested  : true,
                    failed     : false,
                    fails      : []
                });

                onSuccess(data);
            },

            // fail
            ( data ) => {
                // console.log('FAIL', data.data);

                setState({
                    data       : defaultResult,
                    requesting : false,
                    requested  : true,
                    failed     : true,
                    fails      : data.data
                });

                onFail(data);
            }
        );
    }

    const updateValue = (name, value) => {
        if (state.data[name] !== value)
            updateValues({ [name]: value });
    };

    const updateValues = (values) => {
        // setData((prev) => {
        //     return { ...prev, ...values };
        // });
        setState({...state, data: { ...state.data, ...values } });
    };

    const clearFails = () => setState({...state,
        failed : null,
        fails  : [],
    });

    const hasFails   = (state.failed === true); // (fails ?? []).length !== 0;


    return {
        isRequesting    : state.requesting,
        isRequested     : state.requested,

        doSingleRequest : doSingleRequest,
        doRequest       : doRequest,

        data            : state.data,
        setData         : (new_data) => setState({...state, data: new_data}),
        updateValue     : updateValue,
        updateValues    : updateValues,

        hasFails        : hasFails,
        fails           : state.fails,
        clearFails      : clearFails,

        hasResult       : () => hasResult(state.data),
    };
}

// получение основных свойств для наследников ..........................................................................
const baseProps = (props) => { return {
    data         : props.data,
    setData      : props.setData,
    updateValue  : props.updateValue,
    updateValues : props.updateValues,

    hasFails     : props.hasFails,
    fails        : props.fails,
    clearFails   : props.clearFails,

    hasResult    : props.hasResult,
}; }


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// запрос загрузки данных
export function useLoadRequest ( props ) {
    // немедленное выполнение запроса (по-умолчанию)
    props = {...props, doNow: props.doNow ?? true }

    const dataRequest = useDataRequest( props );

    return {
        ...baseProps(dataRequest),
        isLoading : dataRequest.isRequesting,
        isLoaded  : dataRequest.isRequested,

        do        : dataRequest.doSingleRequest,
        load      : dataRequest.doSingleRequest,
        reload    : dataRequest.doRequest,
    };
}

// запрос создания данных
export function useSaveRequest ( props ) {
    const dataRequest = useDataRequest( props );

    return {
        ...baseProps(dataRequest),
        isSaving : dataRequest.isRequesting,
        isSaved  : dataRequest.isRequested,

        save     : dataRequest.doRequest,
        do       : dataRequest.doRequest,
    };
}

// запрос обновления данных
export function useUpdateRequest ( props ) {
    const dataRequest = useDataRequest( props );

    return {
        ...baseProps(dataRequest),
        isUpdating : dataRequest.isRequesting,
        isUpdated  : dataRequest.isRequested,

        update     : dataRequest.doRequest,
        do         : dataRequest.doRequest,
    };
}

// prettier-ignore-end