// prettier-ignore-start
import LIT                        from "../../../../components/Lists/ListItemTypes";
import { Application }            from "../../../../api/methods";
import { ifNotEmpty, isNotEmpty } from "../../../../functions/common";


const ActionAppDataPrice = () => {

    const f_app_id                          = 'id';                              // id заявки
    const f_price                           = 'price';                           // сумма заказа
    const f_prepayment                      = 'prepayment';                      // % предоплаты
    const f_promocode_id                    = 'promocode_id';                    // промо-код

    const f_delivery_is_needed              = 'delivery_is_needed';              // требуется доставка
    const f_delivery_price                  = 'delivery_price';                  // сумма доставки
    const f_delivery_price_calculated       = 'delivery_price_calculated';       // сумма доставки расчетная
    const f_delivery_print_price            = 'delivery_print_price';            // сумма распечатки
    const f_delivery_print_price_calculated = 'delivery_print_price_calculated'; // сумма распечатки расчетная
    const f_delivery_print_one_copy_price   = 'delivery_print_one_copy_price';   // сумма одной копии отчета
    const f_delivery_copies_count           = 'delivery_copies_count';           // количество копий

    const f_delivery_method_name            = 'delivery_method_name';            // название метода доставки
    const f_delivery_method_is_fixed_price  = 'delivery_method_is_fixed_price';  // тип
    const f_delivery_region_name            = 'delivery_region_name';            // название региона
    const f_delivery_town_name              = 'delivery_town_name';              // название города
    const f_delivery_address                = 'delivery_address';                // точный адрес

    const data = (app) => ({
        [f_app_id]               : app[f_app_id              ] ?? null,
        [f_price]                : app[f_price               ] ?? 0,
        [f_prepayment]           : app[f_prepayment          ] ?? 0,
        [f_promocode_id]         : app[f_promocode_id        ] ?? null,

        [f_delivery_price]       : app[f_delivery_price      ] ?? null,
        [f_delivery_print_price] : app[f_delivery_print_price] ?? null,
    });

    const setUserAppPromocodesOptions = (success, fail, data) => Application.listUserAppPromocodesSelect({app_id: data[f_app_id] ?? null, hasUnset: 1}, ({data}) => success(data), fail);

    const formCols = (props) => {
        const region_name = props.app[f_delivery_region_name] ?? null;
        const town_name   = props.app[f_delivery_town_name  ] ?? null;

        return [
            { type: LIT.number_positive    , name: f_price               , title: 'сумма заказа, p.'    , isValid: (val) => isNotEmpty(val) && (val >= 0)                 },
            { type: LIT.percent            , name: f_prepayment          , title: 'аванс'               , isValid: (val) => isNotEmpty(val) && (val >= 0) && (val <= 100) },
            { type: LIT.select             , name: f_promocode_id        , title: 'промокод'            , getOptions: setUserAppPromocodesOptions },

            ...( (!props.app || !(props.app[f_delivery_is_needed] ?? false)) ? [] : [
                {
                    type : LIT.info,
                    title: <>
                        <strong>Доставка: {props.app[f_delivery_method_name] ?? '(метод не задан)'}</strong>
                        {!props.app[f_delivery_method_is_fixed_price] && <p>
                            {(region_name ?? 'регион не задан') + ifNotEmpty(region_name, ' / '+(town_name ?? 'город не задан')) }<br/>
                            {props.app[f_delivery_address] ?? 'адрес не задан'}
                        </p>}
                    </>
                },
                { type: LIT.number_positive, name: f_delivery_price      , title: 'доставка (расчет '+(props.app[f_delivery_price_calculated] ?? '-') + 'p.), p.'  , isValid: (val) => isNotEmpty(val) && (val >= 0)                 },
                { type: LIT.number_positive, name: f_delivery_print_price, title: 'распечатка ('+(props.app[f_delivery_print_one_copy_price] ?? '-')+'р. * '+(props.app[f_delivery_copies_count] ?? '-')+'экз = ' + (props.app[f_delivery_print_price_calculated] ?? '-') + 'p.), p.', isValid: (val) => isNotEmpty(val) && (val >= 0) },
            ])
        ];
    };

    return {
        data     : data,
        formCols : formCols
    }

};

export default ActionAppDataPrice;
