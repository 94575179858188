// prettier-ignore-start
import React from "react";

import {SoftwareProduct} from "../../../api/methods";
import { T }             from "../../../lang/ru";

import List              from "../../../components/Lists/List";
import LIF               from "../../../components/Lists/ListItemFormats";
import LIT               from "../../../components/Lists/ListItemTypes";


const SettingsListsSoftwareProducts = () => {
    return (
        <List
            //title        = {T.editSources}
            popupPrefix  = {'settingsSoftwareProducts'}
            getListData  = {SoftwareProduct.getAll}

            buttons      =  {[
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'is_active'         , title: 'активен'   , format: LIF.check       , type: LIT.check          , canAddAndEdit: true , default: 0   , align: 'center' },
                {name: 'order_id'          , title: 'сортировка', format: LIF.linkEdit    , type: LIT.number_positive, canAddAndEdit: true,                isValid: (val) => val !== "" },
                {name: 'name'              , title: 'название'  , format: LIF.linkEdit    , type: LIT.text           , canAddAndEdit: true, align: 'left', isValid: (val) => val !== "" },
                {name: 'applications_count', title: 'заявок'    , format: LIF.usageCounter},
            ]}

            onNew        = {SoftwareProduct.add}
            onEdit       = {SoftwareProduct.update}
            onDelete     = {SoftwareProduct.delete}

            canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteSoftwareProduct}<b>{item?.name ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsSoftwareProducts;
