import React, {useEffect, useState} from "react";

import {SystemRoles, SystemStatuses} from "../../../api/methods";
import {usePreLoader}                from "../../../hooks/usePreLoader";
import styled                        from "styled-components";
import FloatingSelect                from "../../../components/UI-kit/FloatingSelect";

// prettier-ignore-start
const WrapperRow = styled.div`
  display        : flex;
  width          : 100%;
  align-self     : stretch;
  align-items    : flex-start;
  justify-content: stretch;
  flex-direction : row;
`;
/*
  border         : 1px solid blue;
  margin         : 5px;

  border         : 1px solid red;
  margin         : 5px;
*/

const WrapperColumn = styled.div`
  display        : flex;
  width          : 100%;
  align-self     : stretch;
  align-items    : flex-start;
  justify-content: stretch;
  flex-direction : column;
`;

const WrapperElement = styled.div`
  display        : flex;
  width          : 100%;
  align-self     : stretch;
  align-items    : flex-start;
  justify-content: stretch;
  flex-direction : row;

  padding        : 2px;
`;


const SettingsSystemStructure = () => {

    const preloader = usePreLoader( true );

    const [data       , setData       ] = useState(null);
    const [roleOptions, setRoleOptions] = useState(null);
    const [roleId     , setRoleId     ] = useState('null');

    const isLoaded = (data !== null);

    useEffect(() => {
        loadRoles();
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [roleId]);

    const loadRoles = () => {
        SystemRoles.select({hasUnset: 1}, ({data}) => setRoleOptions(data));
    }

    const loadData = () => {
        preloader.activate();

        SystemStatuses.getStructure( roleId !== 'null' ? {role_id: roleId} : {} , ({data}) => {
            setData(data);
            preloader.deactivate();
        }, () => {
            preloader.deactivate();
        })
    }

    if (preloader.isActive) return preloader.wait();
    if (! isLoaded) {
        return 'не загрузилось...'
    }

    const outActive = (is_active) => (is_active === null
        ? ''
        : (is_active
            ? '+ '
            : '- '
        )
    );

    const outElement = (id, flag, title, data = null) => (
        <WrapperRow key={id} style={{borderTop: '1px solid lightgrey'}}>
            <WrapperColumn style={{width: '25px', alignItems: 'center'}}>
                {flag}
            </WrapperColumn>
            <WrapperColumn>
                <WrapperRow>
                    {title}
                </WrapperRow>
                {data && <WrapperColumn>
                    {data}
                </WrapperColumn>}
            </WrapperColumn>
        </WrapperRow>
    );

    const outStatusElement = ({id, is_active, title, description, roles}) => outElement(
        id,
        outActive(is_active),
        <WrapperElement>{title}</WrapperElement>,
        roles.map( (props) => outRoleStatusElement(props) )
    );

    const outRoleStatusElement = ({id, is_active, title, role, actions, instance_actions, status}) => outElement(
        id,
        outActive(is_active),
        <WrapperColumn>
            <WrapperElement>{role.title} {/*({role.sysname})*/}</WrapperElement>
            {(status?.title !== title) && <WrapperElement style={{paddingLeft:'20px'}}>"{title}"</WrapperElement>}
        </WrapperColumn>,
        [...actions, ...instance_actions]
            .sort((a,b) => a.order_id - b.order_id)
            .map( (props) => outRoleStatusActionElement(props) )
    );

    const outRoleStatusActionElement = ({id, is_active, title, action, set_status}) => outElement(
        id,
        outActive(is_active),
        <WrapperElement>
            <WrapperElement>{title}</WrapperElement>
            <WrapperElement>{action.sysname}</WrapperElement>
            <WrapperElement>{set_status && ('>> '+ set_status.title)}</WrapperElement>
        </WrapperElement>
    );


    return <>
        <br/>
        {roleOptions && <FloatingSelect
            value          = {roleId}
            title          = {'роль' + (! roleOptions ? ' (список пуст)' : '')}
            name           = {'role'}
            onChange       = {(e) => setRoleId(e.target.value)}
            disabled       = {! roleOptions}
            options        = {roleOptions}
        />}
        <br/>
        {data.map( (props) => outStatusElement(props) )}
    </>;
//
};

export default SettingsSystemStructure;
