// prettier-ignore-start
import React from "react";
import {T} from "../../../../../lang/ru";
import {TableSubTitle} from "../TableSubTitle";
import {noSpaceStyle} from "../OrdersListTableRowData";
import {useUserAccessRightChecks} from "../../../../../hooks/useUserAccessRightChecks";
import {Empty} from "../../../../../components/Empty";
import {isEmpty} from "../../../../../functions/common";


export const Comments = () => {

    const userAccess = useUserAccessRightChecks();

/*
    <TableSubTitle style={{ minWidth: 52 }}>Общие: </TableSubTitle>
    <OrderTableInput
        name        = "remark"
        value       = {application.remark ?? ''}
        onChange    = {handleChangeInput}
        onBlur      = {updateInput}
        placeholder = {T.remarksCommon}
    />
    <br style={noSpaceStyle} />
    <TableSubTitle style={{ minWidth: 52 }}>Админ: </TableSubTitle>
    <OrderTableInput
        name        = "note"
        value       = {application.note ?? ''}
        onChange    = {handleChangeInput}
        onBlur      = {updateInput}
        placeholder = {T.noteAdmin}
    />
*/

    const showTitles = (userAccess.isAllowAppListCommentsPrivate)

    const outComment = (title, comment) => ( ! isEmpty(comment) && (
            (showTitles)
                ? <TableSubTitle style={{ minWidth: 52 }}>{title}: <i>{comment}</i></TableSubTitle>
                : comment
        ) );

    return (userAccess.isAllowAppListComments) && {
        head : (rowData) => T.remarks,

        body : (rowData) => {
            const {remark='', note=''} = rowData;

            return ( (isEmpty(remark) && isEmpty(note))
                ? <Empty text='нет' />
                : <>
                    {outComment('Общие', remark)}
                    {( (! isEmpty(remark)) && userAccess.isAllowAppListCommentsPrivate) && <br style={noSpaceStyle} />}
                    {userAccess.isAllowAppListCommentsPrivate && outComment('Админа', note)}
                </>
            );
        },
        headProps: (rowData) => ({
            className: "fixed-row",
            style: {minWidth: '200px'}
        }),
    };

};