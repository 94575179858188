// prettier-ignore-start
import React, { useEffect } from "react";

import { useHistory }                 from "react-router-dom";
//import { useDispatch }              from "react-redux";

import { Application, Form, getPath } from "../../../api/methods";
import { T }                          from "../../../lang/ru";
//import { setActiveStep }            from "store/activeStep/actions";
import {useLoadRequest, useSaveRequest} from "../../../hooks/useDataRequest";

import { PATHS }                      from "../../../constants/RoutesPaths";
import InputsGrid                     from "../../../components/UI-kit/InputsGrid";
import { Preloader }                  from "../../../components/UI-kit/Preloader/Preloader";
import { FullscreenPreloader }        from "../../../components/UI-kit/Preloader/FullscreenPreloader";
import ChoiceCard                     from "./OrderNewSt2SetCategory/ChoiceCard";
import BackTitleButton                from "../BackTitleButton";


const OrderNewSt3SetForm = (props) => {

    const { category_id, payer_id } = props.match.params;

    const history  = useHistory();
//    const dispatch = useDispatch();

    const formsList = useLoadRequest({
        request       : Form.list,
        requestData   : { category_id },
        defaultResult : [],
    });

    const addOrder = useSaveRequest({
        request       : Application.add,
        requestData   : { payer_id },
        defaultResult : [],
    });

    useEffect(() => {
//        dispatch(setActiveStep(2));
    }, []);

    useEffect(() => {
        if (addOrder.isSaved) history.push(
            getPath( PATHS.orderNewSt4Form, {payer_id, category_id, form_id: addOrder.data.object_type, application_id: addOrder.data.id})
        );
    }, [addOrder.isSaved]);


    return <>
        <BackTitleButton title={T.chooseForm} backTo={getPath( PATHS.orderNewSt2SetCategory, {payer_id} )}/>

        {formsList.isLoading && <Preloader />}
        {formsList.isLoaded  && <InputsGrid
            maxWidth="1000px"
            columns="1fr 1fr 1fr"
            gap="0 20px"
            margin="20px 0 0"
        >
            {formsList.data.map( ({ name, id, is_published }) => (is_published === 1) &&
                <ChoiceCard disabled={addOrder.isSaving} selected={addOrder.data?.id === id} key={id} title={name} onClick={() => addOrder.save({form_id: id})}/>
            )}
        </InputsGrid>}
        {addOrder.isSaving && <FullscreenPreloader />}
    </>

};

export default OrderNewSt3SetForm;
