import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import { gray, ultraLightgray, lightgray } from "../../../constants/Colours";
import Text from "../../UI-kit/Text";

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: ${ultraLightgray};
  padding: 16px 0 18px 0;
  a {
    text-decoration: none !important;
    ${Text} {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0;
      color: ${gray} !important;
      &:hover {
        color: #6f6f6f !important;
      }
    }
  }
  @media ${device.tablet} {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    background-color: #f0f0f0;
    z-index: 1000;
  }
`;

export default Header;
