// prettier-ignore-start
import React from "react";

import {Delivery, getPath} from "../../../api/methods";
import { T }        from "../../../lang/ru";

import List         from "../../../components/Lists/List";
import LIF          from "../../../components/Lists/ListItemFormats";
import LIT          from "../../../components/Lists/ListItemTypes";
import {PATHS}      from "../../../constants/RoutesPaths";
import {useHistory} from "react-router-dom";
import {numbToLocaleCurrency} from "../../../functions/common";


const SettingsListsDeliveryMethods = () => {

    const history       = useHistory();

    const priceFormat  = (id, item, itemProps) => item['is_fixed_price']
        ? <>{numbToLocaleCurrency(item['price'] * item['price_multiplier'])}&nbsp;({item['time']})</>
        : 'зависит от региона';

    const gotoDeliveryRegions = (method_id) => history.push(getPath(PATHS.settings.listsDeliveryRegions,{method_id}));

    return (
        <List
            popupPrefix  = {'delivery_methods'}
            getListData  = {Delivery.list}

            buttons      =  {[
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'is_active'         , title: 'активен'         , format: LIF.check       , type: LIT.check , canAddAndEdit: true, align: 'center', default: 1 },
                {name: 'name'              , title: 'название'        , format: LIF.linkEdit    , type: LIT.text  , canAddAndEdit: true, align: 'left', isValid: (val) => val !== ""},
                {name: 'is_fixed_price'    , title: 'цена фиксирована', format: LIF.hidden      , type: LIT.check , canAddAndEdit: true },
                {name: 'price'             , title: 'цена'            , format: LIF.text        , type: LIT.number, canAddAndEdit: true, align: 'left', customFormatHandler: priceFormat, isValid: (val) => val !== ""},
                {name: 'price_multiplier'  , title: 'множитель цены'  , format: LIF.hidden      , type: LIT.number, canAddAndEdit: true },
                {name: 'time'              , title: 'срок'            , format: LIF.hidden      , type: LIT.text  , canAddAndEdit: true },
                {name: 'applications_count', title: 'заявок'          , format: LIF.usageCounter},
                {name: 'regions_count'     , title: 'регионов'        , format: LIF.usageCounter},
            ]}

            actions      = {[
                {title: 'регионы'          , onClick: gotoDeliveryRegions, canDo: (item) => !(item?.is_fixed_price ?? false) },
            ]}

            onNew        = {Delivery.add   }
            onEdit       = {Delivery.update}
            onDelete     = {Delivery.delete}

            canDelete    = {(item) => (item['applications_count'] === 0) && (item['regions_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteDeliveryMethod}<b>{item?.name ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsDeliveryMethods;
