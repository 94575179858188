import styled from "styled-components";
import {accent, white} from "../../../constants/Colours";

// prettier-ignore-start

export const WaitLoader = styled.div`
    padding        : 0px !important;
    border         : 0px !important;
    margin-left    : 0 !important;
    margin-right   : 0 !important;
    text-align     : center !important;
    vertical-align : middle;
    display        : block;
    width          : 16px;
    height         : 16px;
    align-items    : center;
    justify-content: center;
  
    :after {
        position     : absolute;
        margin-left  : auto;
        margin-right : auto;
        text-align   : center !important;
        content      : " ";
        display      : block;
        width        : 12px;
        height       : 12px;
        border-radius: 50%;
        border       : 2px solid ${accent};
        //border-color : ${accent} transparent ${accent} transparent;
        border-color : ${accent} transparent ${white} transparent;
        animation    : lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0%   { transform: rotate(0deg  ); }
        100% { transform: rotate(360deg); }
    }
`;
