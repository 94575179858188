import React from "react";

import InputRadio from "../UI-kit/InputRadio";
import { Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import {gray} from "../../constants/Colours";

type wrapperProps = {
    margin?   : string,
    flexDirect: boolean,
    disabled? : boolean
}

const Wrapper = styled(Row)`
    display       : flex;
    ${(props: wrapperProps) => props.margin ? "margin: " + props.margin : null};
    padding-left  : 15px;
    flex-direction: ${(props: wrapperProps) => props.flexDirect ? "column" : "row"};
    ${(props: wrapperProps) => props.disabled ? `opacity: 0.7` : null};
`

type RadioType = {
    radios     : {
        value  : any;
        name   : string
    }[];
    nameRadio  : string;
    setValue   : any;
    radioValue : string;
    flexDirect?: boolean;
    margin?    : string;
    tabIndex?  : number;
    disabled?  : boolean
};

const Radio: React.FC<RadioType> = ({
    radios = [],
    nameRadio = "",
    setValue,
    radioValue = "",
    flexDirect = false,
    margin = "15px -15px 20px",
    tabIndex = 0,
    disabled = false
}) => {
    const changeRadio: (e: any) => void = (e) => {
        if (! disabled)
            setValue(e.target.value);
    };

    return (
        <Wrapper
            margin     = {margin}
            flexDirect = {flexDirect}
            disabled   = {disabled}
        >
            {radios.map( ({ value, name }) =>
                <InputRadio
                    disabled    = {disabled}
                    id          = {nameRadio + value}
                    value       = {value}
                    name        = {nameRadio}
                    checked     = {radioValue == value}
                    placeholder = {name}
                    onClick     = {changeRadio}
                    key         = {value}
                    tabIndex    = {tabIndex == undefined ? 0 : tabIndex}
                />
            )}
        </Wrapper>
    );
};

export default Radio;
