// prettier-ignore-start
import React from "react";

import { useParams }          from "react-router-dom";

import TemplatesList          from "../TemplatesList";
import { Category, Template } from "../../../api/methods";
import { useLoadRequest }     from "../../../hooks/useDataRequest";
import { PATHS }              from "../../../constants/RoutesPaths";
import { ifNotEmptyPostfix }  from "../../../functions/common";


const SettingsCategoryTemplatesList = () => {

    const { category_id } = useParams();

    const category = useLoadRequest({
        request     : Category.getById,
        requestData : {id: category_id},
    })

    return <TemplatesList
        title       = { ifNotEmptyPostfix(category?.data.name, '. ', '')+ 'Базовые шаблоны' }
        backTo      = { PATHS.settings.categories }
        popupPrefix = { 'categoryTemplatesList' }
        getListData = { (d, s, f) => Template.listCategory  ({       category_id}, s, f) }
        onNew       = { (d, s, f) => Template.assign2Category({...d, category_id}, s, f) }
    />

};

export default SettingsCategoryTemplatesList;
