import React from 'react';
import { accent } from '../../constants/Colours';
export default function UnlockedSvg() {
  return (
    <svg
      fill={accent}
      viewBox="270.81 306.054 26 26"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '100%', height: 'auto' }}
    >
      <path d="M 277.81 306.054 C 275.599 306.054 273.689 306.972 272.498 308.461 C 271.306 309.949 270.81 311.879 270.81 313.961 L 270.81 317.054 L 273.81 317.054 L 273.81 313.961 C 273.81 312.383 274.201 311.14 274.841 310.336 C 275.482 309.531 276.353 309.054 277.81 309.054 C 279.271 309.054 280.138 309.504 280.779 310.304 C 281.419 311.105 281.81 312.363 281.81 313.961 L 281.81 315.054 L 284.81 315.054 L 284.81 313.961 C 284.81 311.867 284.283 309.918 283.091 308.429 C 281.9 306.941 280.017 306.054 277.81 306.054 Z M 279.81 316.054 C 278.154 316.054 276.81 317.398 276.81 319.054 L 276.81 329.054 C 276.81 330.711 278.154 332.054 279.81 332.054 L 293.81 332.054 C 295.466 332.054 296.81 330.711 296.81 329.054 L 296.81 319.054 C 296.81 317.398 295.466 316.054 293.81 316.054 L 279.81 316.054 Z M 286.81 321.054 C 287.915 321.054 288.81 321.949 288.81 323.054 C 288.81 323.793 288.408 324.426 287.81 324.773 L 287.81 327.054 C 287.81 327.605 287.361 328.054 286.81 328.054 C 286.259 328.054 285.81 327.605 285.81 327.054 L 285.81 324.773 C 285.212 324.426 284.81 323.793 284.81 323.054 C 284.81 321.949 285.705 321.054 286.81 321.054 Z" />
    </svg>
  );
}
