// prettier-ignore-start
import React from "react";

import {
    useDispatch,
    useSelector
}                           from "react-redux";
import styled               from "styled-components";
import { useParams }        from "react-router-dom";

import { Chain, Form }      from "../../../api/methods";
import { getUserAnwer }     from "../../../store/userAnswers/selectors";
import { getPreviewMode }   from "../../../store/previewMode/selectors";
import { setElements }      from "../../../store/elements/actions";

import Subtitle2            from "../../../components/UI-kit/Subtitle2";
import MainElement          from "./Elements/MainElement";
import PullElementsChildren from "./PullElementsChildren";
import CreateButtonsBlock   from "./CreateButtonsBlock/CreateButtonsBlock";

const ChildPullWrapper = styled.div`
    margin-left: ${(props) => (props.isPreviewMode ? "70px" : "0")};
`;

const ElementWrapper = styled.div`
    // background    : #eee;
    // border        : 1px solid #eee;
    // border-radius : 10px;
    // padding       : 1px 50px;
    // margin        : 30px 0;
`;


const GetContent = ({
    element,
    index,
    elements,
    systemNames,
    PullElements,
}) => {

    const dispatch    = useDispatch();
    const { form_id } = useParams(); // document.location.pathname.split("/").pop();

    const isLast  = index === (elements.length - 1);
    const isFirst = index === 0;

    const { type, id, childrens } = element;

    const isPreviewMode = useSelector( getPreviewMode   );
    const userAnswer    = useSelector( getUserAnwer(id) );

    const groupTitle            = `Добавить вопросы к группе «${element.name}»`;
    const superComplexListTitle = `Добавить вопросы к супер-сложному списку «${element.name}»`;

    const getElementIdByIndex = (index) => elements[index].id;

    const swapPrevious = (currentId, currentIndex, isFirst) => {
        if (isFirst) return;

        swapElements(currentId, getElementIdByIndex(currentIndex - 1));
    };

    const swapNext = (currentId, currentIndex, isLast) => {
        if (isLast) return;

        swapElements(currentId, getElementIdByIndex(currentIndex + 1));
    };

    const successLoadForm = ({ data }) => dispatch(setElements(data));

    const loadForm = (id) => Form.getById({ id }, successLoadForm);

    const swapElements = (currentId, neighborId) =>
        Form.swap({ id1: currentId, id2: neighborId, form: form_id }, () => loadForm(form_id) );

    const filteredStruct = (element) =>
        element.struct.filter( (v, i, a) =>
            a.findIndex(
                (t) => t.id === v.id
            ) === i
        );

    const addElement = (type_id, parent_id, condition, order_index) => {
        Chain.add(
            {
                type       : type_id,
                form_id    ,
                parent_id  ,
                condition  ,
                tip        : "",
                order_index,
            },
            addElementSuccess
        );
    };

    const addElementSuccess = () => loadForm(form_id);

    const getCountOfChildrenByStructId = (element, structId) => {
        const { childrens = [] } = element;
        const child = childrens.filter( (child) => child.id === structId )[0];

        const { elems } = child;

        if (elems.length === 0) return 0;

        const { order_index } = elems.pop();

        return order_index + 1;
    };

    const isUserAnswerAcceptString = (userAnswer = {}, string) => {
        let isContain = false;

        const isAnswerUndefined  = typeof userAnswer?.answer == "undefined";
        const isUserAnswerString = typeof userAnswer?.answer == "string";

        if (isAnswerUndefined ) return false;
        if (isUserAnswerString) return userAnswer.answer?.includes(string);

        Object.keys(userAnswer?.answer).map( (key) => {
            if (userAnswer?.answer[key] === true && key === string) isContain = true;
        });

        return isContain;
    };

    const isShowChild = (element, child) => {
        const { id, type } = element;
        const nameForShowing = child.name;

        if (! isPreviewMode         ) return true;
        if (type === 5 || type === 9) return true;

        return isUserAnswerAcceptString(userAnswer, nameForShowing);
    };

    const renderChildrenOfElement = (element, structId, structName) => {
        const { childrens } = element;
        const filteredChildren = childrens.filter( (child) => child.id === structId );

        return filteredChildren.map( (child) => {
            const isContainChildren = child.elems?.length !== 0;
            const isShow            = isShowChild(element, child) || !isPreviewMode;

            if (! isContainChildren) return null;
            if (isShow             ) return <PullElements elements={child.elems} />;
        });
    };


    return (
        <ElementWrapper key={id}>
            <MainElement
                isFirst      = {isFirst}
                isLast       = {isLast}
                systemNames  = {systemNames}
                swapNext     = {() => swapNext    (id, index, isLast )}
                swapPrevious = {() => swapPrevious(id, index, isFirst)}
                element      = {element}
            />

            {(isPreviewMode || type === 8)
                ? null
                : filteredStruct(element).map( ({ name, id }) =>
                    <ChildPullWrapper key={id} isPreviewMode={1}>
                        <Subtitle2 style={{ margin: 0 }}>
                            {(type === 5)
                                ? groupTitle
                                : (type === 9)
                                    ? superComplexListTitle
                                    : `Добавить вопросы к варианту ответа «${
                                        element.name == null ? " – " : element.name
                                      }» на вопрос «${name}»`}
                        </Subtitle2>

                        <CreateButtonsBlock
                            addElement = {(type_id) =>
                                addElement(
                                    type_id,
                                    element.id,
                                    id,
                                    getCountOfChildrenByStructId(element, id)
                                )
                            }
                        />
                        {renderChildrenOfElement(element, id, name)}
                    </ChildPullWrapper>
                )
            }

          {isPreviewMode
            ? childrens.map( (child, index) =>
                <PullElementsChildren
                    key              = {child.id}
                    child            = {child}
                    index            = {index}
                    element          = {element}
                    isShowChild      = {isShowChild}
                    PullElements     = {PullElements}
                    ChildPullWrapper = {ChildPullWrapper}
                    elements         = {elements}
                />
            )
            : null}
        </ElementWrapper>
    );
};




export default GetContent;
