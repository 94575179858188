import styled from "styled-components";
import { device } from "../../constants/Breakpoints";
import { black } from "../../constants/Colours";
import exit from "../../assets/svg/exit.svg";

type Props = {
  color?: string;
  withoutMargin?: boolean;
  fontWeight?: string;
  rightMargin?: boolean;
};
const Text = styled.p`
  font-size: 16px;
  font-weight: ${(props: Props) =>
    props.fontWeight ? `${props.fontWeight}` : "normal"};
  line-height: 160%;
  max-width: 800px;
  color: ${(props: Props) => (props.color ? `${props.color}` : black)};
  margin-top: 0px;
  margin-bottom: ${(props: Props) => (props.withoutMargin ? "0" : "16px")};
  margin-right: ${(props: Props) => (props.rightMargin ? "20px" : 0)};
  @media ${device.tablet} {
    font-size: 14px;
    &.header-exit {
      font-size: 0;
      &::after {
        content: "";
        background-image: url(${exit});
        display: block;
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export default Text;
