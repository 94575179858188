import React from "react";
import styled from "styled-components";
import { lightgray, gray, black } from "../../constants/Colours";
import {Input} from "./FloatingInput";


const Option = styled.option`
  color: ${black};
  &:disabled {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 337px;
`;

const Label = styled.label`
  :before {
    content: "";
    display: "block";
    position: absolute;
    right: 20px;
    top: 50%;
    width: 7px;
    height: 7px;
    border-top: 1px solid ${gray};
    border-right: 1px solid ${gray};
    transform: translateY(-50%) rotate(135deg);
    pointer-events: none;
  }
`;

type PlaceHolderLabelProps = {
  isOnTop: boolean;
};


const PlaceHolderLabel = styled.label`
  font-weight   : normal;
  position      : absolute;
  pointer-events: none;
  left          : 20px;

  top           : ${(props: PlaceHolderLabelProps) => (props.isOnTop ? '12px' : '25px')};
  font-size     : ${(props: PlaceHolderLabelProps) => (props.isOnTop ? '12px' : '18px')};
  color         : ${gray};

  transition    : 0.2s ease all;

  ${Input}:focus ~ & {
    top       : 11px;
    font-size : 12px;
    width     : 230px;
    background: white;
  }
`;



type SelectProps = {
  value: number;
};

const Select = styled.select`
  width: 100%;
  height: 54px;
  border: 1px solid ${lightgray};
  color: ${(props: SelectProps) => (props.value === -1 ? gray : black)};
  font-size: ${(props: SelectProps) => (props.value === -1 ? "18px" : "16px")};
  padding: 15px 25px 15px 20px;
  margin: 10px 0;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

type FloatingSelectProps = {
  value: number;
  name: string;
  onChange: (e: any) => void;
  options: { id: number; name: string }[];
  title: string;
  style?: any;
  containerStyle?: any;
  disabled?: boolean;
  placeholder: string;
};

const FloatingSelect: React.FC<FloatingSelectProps> = ({
  value = -1,
  name = "",
  onChange,
  options = [],
  title = "",
  style = {},
  containerStyle = {},
  disabled = false,
  placeholder = '',
}) => (
  <Wrapper style={containerStyle}>
    <Select style={style} value={value} onChange={onChange} name={name} disabled={disabled ?? false}>
      <Option selected disabled value={-1}>
        {placeholder}
      </Option>
      {options.map(({ id, name }) => (
          <Option key={id} value={id} selected={id === value}>
            {name}
          </Option>
      ))}
    </Select>
    <Label />
    <PlaceHolderLabel isOnTop={true}>{title}</PlaceHolderLabel>
  </Wrapper>
);

export default FloatingSelect;
