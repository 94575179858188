// prettier-ignore-start
import ActionCommentChat from "./Base/ActionCommentChat";
import ActionMessageForm from "./Base/Forms/ActionMessageForm";
import ActionFormType    from "./ActionFormType";


const ActionMessageFormCommentChat = () => {

    const common = ActionCommentChat();


    return {
        type    : ActionFormType.popupForm,

        data     : common.data,
        formCols : common.formCols,
        form     : (props) => ActionMessageForm().form(props, common.formCols),
    }

};

export default ActionMessageFormCommentChat;
