// prettier-ignore-start
import React, {useEffect, useState} from "react";

import {useHistory, useParams} from "react-router-dom";
import {getPath, User}         from "../../../../api/methods";
import {PATHS}                 from "../../../../constants/RoutesPaths";
import LIF                     from "../../../../components/Lists/ListItemFormats";
import LIT                     from "../../../../components/Lists/ListItemTypes";
import ListProps               from "../../../../components/Lists/ListProps";


const UserCategoriesList = () => {

    const history = useHistory();
    const { user_id } = useParams();

    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        loadUserInfo();
    }, []);


    const loadUserInfo = () => User.getById({id: user_id}, ({data}) => setUserInfo(data));

    const userFIO = () => (userInfo.surname) ? [userInfo.surname, userInfo.name, userInfo.middlename].join(' ') : '';


    return (
        <ListProps
            backLink        = { PATHS.users }

            title           = {<>Эксперт {userFIO()}<br />&nbsp;&raquo; Категории</>}
            popupPrefix     = {'userCategoriesList'}
            isActiveColName = 'is_active'

            cols            = {[
                {name: 'name', title: 'категория', format: LIF.linkEdit, type: LIT.text, canAddAndEdit: false, align: 'left' },
            ]}

            getNotAssigned  = {(setOptions                    ) => User.getNotAssignedCategories({user_id                                               }, ({data}) => setOptions(data) )}
            getListData     = {(data           , success, fail) => User.getAssignedCategories   ({user_id                                               }, success, fail)}
            onNew           = {({id, is_active}, success, fail) => User.assignCategory          ({user_id, is_active: is_active ? 1 : 0, category_id: id}, success, fail)}
            onEdit          = {({id, is_active}, success, fail) => User.updateAssignedCategory  ({user_id, is_active: is_active ? 1 : 0, category_id: id}, success, fail)}
            onDelete        = {({id           }, success, fail) => User.deleteAssignedCategory  ({user_id, category_id: id                              }, success, fail)}

            newAll          = { (success) => User.assignAllCategories    ({user_id}, success) }
            deleteAll       = { (success) => User.deleteAllCategories    ({user_id}, success) }
            activateAll     = { (success) => User.activateAllCategories  ({user_id}, success) }
            deactivateAll   = { (success) => User.deactivateAllCategories({user_id}, success) }

            canDelete       = {() => true}
            textOnDelete    = {(item) => <>Удалить у пользователя категорию<b>{item?.name ? <>:<br />{item.name}</>: ''}</b>?</>}

            actions = {[
                {title: 'формы', onClick: (category_id) => history.push(getPath(PATHS.userCategoryForms, {user_id, category_id})), canDo: () => true },
            ]}
        />
    );
};

export default UserCategoriesList;