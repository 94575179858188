import React from "react";
import styled from "styled-components";
import { gray } from "../../../constants/Colours";
import Text from "../../UI-kit/Text";

const LinksContainer = styled.div`
  display: flex;
  & * {
    display: flex;
    align-items: center;
    color: ${gray} !important;
    text-decoration: none;
    cursor: pointer;
    margin: 0;
    margin-right: 30px;
    &:hover {
      color: #6f6f6f !important;
    }
  }
`;

type LinksProps = {
  links: {
    name: string;
    onClick: () => void;
  }[]
};

const Links: React.FC<LinksProps> = ({ links}) => (
  <LinksContainer>
    {links.map(({ name, onClick }) => (
      <a key={name} onClick={onClick}>
        <Text>{name}</Text>
      </a>
    ))}
  </LinksContainer>
);

export default Links;
