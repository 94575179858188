// prettier-ignore-start
import React from "react";

import { useParams }                   from "react-router-dom";
import CompanyTemplatesList            from "./CompanyTemplatesList";
import { getPath, Category, Template } from "../../../api/methods";
import { useLoadRequest }              from "../../../hooks/useDataRequest";
import { PATHS }                       from "../../../constants/RoutesPaths";
import { ifNotEmptyPostfix }           from "../../../functions/common";


const SettingsCompanyCategoryTemplates = () => {

    const { company_id, category_id } = useParams();

    const category = useLoadRequest({
        request    : Category.getById,
        requestData: {id: category_id},
    })

    return <CompanyTemplatesList
        popupPrefix  = { 'companyCategoryTemplatesList' }
        backTo       = { getPath(PATHS.settings.companyForms, {company_id}) }
        title        = { ifNotEmptyPostfix(category.data?.name, '. ', '') + 'Шаблоны' }

        getListData  = { (d, s, f) => Template.listCompanyCategory   ({...d, category_id}, s, f) }
        onNew        = { (d, s, f) => Template.assign2CompanyCategory({...d, category_id}, s, f) }
    />

};

export default SettingsCompanyCategoryTemplates;
