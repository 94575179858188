// prettier-ignore-start
import LIT             from "../../../../components/Lists/ListItemTypes";
import { Application } from "../../../../api/methods";


const ActionAppExecutorRating = () => {

    // const f_app_id = 'app_id';
    const f_rating = 'rating';

    const data = (app, field = null) => ({
        // [f_app_id] : app['id'] ?? 0,
    });

    const requestData = (app, setData) => Application.rating({ app_id: app['id'] ?? 0, sysname: 'executors' }, ({data}) => setData( (prev) => {
        let formData = {};

        data.map( ({ id, values }) => {
            const value_default = values[0]?.id ?? null;
            const value         = values.find( ({ user_values_count }) => (user_values_count > 0) );

            formData = { ...formData, [id]: value?.id ?? value_default };
        } );

        return { ...prev, ...formData, [f_rating]: data, updateCols: true }
    }) );

    const formCols = (props) => (props?.data?.rating)
        ? props.data.rating.map( ({ id, title, values }) => ({
            name      : id,
            title     : title,
            type      : LIT.select,
            getOptions: (success, fail, data) => success( values.map( ({ id, title, value }) => ({ id, name: '+' + (((value<10) ? '0' : '') + value) + ' | ' + title }) ) )
        }) )
        : [{
            type      : LIT.loader
        }];

    const prepareDataBeforeSave = (data) => {
        let result = {...data};

        delete result[f_rating];

        return result;
    }


    return {
        data       : data,
        requestData: requestData,
        formCols   : formCols,
        onSave     : prepareDataBeforeSave,
    }

};

export default ActionAppExecutorRating;
