// prettier-ignore-start
import React from 'react';

import { useParams } from 'react-router-dom';
import { getPath }   from "../../../api/methods";
import { PATHS }     from "../../../constants/RoutesPaths";
import Questionnaire from "../../Forms/Questionnaire/Questionnaire";


type paramsType = {
    payer_id       : string,
    category_id    : string,
    form_id        : string,
    application_id : string
}

type propsType = {
    match: {
        params: paramsType;
    };
};


const OrderNewSt4Form: React.FC<propsType> = (props) => {
    const { payer_id, category_id, form_id, application_id }: paramsType = useParams();

    return <Questionnaire
        isPreview      = {false}
        autosave       = {true}

        form_id        = {form_id}
        application_id = {application_id}

        nextTo         = {getPath(PATHS.orderNewSt5Delivery, { payer_id, category_id, form_id, application_id })}

        backTitle      = {'назад'}
        backTo         = {getPath(PATHS.orderNewSt3SetForm, { payer_id, category_id })}
    />

};

export default OrderNewSt4Form;
