import React from "react";
import { Col, Row } from "styled-bootstrap-grid";

import attached_file from "../../../../assets/svg/attached_file.svg";
import checkbox from "../../../../assets/svg/checkbox.svg";
import group from "../../../../assets/svg/group.svg";
import important from "../../../../assets/svg/important.svg";
import infinity_list from "../../../../assets/svg/infinity_list.svg";
import input from "../../../../assets/svg/input.svg";
import radio from "../../../../assets/svg/radio.svg";
import repeated_list from "../../../../assets/svg/repeated_list.svg";
import superComplexList from "../../../../assets/svg/super-complext-element.svg";
import styled from "styled-components";
import { gray } from "../../../../constants/Colours";

const config = [
  {
    type_id: 1,
    name: "Поле для ввода",
    icon: input,
  },
  {
    type_id: 2,
    name: "Один из списка",
    icon: radio,
  },
  {
    type_id: 3,
    name: "Несколько из списка",
    icon: checkbox,
  },
  {
    type_id: 4,
    name: "Вопрос с прикреплением файла",
    icon: attached_file,
  },
  {
    type_id: 5,
    name: "Добавить группу вопросов",
    icon: group,
  },
  {
    type_id: 6,
    name: "Добавить важную информацию",
    icon: important,
  },
  {
    type_id: 7,
    name: "Поле для ввода списка",
    icon: infinity_list,
  },
  {
    type_id: 8,
    name: "Список с повторяющимися вопросами",
    icon: repeated_list,
  },
  {
    type_id: 9,
    name: "Супер-вложенный список",
    icon: superComplexList,
  },
];

const CreateButtonIcon = styled.img`
  border: 1px solid ${gray};
  border-radius: 4px;
  transition: all 400ms linear;
  cursor: pointer;
  height: 50px;
  width: 50px;
  &:hover {
    border-radius: 20px;
  }
`;

const CreateButtonsBlock = ({ addElement }) => (
  <Row>
    {config.map(({ type_id, name, icon }) => (
      <Col
        key={type_id}
        col={"auto"}
        style={{
          padding: 15,
          paddingBottom: 0,
          paddingRight: 0,
          marginBottom: 0,
        }}
      >
        <CreateButtonIcon
          src={icon}
          style={{ border: "1px solid #eee" }}
          onClick={() => addElement(type_id)}
        />
        {/* <Button onClick={() => addElement(type_id)}>{name}</Button> */}
      </Col>
    ))}
  </Row>
);

export default CreateButtonsBlock;
