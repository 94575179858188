// prettier-ignore-start
import React from "react";

import { useHistory } from "react-router-dom";

import { getPath, TemplateType } from "../../../../api/methods";

import { isNotEmpty }   from "../../../../functions/common";
import List             from "../../../../components/Lists/List";
import LIF              from "../../../../components/Lists/ListItemFormats";
import LIT              from "../../../../components/Lists/ListItemTypes";
import { PATHS }        from "../../../../constants/RoutesPaths";
import {isValidJsonCheck} from "../../../../functions/validators";


const SettingsSystemTemplateTypesList = () => {

    const history = useHistory();

    const gotoTemplateGenerators = () => history.push( PATHS.settings.systemTemplateGenerators );
    const gotoTemplateFormats    = () => history.push( PATHS.settings.systemTemplateFormats    );

    const gotoTypeTemplates = (id) => history.push( getPath( PATHS.settings.systemTemplateTypeTemplates, { template_type_id: id } ) );


    return <List
        title        = 'Типы шаблонов'
        popupPrefix  = {'systemTemplateTypes'}
        getListData  = {TemplateType.list}

        buttons      =  {[
            {type: 'new'},
            {type: 'group', buttons: [
                    {title: 'форматы'   , onClick: gotoTemplateFormats    },
                    {title: 'генераторы', onClick: gotoTemplateGenerators },
                ]},
        ]}

        cols         = {[
            {name: 'id'             , title: 'id'       , format: LIF.linkEdit, type: LIT.number  , canAdd: true, canEdit: false},
            {name: 'title'          , title: 'название' , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: ''  , align: 'left', isValid: (val) => isNotEmpty(val) },
            {name: 'name'           , title: 'имя'      , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: ''  , align: 'left', isValid: (val) => isNotEmpty(val) },
            {name: 'props'          , title: 'параметры', format: LIF.hidden  , type: LIT.textarea, canAddAndEdit: true , default: '{}',                isValid: (val) => isValidJsonCheck(val) },
            {name: 'description'    , title: 'описание' , format: LIF.linkEdit, type: LIT.textarea, canAddAndEdit: true , default: ''  , align: 'left'},

            {name: 'templates_count', title: 'шаблонов', format: LIF.usageCounter},
        ]}

        actions = {[
            {title: 'шаблоны', onClick: gotoTypeTemplates, canDo: () => true },
        ]}


        onNew        = {TemplateType.create}
        onEdit       = {TemplateType.update}
        onDelete     = {TemplateType.delete}

        canDelete    = { (item) => (item['templates_count'] === 0) }

        textOnDelete = { (item) => <>Удалить тип <b>{item?.name ?? ''}</b>?</> }
    />

};

export default SettingsSystemTemplateTypesList;
