// prettier-ignore-start
import React from "react";

import { useHistory }         from 'react-router-dom';
import {getPath, SystemRoles} from "../../../api/methods";

import { PATHS }              from "../../../constants/RoutesPaths";
import List                   from "../../../components/Lists/List";
import LIF                    from "../../../components/Lists/ListItemFormats";
import LIT                    from "../../../components/Lists/ListItemTypes";


const SettingsSystemRoles = () => {

    const history = useHistory();

    const gotoRoleAccessRights = (roleId) => history.push(getPath(PATHS.settings.systemRoleAccessRights, {role_id: roleId}));
    const gotoRoleStatuses     = (roleId) => history.push(getPath(PATHS.settings.systemRoleStatuses    , {role_id: roleId}));


    return <List
        popupPrefix  = {'systemRoles'}
        getListData  = {SystemRoles.getAll}
        cols         = {[
          {name: 'id'                 , title: 'id'           , format: LIF.linkEdit, type: LIT.number, canAdd: true , canEdit: false},

          {name: 'is_active'          , title: 'активность'   , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0},
          {name: 'sysname'            , title: 'системное имя', format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'title'              , title: 'название'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'description'        , title: 'описание'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'app_statuses_count' , title: 'стат'         , format: LIF.usageCounter},
          {name: 'access_rights_count', title: 'прав'         , format: LIF.usageCounter},
          {name: 'users_count'        , title: 'пльз'         , format: LIF.usageCounter},
        ]}
        actions = {[
            {title: 'статусы' , onClick: gotoRoleStatuses    , canDo: () => true },
            {title: 'права'   , onClick: gotoRoleAccessRights, canDo: () => true },
        ]}
        buttons      =  {[
            {type: 'new'},
        ]}

        onNew        = {SystemRoles.add   }
        onEdit       = {SystemRoles.update}
        onDelete     = {SystemRoles.delete}

        canDelete    = {(item) => (item['app_statuses_count'] === 0 && item['access_rights_count'] === 0 && item['users_count'] === 0)}

        textOnDelete = {(item) => <>Удалить роль <b>{item?.title ?? ''}</b>?</>}
    />

};

export default SettingsSystemRoles;
