// prettier-ignore-start
const SUCCESS = "SUCCESS";
const ERROR   = "ERROR";
const DEFAULT = "DEFAULT";
const LOADING = "LOADING";

export type LoaderState = typeof SUCCESS | typeof ERROR | typeof DEFAULT | typeof LOADING;

export const SET_LOADER_STATE = 'SEND_MESSAGE';

interface SetLoaderStateAction {
    type   : typeof SET_LOADER_STATE
    payload: LoaderState
}

export type LoaderActionTypes = SetLoaderStateAction
