import {UseAccessRightsType} from "./useAccessRights";
import {
  accessRightName,
  accessRightValue,
  useAccessRightChecks,
  UseAccessRightChecksType
} from "./useAccessRightChecks";
import {useAppAccessRights} from "./useAppAccessRights";
import {userAccessRightName, userAccessRightValue} from "./useUserAccessRightChecks";

// TYPES

// CONSTS
export const appAccessRightName = {
  ...accessRightName,

  appDataQuestionnaire: 'app.data.questionnaire',
}

export const appAccessRightValue = {
  ...accessRightValue,

}

const defaultAccessRightValue = appAccessRightValue.deny;


// MAIN
export const useAppAccessRightChecks: (appId: number) => UseAccessRightChecksType = (appId) => {

  const appAccessRights     : UseAccessRightsType      = useAppAccessRights(appId, defaultAccessRightValue);
  const check: UseAccessRightChecksType = useAccessRightChecks(appAccessRights);


  return {
    ...check,

    // константы
    names        : appAccessRightName ,
    values       : appAccessRightValue,

    // результирующие проверки
    isAllowQuestionnaire : check.isNotDeny(appAccessRightName.appDataQuestionnaire),

    isAllowAppDataDocsResult             : check.isNotDeny      ( userAccessRightName.appDataDocsResult       ),
    isAllowAppDataDocsResultCreate       : check.accessRights.is( userAccessRightName.appDataDocsResult       , userAccessRightValue.create ),

  };

/*
    "app.data.answers"                   : { "value": "deny"   , "prop": "" },
    "app.data.contact_person"            : { "value": "allow"  , "prop": "" },
    "app.data.delivery"                  : { "value": "allow"  , "prop": "" },
    "app.data.questionnaire"             : { "value": "edit"   , "prop": "" },
    "app.data.deadlines"                 : { "value": "allow"  , "prop": "" },
    "app.data.price"                     : { "value": "all"    , "prop": "" },
    "app.data.price.payment.status"      : { "value": "allow"  , "prop": "" },
    "app.data.assigned.user"             : { "value": "allow"  , "prop": "" },
    "app.data.assigned.workers"          : { "value": "allow"  , "prop": "" },
    "app.data.payer"                     : { "value": "allow"  , "prop": "" },
    "app.data.props"                     : { "value": "allow"  , "prop": "" },
    "app.data.software_product"          : { "value": "allow"  , "prop": "" },
    "app.data.rooms_count"               : { "value": "allow"  , "prop": "" },
    "app.data.executor_company"          : { "value": "allow"  , "prop": "" },
    "app.data.wishes"                    : { "value": "allow"  , "prop": "" },
    "app.data.comments"                  : { "value": "private", "prop": "" },
    "app.data.docs.generation"           : { "value": "allow"  , "prop": "" },
    "app.data.docs.published"            : { "value": "deny"   , "prop": "" },
    "app.data.docs.signed"               : { "value": "create" , "prop": "" },
    "app.data.docs.payment_order"        : { "value": "create" , "prop": "" },
    "app.data.docs.signed.delete"        : { "value": "allow"  , "prop": "" },
    "app.data.docs.payment_order.delete" : { "value": "allow"  , "prop": "" },
    "app.edit"                           : { "value": "allow"  , "prop": "" },
    "app.delete"                         : { "value": "allow"  , "prop": "" },
*/

};
