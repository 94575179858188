import React, {useState} from "react";

import List from "./List";
import LIF from "./ListItemFormats";
import LIT from "./ListItemTypes";
import {reloadPage} from "../../functions/reloadPage";
import styled from "styled-components";
import {accent, gray} from "../../constants/Colours";
import {WaitLoader} from "../UI-kit/Preloader/WaitLoader";

// prettier-ignore-start

const IconBox = styled.div`
    display       : inline-block;
    background    : #fff;
    width         : 18px;
    height        : 18px;
    border-radius : 3px;
    border        : 1px solid ${accent};
    transition    : all 150ms;
`;

const WaitLoaderIcon = styled(WaitLoader)`
    margin-left   : 0px !important;
    padding       : 1px !important;
`;

const Icon = styled.svg`
    fill          : none;
    stroke        : ${accent};
    stroke-width  : 2px;
`;

const ListProps = ({
    backLink         = null,
    title           = '',
    popupPrefix     = '',
    keyName         = 'id',
    isActiveColName = 'is_active',
    name            = 'категория',
    cols            = [],
    buttons         = [],
    actions         = [],
    getListData           = () => [],

    getNotAssigned        = (setOptions) => {},

    newAll                = (success) => {},
    deleteAll             = (success) => {},
    activateAll           = (success) => {},
    deactivateAll         = (success) => {},

    canDelete             = (item) => true,

    onNew                 = (data, success, fail) => {},
    onEdit                = (data, success, fail) => {},
    onDelete              = (data, success, fail) => {},

    textOnDelete          = (item) => '',
    confirmName     = 'категории',

}) => {

    const [isNewAll       , setIsNewAll       ] = useState(false);
    const [isDeleteAll    , setIsDeleteAll    ] = useState(false);
    const [isActivateAll  , setIsActivateAll  ] = useState(false);
    const [isDeactivateAll, setIsDeactivateAll] = useState(false);

    const useActivate = isActiveColName !== '';

    const icon = (standartIcon, inAction, isInline = false) => {
        const style = isInline ? {display: 'inline-block', align: 'middle'} : {};

        return (inAction)
            ? <WaitLoaderIcon style={style}/>
            : standartIcon;
    }

    const doWithConfirm = (confirmText, setFlag, action) => {
        if (window.confirm(confirmText)) {
            setFlag(true);
            action( () => {
                setFlag(false);
                reloadPage();
            });
        }
    }

    const confirmText = (confirmName && confirmName !='') ? ' '+confirmName : '';

    const newAllConfirm        = () => doWithConfirm( 'Вы действительно хотите добавить все'      +confirmText+'?', setIsNewAll       , newAll        );
    const deleteAllConfirm     = () => doWithConfirm( 'Вы действительно хотите удалить все'       +confirmText+'?', setIsDeleteAll    , deleteAll     );
    const activateAllConfirm   = () => doWithConfirm( 'Вы действительно хотите активировать все'  +confirmText+'?', setIsActivateAll  , activateAll   );
    const deactivateAllConfirm = () => doWithConfirm( 'Вы действительно хотите деактивировать все'+confirmText+'?', setIsDeactivateAll, deactivateAll );

    const isAllInActiveSate = (data, isActive = true) =>
        data.length == 0 ||
        data.reduce(
            (value, item) => value && (item[isActiveColName] == isActive),
            true
        );

    const getCols = () => {
        const activateCols = !useActivate ? [] : [
            {name: isActiveColName, title: 'активна'  , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: true}
        ];

        const listCols = [
            ...activateCols,
            ...cols,
            {name: 'id', title: name, format: LIF.hidden  , type: LIT.select, canAddAndEdit: true , canEdit: false, getOptions: getNotAssigned, default: 0, isValid: (val) => val > 0},
        ];

        return listCols;
    }

    const getButtons = () => {
        const listActivateButtons = !useActivate ? [] : [
            {type: 'group', buttons: [ {
                title: <IconBox>{icon(
                    <Icon viewBox="0 0 18 18"><polyline stroke={gray} strokeWidth='1px' points="5 10 10 15 19 5" /><polyline points="1 10 6 15 15 5" /></Icon>,
                    isActivateAll
                )}</IconBox>,
                onClick: activateAllConfirm, isDisabled: (data) => isAllInActiveSate(data, true )
            }, {
                title: <IconBox>{icon(
                    <Icon viewBox="0 0 18 18"></Icon>,
                    isDeactivateAll
                )}</IconBox>,
                onClick: deactivateAllConfirm, isDisabled: (data) => isAllInActiveSate(data, false)
            } ]},
        ];

        const listButtons = [
            {type: 'group', buttons: [
                {type: 'back', to: backLink},
            ]},
            {type: 'new'},
            {type: 'group', buttons: [
                {title: <>+ все {confirmText} {icon('', isNewAll, true)}</>, onClick: newAllConfirm},
                {title: icon(<>&times;</>                       , isDeleteAll), onClick: deleteAllConfirm, isDisabled: (data) => (data.length == 0)}
            ]},
            ...listActivateButtons,
        ];

        return listButtons;
    }

    return (
        <List
            title        = {title}
            popupPrefix  = {popupPrefix}
            getListData  = {getListData}
            keyName      = {keyName}

            cols         = {getCols()}
            buttons      = {getButtons()}
            actions      = {actions}

            onNew        = {onNew}
            onEdit       = {onEdit}
            onDelete     = {onDelete}

            canDelete    = {canDelete}
            textOnDelete = {textOnDelete}
        />
    );
};

export default ListProps;