import React from "react";
import {useLoading} from "./useLoading";
import {ProgressBar} from "../components/Progress";

export function useProgress() {
  const loading = useLoading();

  const progressBar = (props = {}) => (
      <ProgressBar value={loading.props.loaded} max={loading.props.total} visible={loading.isActive} props={props} />
  );

  return {
    ...loading,
    progressBar: progressBar,
  };
}
