import styled from 'styled-components';
import { device } from '../../constants/Breakpoints';
import { black } from '../../constants/Colours';

const Subtitle = styled.h2`
  font-size: 24px;
  line-height: 160%;

  font-weight: bold;
  margin-bottom: 6px;
  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export default Subtitle;
