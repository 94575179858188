// prettier-ignore-start
import React from "react";
import {useHistory, useParams} from "react-router-dom";

import {
    getPath,
    SystemRoles,
    SystemRoleStatuses,
    SystemStatuses,
}                         from "../../../api/methods";
import { reloadPage }     from "../../../functions/reloadPage";

import { PATHS }          from "../../../constants/RoutesPaths";
import List               from "../../../components/Lists/List";
import LIF                from "../../../components/Lists/ListItemFormats";
import LIT                from "../../../components/Lists/ListItemTypes";
import ListFormat_Color   from "../../../components/Lists/Parts/ListFormat_Color";
import { useLoadRequest } from "../../../hooks/useDataRequest";


const SettingsSystemStatusRoles = () => {

    const history = useHistory();

    const { status_id } = useParams();

    const status = useLoadRequest({
        request     : SystemStatuses.getById,
        requestData : { id: status_id },
    });

    const gotoStatusRoleActions      = (role_status_id) => history.push(getPath(PATHS.settings.systemStatusRoleActions     , {role_status_id}));
    const gotoStatusRoleAccessRights = (role_status_id) => history.push(getPath(PATHS.settings.systemStatusRoleAccessRights, {role_status_id}));

    const formatRole       = (id, item, itemProps) => (item?.role?.title ?? '') + ' ('+ (item?.role?.sysname ?? '') + ')';
    const formatInstanceOf = (id, item, itemProps) => (! item?.instance_of) ? '' : (item?.instance_of?.role?.title ?? '') +' / '+ (item?.instance_of?.title ?? '');
    const formatColor      = (id, item, itemProps) => ListFormat_Color(item.calc_color);

    const optionsRole       = (success) => SystemRoles.select                  ({}              , ({data}) => success(data));
    const optionsInstanceOf = (success) => SystemRoleStatuses.selectIinstanceOf({hasUnset: true}, ({data}) => success(data));

    const titleParentTitle       = 'исходное название ("'+ status.data.title       +'")';
    const titleParentDescription = 'исходное описание ("'+ status.data.description +'")';

    const copyStatusRoleActions = (roleStatusId, item) => {
        if (item?.instance_of_id)
            SystemRoleStatuses.copyInstanceActions({id: roleStatusId}, ({data}) => reloadPage() );
    }



    return <List
        title        = {<>Роли на статусе "{status.data.title ?? '-'}"</>}
        popupPrefix  = {'systemStatusRoles'}
        getListData  = {(data, success, fail) => SystemRoleStatuses.getByStatusId({status_id}, success, fail)}

        cols         = {[
          {name: 'id'                    , title: 'id'                    , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: false, canEdit: false },
          {name: 'is_active'             , title: 'активность'            , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0   , align: 'center'},
          {name: 'role_id'               , title: 'роль'                  , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true , default: null, align: 'left'  , getOptions: optionsRole, customFormatHandler: formatRole },
          {name: 'has_parent_title'      , title: titleParentTitle        , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 0                    },
          {name: 'title'                 , title: 'название статуса'      , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'  },
          {name: 'has_parent_description', title: titleParentDescription  , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 0                    },
          {name: 'description'           , title: 'описание'              , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'  },
          {name: 'is_colored'            , title: 'использовать цвет'     , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 1 },
          {name: 'has_parent_color'      , title: 'наследовать от статуса', format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 1 },
          {name: 'color'                 , title: ''                      , format: LIF.linkEdit, type: LIT.color , canAddAndEdit: true , default: ''                   , customFormatHandler: formatColor },
          {name: 'instance_of_id'        , title: 'копия от'              , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true , default: null, align: 'left'  , getOptions: optionsInstanceOf, customFormatHandler: formatInstanceOf },
          {name: 'instance_to_count'     , title: 'ссыл'                  , format: LIF.usageCounter},
          {name: 'actions_count'         , title: 'дйст'                  , format: LIF.usageCounter},
          {name: 'access_rights_count'   , title: 'прав'                  , format: LIF.usageCounter},

        ]}
        actions = {[
            {title: 'действия'  , onClick: gotoStatusRoleActions     , canDo: () => true },
            {title: 'права'     , onClick: gotoStatusRoleAccessRights, canDo: () => true },
            {title: 'копировать', onClick: copyStatusRoleActions     , canDo: (item) => (!! item?.instance_of_id)},
        ]}
        buttons      =  {[
            {type: 'group', buttons: [
                {type: 'back', to: PATHS.settings.systemStatuses},
            ]},
            {type: 'new'},
        ]}

        onNew        = {(data, s, f) => SystemRoleStatuses.add({...data, status_id}, s, f)   }
        onEdit       = {SystemRoleStatuses.update}
        onDelete     = {SystemRoleStatuses.delete}
        canDelete    = {(item) => ((item['actions_count']+item['access_rights_count']+item['instance_to_count']) === 0)}

        textOnDelete = {(item) => <>Удалить роль <b>{item?.role?.title ?? ''}</b>?</>}
    />

};

export default SettingsSystemStatusRoles;
