// prettier-ignore-start
import React from "react";

import {DeliveryRegion, DeliveryTown, getPath} from "../../../api/methods";
import { T }        from "../../../lang/ru";

import List         from "../../../components/Lists/List";
import LIF          from "../../../components/Lists/ListItemFormats";
import LIT          from "../../../components/Lists/ListItemTypes";
import {PATHS}      from "../../../constants/RoutesPaths";
import {useParams}  from "react-router-dom";
import { useLoadRequest } from "../../../hooks/useDataRequest";
import { numbToLocale }   from "../../../functions/common";


const SettingsListsDeliveryTowns = () => {

    const { region_id } = useParams ();
    const region = useLoadRequest({
        request     : DeliveryRegion.read,
        requestData : { id: region_id },
    });

    const price_multiplier = region.data?.method?.price_multiplier ?? 1;

    const priceFormat  = (id, item, itemProps) => numbToLocale(item['price'] * price_multiplier);


    return (
        <List
            title        = {(region.data?.method?.name ?? '-') +' / '+ (region.data?.name ?? '-') +' / Города доставки'}
            popupPrefix  = {'delivery_towns'}
            getListData  = {(data, s, f) => DeliveryTown.list({ region_id }, s, f)}

            buttons      =  {[
                {type: 'group', buttons: [
                    {type: 'back', to: getPath(PATHS.settings.listsDeliveryRegions, {method_id: region.data.method_id})},
                ]},
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'is_active'         , title: 'активен'    , format: LIF.check       , type: LIT.check , canAddAndEdit: true , align: 'center', default: 1 },
                {name: 'name'              , title: 'название'   , format: LIF.linkEdit    , type: LIT.text  , canAddAndEdit: true , align: 'left'  , isValid: (val) => val !== ""},
                {name: 'price'             , title: 'цена (база)', format: LIF.linkEdit    , type: LIT.number, canAddAndEdit: true , align: 'center', isValid: (val) => val > 0},
                {name: 'price_result'      , title: 'цена (итог)', format: (price_multiplier === 1 ? LIF.hidden : LIF.linkEdit), type: LIT.number, canAddAndEdit: false, align: 'center', customFormatHandler: priceFormat},
                {name: 'time'              , title: 'срок'       , format: LIF.linkEdit    , type: LIT.text  , canAddAndEdit: true , align: 'left'  , isValid: (val) => val !== ""},
                {name: 'comment'           , title: 'комментарий', format: LIF.text        , type: LIT.text  , canAddAndEdit: true , align: 'left'  },
                {name: 'applications_count', title: 'заявок'     , format: LIF.usageCounter},
            ]}

            onNew        = {(data, success, fail) => DeliveryTown.create({...data, region_id }, success, fail)}
            onEdit       = {DeliveryTown.update}
            onDelete     = {DeliveryTown.delete}

            canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteDeliveryTown}<b>{item?.name ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsDeliveryTowns;
