import React from "react";
import {WaitLoader} from "./UI-kit/Preloader/WaitLoader";

const ProgressBarStyleCommon  = {
    display: 'block',
    padding: 0,
    border : 0,
    borderRadius: '6px',
    color: 'white',
    fontSize: '14px',
    lineHeight: '18px',
    height: '18px',
    textAlign: 'center',
}

export const ProgressBar = ({ value, visible, max = 100, showPercent = true, props = {} }) => {
    if (! visible) return null;

    const ProgressBarOutStyle  = {
        ...ProgressBarStyleCommon,
        display: (visible) ? 'block' : 'none',
        width: '100%',
        backgroundColor: 'lightgrey',
        ...props
    }

    const isValidProps = (max !== undefined) && (max !== null) && (max !== 0);

    if (! isValidProps)  {
        return (<div style={ProgressBarOutStyle}><WaitLoader /></div>)
    }

    if (value < 0  ) value = 0;
    if (value > max) value = max;

    const percent = Math.round(value/max*100);
    const percentText = (showPercent) ? percent.toLocaleString('ru-RU', {maximumFractionDigits: 0} )+"%" : null;

    const ProgressBarLineStyle  = {
        ...ProgressBarStyleCommon,
        width: percent+"%",
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#4f8ce8',
    }

    return (
        <div style={ProgressBarOutStyle}>
            <div style={ProgressBarLineStyle}>
                {percentText}
            </div>
        </div>
    );

}
