import React from "react";

import Text from "./UI-kit/Text";


const Divider = ({ style = {} }) => {
    return <Text style={{ ...style, display: "inline", padding: 5, border: 0, color: "lightgrey"}}> | </Text>;
};

export default Divider;
