// prettier-ignore-start
import React from "react";

import { TemplateGenerator } from "../../../../api/methods";

import { PATHS }             from "../../../../constants/RoutesPaths";
import List                  from "../../../../components/Lists/List";
import LIF                   from "../../../../components/Lists/ListItemFormats";
import LIT                   from "../../../../components/Lists/ListItemTypes";
import { isValidJsonCheck }  from "../../../../functions/validators";
import { isNotEmpty }        from "../../../../functions/common";


const SettingsSystemTemplateGeneratorsList = () => {

    return <List
        title        = 'Генераторы'
        popupPrefix  = {'systemTemplateGenerators'}
        getListData  = {TemplateGenerator.list}

        buttons      =  {[
            {type: 'group', buttons: [
                {type: 'back', to: PATHS.settings.systemTemplateTypes},
            ]},
            {type: 'new'},
        ]}

        cols         = {[
            {name: 'id'             , title: 'id'      , format: LIF.linkEdit, type: LIT.number  , canAdd: true, canEdit: false},
            {name: 'name'           , title: 'название', format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: ''       , align: 'left', isValid: (val) => isNotEmpty(val) },
            {name: 'props'          , title: 'свойства', format: LIF.hidden  , type: LIT.textarea, canAddAndEdit: true , default: '{}'     , align: 'left', isValid: (val) => isValidJsonCheck(val) },
            {name: 'description'    , title: 'описание', format: LIF.linkEdit, type: LIT.textarea, canAddAndEdit: true , default: ''       , align: 'left'},

            {name: 'template_files_count', title: 'файлов', format: LIF.usageCounter},
        ]}

        onNew        = {TemplateGenerator.create}
        onEdit       = {TemplateGenerator.update}
        onDelete     = {TemplateGenerator.delete}

        canDelete    = { (item) => (item['template_files_count'] === 0) }

        textOnDelete = { (item) => <>Удалить генератор <b>{item?.name ?? ''}</b>?</> }
    />

};

export default SettingsSystemTemplateGeneratorsList;
