import React, { useEffect, useRef } from 'react';
import { yellow } from '../../constants/Colours';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswerViewportRelative } from '../../store/answersInViewport/actions';


const Wrapper = styled.div`
  border: 1px solid ${(props) => (props.empty ? yellow : 'none')};
  border-radius: 10px;
  margin: 10px 0;
`;

export const EmptyFieldWrapper = ({ empty, children, id }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const emptyFieldsIds = useSelector((state) => state.emptyFieldsIds);
  let observer;
  if (
    emptyFieldsIds.length &&
    (emptyFieldsIds[0] === id || emptyFieldsIds.slice(-1) === id)
  ) {
    observer = new IntersectionObserver(([entry]) => {
      dispatch(setAnswerViewportRelative(id, entry.isIntersecting));
    });
  }

  useEffect(() => {
    if (observer) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [emptyFieldsIds]);

  return (
    <Wrapper empty={empty} ref={ref}>
      {children}
    </Wrapper>
  );
};
