// prettier-ignore-start
import React from "react";
import CommonMessageForm from "./CommonMessageForm";


const ActionMessageForm = () => {

    const form = (props = {
        popupName        : '',
        title            : '',
        data             : {},
        setData          : () => {},
        run              : () => {},
        onRun            : () => {},
        runText          : null,
    }, formCols) => (
        <CommonMessageForm
            cols = {[...formCols(props)]}

            {...props}
        />
    );

    return {
        form     : form,
    }

};

export default ActionMessageForm;
