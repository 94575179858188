import React, { useState } from 'react';

import { reloadPage  }             from '../../functions/reloadPage';
import { useProgress }             from '../../hooks/useProgress';

import SecondOrderButton           from '../UI-kit/Buttons/SecondOrderButton';
import Button                      from '../UI-kit/Buttons/Button';
import { WaitLoader  }             from '../UI-kit/Preloader/WaitLoader';
import { Empty                   } from '../Empty';
import { successDownloadFileName } from './DownloadFile';
import Divider from "../Divider";


const FileHandler = ({
  children,
  isEmpty = false,
  data = {},
  fileName = null,
  id = null,
  // flags
  isUploaded = false,
  userCanDelete = false,
  userCanUpload = false,
  downloadOnly = false,
  isDeleteBlocked = false,
  reloadPageAfterChange = true,
  isCustomDownload = false,

  // events
  onUpload = () => {},
  onDownload = () => {},
  onDelete = () => {},

  // texts
  subjectIm = null,
  subjectRod = null,
  downloadButtonText = null,
  downloadButtonTextProgress = null,
  uploadButtonText = null,
  uploadButtonTextProgress = null,
  deleteButtonText = null,
  deleteButtonTextProgress = null,
  deleteQuestionText = null,
  emptyText = null,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(true);

  const loading = useProgress();

  const canDelete = !downloadOnly && userCanDelete && !isDeleteBlocked;
  const canUpload = !downloadOnly && userCanUpload;

  const hasFileName = !!fileName;

  isUploaded = hasFileName || isUploaded;
  isCustomDownload = !hasFileName || isCustomDownload;

  const reload = () => {
    if (reloadPageAfterChange) reloadPage();
  };

  // texts ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const getText = (
    subject,
    phrase,
    defaultPhrase,
    defaultPhrasePostfix = ''
  ) => {
    if (phrase   ) return phrase;
    if (! subject) return defaultPhrase + defaultPhrasePostfix;
                   return defaultPhrase + ' ' + subject + defaultPhrasePostfix;
  };

  if (!subjectRod) subjectRod = subjectIm;

  uploadButtonText           = getText( subjectIm  , uploadButtonText          , 'Загрузить');
  uploadButtonTextProgress   = getText( subjectRod , uploadButtonTextProgress  , 'Загрузка'  , '...' );
  downloadButtonText         = getText( subjectIm  , downloadButtonText        , 'Скачать'   );
  downloadButtonTextProgress = getText( subjectRod , downloadButtonTextProgress, 'Скачивание', '...' );
  deleteButtonText           = getText( subjectIm  , deleteButtonText          , 'Удалить'   );
  deleteButtonTextProgress   = getText( subjectRod , deleteButtonTextProgress  , 'Удаление'  , '...' );
  deleteQuestionText         = getText( subjectIm  , deleteQuestionText        , 'Вы действительно хотите удалить', '?' );
  emptyText                  = getText( null, emptyText                 , 'отсутствует' );



  if (!isUploaded && !canUpload) return <Empty text={emptyText} />;

  // DOWNLOAD ////////////////////////////////////////////////////////////////////////////////////////////////////////

  const onClickDownload = () => {
    loading.activate();
    onDownload(
      data,
      (response) => {
        if (!isCustomDownload) successDownloadFileName(response, fileName);
        loading.deactivate();
      },
      () => {
        loading.deactivate();
      },
      loading.onProgress
    );
  };

  // Delete
  const onClickDelete = () => {
    const confirm = window.confirm(deleteQuestionText);

    if (confirm) {
      setIsDeleting(true);
      onDelete(data, () => {
        reload();
        setIsDeleting(false);
      });
    }
  };

  const showDelete = canDelete && !loading.isActive;
  const showDounload = !isDeleting;

  const downloadPart = (
    <>
      {showDounload &&
        ((loading.isActive && downloadButtonTextProgress) || (
          <SecondOrderButton
            disabled={false}
            style={{ padding: 0 }}
            onClick={onClickDownload}
          >
            {downloadButtonText}
          </SecondOrderButton>
        ))}
      {showDelete &&
        ((isDeleting && (
          <>
            {deleteButtonTextProgress}
            <WaitLoader />
          </>
        )) || (
          <>
            <Divider />
            <SecondOrderButton
              disabled={false}
              className="delete-button"
              onClick={onClickDelete}
            >
              {deleteButtonText}
            </SecondOrderButton>
          </>
        ))}
      {children}
      {loading.progressBar({
        /*marginTop: '5px'*/
      })}
    </>
  );

  if (isUploaded) {
    return downloadPart;
  }

  // UPLOAD //////////////////////////////////////////////////////////////////////////////////////////////////////////

  const onClickUpload = () => {
    const file = document.createElement('input');

    file.type = 'file';
    file.style = { display: 'none' };
    file.onchange = handleUploadFile;

    file.click();
  };

  const handleUploadFile = (e) => {
    const { files } = e.target;
    const [file] = files;

    loading.activate();
    setIsUploadSuccess(true);

    onUpload(
      { ...data, file },
      () => {
        loading.deactivate();
        reload();
      },
      () => {
        setIsUploadSuccess(false);
        loading.deactivate();
      },
      loading.onProgress
    );
  };


  return <>
      {(loading.isActive && uploadButtonTextProgress) || (
        <Button
          disabled = {false}
          onClick  = {onClickUpload}
          margin   = "0px"
          empty    = {isEmpty}
        >
          {uploadButtonText}
        </Button>
      )}
      {children}
      {loading.progressBar()}
      {!isUploadSuccess && 'Ошибка загрузки файла.'}
  </>

};

export default FileHandler;
