// prettier-ignore-start
import React from 'react';

import { Container, Row }         from 'styled-bootstrap-grid';
import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";

import Title                      from "../../../components/UI-kit/Title";
import OrdersList                 from "./OrdersList";
import OrderNewButton             from "../components/OrderNewButton";


type Props = {
    tabs: string[];
};


const Orders: React.FC<Props> = ({tabs = []}) => {

    const userAccess:any = useUserAccessRightChecks();


    if (! userAccess.isAllowAppList) return null;
    return <>
        <Title margin="30px 30px 15px 15px">{userAccess.propAppList}</Title>

        <Container>
            <Row>
                <OrderNewButton />
            </Row>
        </Container>

        <OrdersList tabs={tabs}/>
    </>

};

export default Orders;
