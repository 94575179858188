// prettier-ignore-start
import React, { useEffect } from 'react';

import { useHistory, useParams }   from 'react-router-dom';
import { useDispatch }             from 'react-redux';
import styled                      from 'styled-components';
import { Row }                     from 'styled-bootstrap-grid';

import { T }                       from '../../lang/ru';
import {Application, getPath}      from '../../api/methods';
import { getApplicationId }        from '../../functions/getters';
import { setIsHiddenMobileButton } from '../../store/isHiddenMobileButton/action';

import {useUserAccessRightChecks}  from "../../hooks/useUserAccessRightChecks";
import { useLoadRequest }          from "../../hooks/useDataRequest";

import { PATHS }                   from "../../constants/RoutesPaths";
import Button                      from '../../components/UI-kit/Buttons/Button';
import Title                       from '../../components/UI-kit/Title';
import ButtonFixedGroup            from '../../components/UI-kit/Buttons/ButtonsFixedGroup';

import orderTabType                from "./orderTabType";
import ActionsList                 from "./ActionsList";
import OrderInfo                   from './Info/OrderInfo';
import OrderChat                   from './Info/OrderChat/OrderChat';
import OrderObject                 from './Info/OrderAnswers/OrderObject';
import OrderHistoryLog             from "./Info/Log/OrderHistoryLog";
import BackButton                  from './BackButton';
import OrderButtons                from "./components/OrderButtons";


const TopRightLabel = styled.span`
  display   : inline-block;
  font-size : smaller;
  position  : absolute;
  background: none;
`;


const Order = ({
    tabs = [],
}) => {

    const { order_id } = useParams();
    const history      = useHistory();
    const dispatch     = useDispatch();
    const userAccess   = useUserAccessRightChecks();

    const application = useLoadRequest({
        request      : (d, s, f) => Application.getById(d, s, (data) => { f(data); history.push('/'); }),
        requestData  : { id: order_id },
        defaultResult: {},
    });

    useEffect(() => {
        dispatch(setIsHiddenMobileButton(false)); // ??
    }, []);


    const {
        contract_number,
        new_chat_messages,
    } = application.data;

    const activeTab = tabs[0] ?? orderTabType.orderInfo;

    const getTabs = () => {
        let tabs = [];
                                             tabs[0] = {name: orderTabType.orderInfo   , title: T.infoAboutOrder , path: getPath(PATHS.orderInfo   , {order_id})};
        if (userAccess.isAllowChat         ) tabs[1] = {name: orderTabType.orderChat   , title: T.chat           , path: getPath(PATHS.orderChat   , {order_id})};
        if (userAccess.isAllowQuestionnaire) tabs[2] = {name: orderTabType.orderForm   , title: T.infoAboutObject, path: getPath(PATHS.orderForm   , {order_id})};
        if (userAccess.isAllowHistoryLog   ) tabs[3] = {name: orderTabType.orderHistory, title: 'История'        , path: getPath(PATHS.orderHistory, {order_id})};

        return tabs;
    }

    const tabsList = getTabs();

    const gotoTab = (path) => history.push(path);


    return <>
        <Title margin="30px 30px 15px 15px">{T.order} №{getApplicationId(contract_number)}</Title>

        <Row style={{margin: '0 0 0 0px'}}>
            <OrderButtons
                application_id = {application.data.id}
                form_id        = {application.data.form_id}
            />
        </Row>

        <Row>
            <ButtonFixedGroup margin="0 0 0 16px">
                <BackButton buttonType={0} to={PATHS.orders}/>

                {tabsList.map((tab, index) => (
                    <Button
                        style     = {{ width: 240 }}
                        onClick   = {() => gotoTab(tab.path)}
                        key       = {index}
                        className = {tab.name === activeTab ? 'active' : ''}
                    >
                        {tab.title}
                        {((tab.name === orderTabType.orderChat) && (typeof new_chat_messages != 'undefined') && (new_chat_messages !== 0)) &&
                            <TopRightLabel>
                                &nbsp;&nbsp;+{new_chat_messages}
                            </TopRightLabel>
                        }
                    </Button>
                ))}
            </ButtonFixedGroup>
        </Row>

        {(activeTab === orderTabType.orderInfo   ) && <>
            <ActionsList
                appId           = {order_id}
                app             = {application.data}
                reload          = {application.reload}
            />
            <OrderInfo
                isLoading       = {application.isLoading}
                applicationData = {application.data}
            />
        </>}
        {(activeTab === orderTabType.orderChat   ) && userAccess.isAllowChat          && <OrderChat          id = {order_id} />}
        {(activeTab === orderTabType.orderForm   ) && userAccess.isAllowQuestionnaire && <OrderObject        id = {order_id} />}
        {(activeTab === orderTabType.orderHistory) && userAccess.isAllowHistoryLog    && <OrderHistoryLog appId = {order_id} />}
    </>

};

export default Order;
