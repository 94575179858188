import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import questionnaireFunc from '../../../../../functions/questionnaireFunc';
import { getPreviewMode } from '../../../../../store/previewMode/selectors';
import { setUserAnswer } from '../../../../../store/userAnswers/actions';
import Button from '../../../../../components/UI-kit/Buttons/Button';
import FloatingInput from '../../../../../components/UI-kit/FloatingInput';
import InputButtonGroup from '../../../../../components/UI-kit/InputButtonGroup';
import { Radio, RadioGroup } from '../../../../../components/UI-kit/RadioGroup';
import { CRUDStructElement } from '../../CRUDStructElement';
import { getUserAnswers } from '../../../../../store/userAnswers/selectors';
import hooks from '../../../../../functions/hooks';
import { EmptyFieldWrapper } from '../../../../../components/UI-kit/EmptyFieldWrapper';

const RadioPart = ({ struct, element, addStruct, deleteStruct }) => {
  const { id, name } = element;
  const key = id;

  const dispatch = useDispatch();
  const isEmpty = hooks.useIsFieldEmpty(id);
  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);

  const userAnswer = userAnswers[key]?.answer;
  const hasUserAnswer = userAnswer !== undefined ? 1 : 0;
  const [selectedValue, setSelectedValue] = useState();
  const [state, setState] = useState({ value: '' });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  useEffect(() => {
    setIsAddButtonDisabled(state.value === '');
  }, [state.value]);

  useEffect(() => {
    if (hasUserAnswer) {
      setSelectedValue(userAnswer);
    }
  }, [userAnswer]);

  const onClickRadioButton = (selectedValue) => setSelectedValue(selectedValue);

  const setUserAnswerRadio = (selectedValue) => {
    const userAnswer = questionnaireFunc.getAnswerInfo(element, selectedValue);

    dispatch(setUserAnswer(key, userAnswer));
    onClickRadioButton(selectedValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleAddStruct = () => {
    if (
      element.struct.every((i) => i.name !== state.value) ||
      !element.struct.length
    ) {
      addStruct(element.id, state.value);
      setState({ ...state, value: '' });
    } else {
      alert('Вы ввели одинаковые поля!');
    }
  };

  const handleClickRadio = isPreviewMode
    ? setUserAnswerRadio
    : onClickRadioButton;

  return (
    <>
      {isPreviewMode ? null : (
        <Col>
          <Row>
            <InputButtonGroup>
              <FloatingInput
                name="value"
                onChange={handleChange}
                value={state.value}
                label={'Название элемента'}
              ></FloatingInput>
              <Button
                disabled={isAddButtonDisabled}
                onClick={handleAddStruct}
                margin={'10px 0'}
              >
                Добавить ответ
              </Button>
            </InputButtonGroup>
          </Row>
        </Col>
      )}

      {isPreviewMode ? (
        <EmptyFieldWrapper empty={isEmpty} id={id}>
          <RadioGroup
            onClickRadioButton={handleClickRadio}
            selectedValue={selectedValue}
          >
            {element.struct.map(({ name, id }) => (
              <Radio key={id} value={id.toString()} labelText={name} />
            ))}
          </RadioGroup>
        </EmptyFieldWrapper>
      ) : (
        <CRUDStructElement
          elementId={element.id}
          struct={element.struct}
          deleteStruct={deleteStruct}
          changeState={setState}
          state={state}
        />
      )}
    </>
  );
};

export default RadioPart;
