// prettier-ignore-start
import React, { useEffect, useState } from "react";

import { Category, Form } from "../../../../api/methods";
import {usePreLoader}     from "../../../../hooks/usePreLoader";
import {useSaving}        from "../../../../hooks/useSaving";

import Button             from "../../../../components/UI-kit/Buttons/Button";
import FloatingInput      from "../../../../components/UI-kit/FloatingInput";
import FloatingSelect     from "../../../../components/UI-kit/FloatingSelect";
import InputButtonGroup   from "../../../../components/UI-kit/InputButtonGroup";
import Title              from "../../../../components/UI-kit/Title";
import FormsTable         from "./List/FormsTable";


const defaultCategories      = [];
const defaultForms           = [];
const defaultChoosenCategory = -1;

const EditorFormsList = () => {
    const saving = useSaving();

    const preloaderCategories = usePreLoader( true );
    const preloaderForms      = usePreLoader( true );

    const [categories        , setCategories        ] = useState(defaultCategories);
    const [forms             , setForms             ] = useState(defaultForms);
    const [newFormName       , setNameFormName      ] = useState("");
    const [choosenCategory   , setChoosenCategory   ] = useState(defaultChoosenCategory);

    useEffect(() => {
        if (choosenCategory !== defaultChoosenCategory) {
            loadForms();
        } else {
            setForms(defaultForms);
        }
    }, [choosenCategory]);

    useEffect(() => {
        loadCategories()
    }, []);

    const loadCategories = () => {
        preloaderCategories.activate();
        Category.getAllSelect(
            {},
            ({ data }) => {
                const category1st = (data.length > 0) ? data[0].id : defaultChoosenCategory;

                setCategories(data);
                setChoosenCategory(category1st);
                preloaderCategories.deactivate();
            },
            () => {
                setCategories(defaultCategories);
                setChoosenCategory(defaultChoosenCategory);
                preloaderCategories.deactivate(false);
            }
        );
    };

    const loadForms = () => {
        preloaderForms.activate();
        Form.list(
            { category_id: choosenCategory },
            ({ data }) => {
                setForms(data);
                preloaderForms.deactivate();
            },
            () => {
                setForms(defaultForms);
                preloaderForms.deactivate(false);
            }
        );
    }

    const addForm = () => {
        saving.activate();
        Form.add(
            { name: newFormName, category_id: choosenCategory },
            () => {
                loadForms();
                setNameFormName("");
                saving.deactivate();
            },
            () => {
                loadForms();
                setNameFormName("");
                saving.deactivate(false);
            }
        );
    };

    return (
        <>
            <Title>Редактор опросных форм</Title>
            {preloaderCategories.isActive
                ? preloaderCategories.wait()
                : ( (categories.length === 0)
                    ? 'Список категорий пуст'
                    : <>
                        <FloatingSelect
                            title={"Категория"}
                            containerStyle={{width: "max-content", paddingRight: 12}}
                            style={{width: "max-content"}}
                            value={choosenCategory}
                            name="form"
                            onChange={(e) => setChoosenCategory(e.target.value)}
                            options={categories}
                        />

                        {preloaderForms.isActive || (choosenCategory === defaultChoosenCategory)
                            ? preloaderForms.wait()
                            : <>
                                <InputButtonGroup style={{ alignItems: "center"}}>
                                    <FloatingInput
                                        label={"Название нового раздела"}
                                        onChange={(e) => setNameFormName(e.target.value)}
                                        value={newFormName}
                                    />
                                    <Button
                                        // disabled={isAddButtonDisabled}
                                        onClick={addForm}
                                        margin={"10px 0"}
                                    >
                                        Добавить форму
                                    </Button>
                                    {saving.spinner()}
                                </InputButtonGroup>

                                <FormsTable
                                    forms={forms}
                                    loadForms={loadForms}
                                />
                            </>
                        }
                    </>
                )
            }
        </>
    );
};

export default EditorFormsList;
