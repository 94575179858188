import {API_URL, optionsDownload} from "../../api/methods";
import Axios from "axios";

export const downloadData = (url, responseType, success, fail = undefined, load = undefined, isCustomURL = false) => {

  let token = localStorage.getItem("accessToken");

  const headers = {
    Authorization: "Bearer " + token,
  };

  if (! isCustomURL) {
    let isRelativeURL = url.slice(0,4) !== 'http';
    let urlRoot   = API_URL.replace("/api/", "");

    url = ( (isRelativeURL) ? urlRoot : '' ) + url;
  }

  Axios({
    url: url,
    method: "GET",
    headers: headers,
    responseType: responseType,
    ...optionsDownload(load)
  }).then((response) => {
    success(response)
  }).catch((response) => {
    if (typeof fail === "function") fail(response);
  });
};
