// prettier-ignore-start
import React from "react";

import {Delivery, DeliveryRegion, getPath} from "../../../api/methods";
import { T }        from "../../../lang/ru";

import List         from "../../../components/Lists/List";
import LIF          from "../../../components/Lists/ListItemFormats";
import LIT          from "../../../components/Lists/ListItemTypes";
import {PATHS}      from "../../../constants/RoutesPaths";
import {useHistory, useParams} from "react-router-dom";
import { useLoadRequest } from "../../../hooks/useDataRequest";


const SettingsListsDeliveryRegions = () => {

    const history         = useHistory();
    const { method_id }   = useParams ();
    const delivery_method = useLoadRequest({
        request     : Delivery.read,
        requestData : { id: method_id },
    });


    const gotoDeliveryTowns = (region_id) => history.push(getPath(PATHS.settings.listsDeliveryTowns,{region_id}));

    return (
        <List
            title        = {(delivery_method.data.name ?? '-') + ' / Регионы доставки'}
            popupPrefix  = {'delivery_regions'}
            getListData  = {(data, s, f) => DeliveryRegion.list({ method_id }, s, f)}

            buttons      =  {[
                {type: 'group', buttons: [
                    {type: 'back', to: PATHS.settings.listsDeliveryMethods},
                ]},
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'is_active'  , title: 'активен'    , format: LIF.check       , type: LIT.check, canAddAndEdit: true, align: 'center', default: 1 },
                {name: 'name'       , title: 'название'   , format: LIF.linkEdit    , type: LIT.text , canAddAndEdit: true, align: 'left'  , isValid: (val) => val !== ""},
                {name: 'comment'    , title: 'комментарий', format: LIF.text        , type: LIT.text , canAddAndEdit: true, align: 'left'  },
                {name: 'towns_count', title: 'городов'    , format: LIF.usageCounter},
            ]}

            actions      = {[
                {title: 'города', onClick: gotoDeliveryTowns },
            ]}

            onNew        = {(data, success, fail) => DeliveryRegion.create({...data, method_id }, success, fail)}
            onEdit       = {DeliveryRegion.update}
            onDelete     = {DeliveryRegion.delete}

            canDelete    = {(item) => (item['towns_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteDeliveryRegion}<b>{item?.name ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsDeliveryRegions;
