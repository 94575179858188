import styled from "styled-components";
import { accent, lightgray, white } from "../../../constants/Colours";

export const SmallButton = styled.button`
  font-size: 16px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: ${accent};
  border: 1px solid ${accent};
  transition: all 200ms linear;
  cursor: pointer;
  &:hover {
    background-color: ${accent};
    color: ${white};
  }
`;
