// prettier-ignore-start
import React from "react";

import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";

import { API_URL }                from "../../../api/methods";
import { isEmpty }                from "../../../functions/common";
import Divider                    from "../../../components/Divider";
import { downloadData }           from "../../../components/File/DownloadData";
import FileHandler                from "../../../components/File/FileHandler";
import { Empty }                  from "../../../components/Empty";


const OrderInfoDocsGeneration = ({applicationData}) => {

    const userAccess = useUserAccessRightChecks();

    const {
        contract_number,
        docs_generation = [],
        executor_company_id,
    } = applicationData;

    const getFileName = (docName, docType) => {
        let [newDocName] = docName.split("(");

        newDocName = newDocName.replace(" ", "");
        newDocName += `. Заказ №${contract_number}`;
        newDocName += "." + docType;

        return newDocName;
    }

    const getGenHref    = (type_name, format_name, mode_name) => API_URL + 'applications/' + applicationData.id + '/doc/' + type_name + '/' + format_name + (isEmpty(mode_name) ? '' : ('/'+mode_name));
    const getStoredHref = (id        ) => API_URL + 'applications/' + applicationData.id + '/doc/' + id;

    const getLink       = (text, file_name, href) => { // <a href={path}>[ скачать {text} ]</a>;
        const handleDownload = (data, success, fail, load) => downloadData(href, "blob", success, fail, load);

        return <FileHandler
            fileName           = {file_name}
            downloadOnly       = {true}
            onDownload         = {handleDownload}
            downloadButtonText = {text}
            subjectIm          = {`: ${text}`}
        />
    }


    if (! userAccess.isAllowAppDataDocsGeneration) return null;
    if (docs_generation.length === 0             ) return <Empty key={0} text='отсутствуют' />
    if (! executor_company_id                    ) return '* выберите организацию-исполнителя по договору';

    return Object.keys(docs_generation).map( (group_title) => {
        // return <><b style={{padding: 0}}>{group_title}</b><br /></>

        return Object.keys( docs_generation[ group_title ] ).map( (file_type_name) => {
            // {outSeparator()}
            const getStoredLink = (id, name)   => <>{getLink(name  , name, getStoredHref(id))}<br/></>;

            const {
                title    = '',
                generate = [],
                stored   = [],
            } = docs_generation[ group_title ][ file_type_name ];

            let is_first = true;
            const outSeparator = () => {
                if (! is_first)
                    return <Divider style={{marginRight: "15px"}}/>;

                is_first = false;
                return null;
            }

            const list_generate = Object.keys( generate ).map( (format_title) => {
                const {
                    file_name   = '',
                    ext         = '',
                    type_name   = '',
                    format_name = '',
                    mode        = '',
                } = generate[ format_title ];

                return <>
                    {outSeparator()}
                    {getLink(
                        format_title,
                        file_name,
                        getGenHref(type_name, format_name, mode)
                    )}
                </>;
            });

            const list_stored = Object.keys( stored ).map( (file_id) => {
                const file_name = stored[file_id];

                return <span style={{padding: 0, marginLeft: "0px"}}>
                    {getLink(
                        file_name,
                        file_name,
                        getStoredHref(file_id)
                    )}
                    <br />
                </span>;
            });

            // <b style={{padding: 0}}>{group_title}</b><br />
            return <>
                <span style={{padding: 0, marginLeft: "0px"}}>
                    <span style={{padding: 0, marginRight: "15px"}}>{title}:</span> {list_generate}<br />
                    {list_stored}
                </span>
            </>
        });
    });

};

export default OrderInfoDocsGeneration;