import { PATHS } from '../constants/RoutesPaths';

export const storageData = {
    accessRights : 'accessRights',
    role         : 'role',
    ratings      : 'ratings',
    user_fio     : 'user_fio',
}

export const authSuccess: (res: any) => void = (res) => {
    const {
        access_token,
        role,
        access_rights,
        ratings,
        fio,
    } = res.data;

    localStorage.setItem( 'accessToken'           , access_token                  );
    localStorage.setItem( storageData.user_fio    , fio                           );
    localStorage.setItem( storageData.role        , role                          );
    localStorage.setItem( storageData.accessRights, JSON.stringify(access_rights) );
    localStorage.setItem( storageData.ratings     , JSON.stringify(ratings      ) );

    document.location.replace(PATHS.default);
};
