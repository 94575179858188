import styled from "styled-components";
import { ultraLightgray } from "../../../constants/Colours";

const Footer = styled.div`
  height: 60px;
  margin-top: 40px;
  // background-color: ${ultraLightgray};
`;

export default Footer;
