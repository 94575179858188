// prettier-ignore-start
import React from "react";

import { useParams }              from "react-router-dom";
import CompanyTemplatesList       from "./CompanyTemplatesList";
import { Form, getPath,Template } from "../../../api/methods";
import { useLoadRequest }         from "../../../hooks/useDataRequest";
import { PATHS }                  from "../../../constants/RoutesPaths";
import { ifNotEmptyPostfix }      from "../../../functions/common";


const SettingsCompanyFormTemplates = () => {

    const { company_id, form_id } = useParams();

    const form = useLoadRequest({
        request    : Form.get,
        requestData: {id: form_id},
    })

    return <CompanyTemplatesList
        popupPrefix  = { 'companyCategoryTemplatesList' }
        backTo       = { getPath(PATHS.settings.companyForms, {company_id}) }
        title        = { ifNotEmptyPostfix(form.data?.name, '. ', '') + 'Шаблоны' }

        getListData  = { (d, s, f) => Template.listCompanyForm   ({...d, form_id}, s, f) }
        onNew        = { (d, s, f) => Template.assign2CompanyForm({...d, form_id}, s, f) }
    />

};

export default SettingsCompanyFormTemplates;
