// prettier-ignore-start
import {PATHS}   from "../../../../../constants/RoutesPaths";
import {getPath} from "../../../../../api/methods";

export const defaultResult = {

    head      : (rowData) => '',
    body      : (rowData) => '',
    headProps : (rowData) => ({
        className: "fixed-row"
    }),
    bodyProps : (rowData) => ({
        to    : getPath(PATHS.orderInfo, {order_id: rowData.id ?? 0} ),
    }),
}


export const Common = (
    head = '',
    data = '',
) => ({
    head : (rowData) => head,
    body : (rowData) => data,
});
