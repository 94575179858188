// prettier-ignore-start

const ActionFormType = {
    _spliter       : '_splitter',

    popupForm      : 'popup_form',
    gotoLocation   : 'goto_location',

    //popupComponent : 'popup_component',
    //component      : 'component',
};

export default ActionFormType;
