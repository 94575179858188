// prettier-ignore-start
import React from "react";

import { useHistory }               from "react-router-dom";

import { Company, getPath }         from "../../../api/methods";
import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";
import { PATHS }                    from "../../../constants/RoutesPaths";
import LIF                          from "../../../components/Lists/ListItemFormats";
import List                         from "../../../components/Lists/List";


const SettingsCompanyList = () => {

    const history    = useHistory();
    const userAccess = useUserAccessRightChecks();

    const gotoEdit            = (id) => history.push( getPath( PATHS.settings.companyEdit           , { company_id: id } ) );
    const gotoTemplates       = (id) => history.push( getPath( PATHS.settings.companyTemplates      , { company_id: id } ) );
    const gotoForms           = (id) => history.push( getPath( PATHS.settings.companyForms          , { company_id: id } ) );
    const gotoResultTemplates = (id) => history.push( getPath( PATHS.settings.companyResultTemplates, { company_id: id } ) );



    return <List
        popupPrefix  = {'companyList'}
        getListData  = {Company.list}

        buttons      =  {[
            {title: '+', onClick: () => history.push(PATHS.settings.companyNew)},
        ]}

        cols         = {[
            {name: 'id'          , title: 'id'                   , format: LIF.text},
            {name: 'display_name', title: 'Отображаемое название', format: LIF.linkGoTo, canAddAndEdit: false, align: 'left', props: {goTo: gotoEdit} },
            {name: 'name'        , title: 'название'             , format: LIF.linkGoTo, canAddAndEdit: false, align: 'left', props: {goTo: gotoEdit} },
            {name: 'inn'         , title: 'ИНН'                  , format: LIF.text    , canAddAndEdit: false },

            {name: 'categories_count'        , title: 'категорий', format: LIF.usageCounter},
            {name: 'applications_count'      , title: 'заявок'   , format: LIF.usageCounter},
            {name: 'templates_assigned_count', title: 'шаблоны'  , format: LIF.usageCounter},
        ]}

        actions = {[
            {title: 'шаблоны'  , onClick: gotoTemplates      , canDo: () => userAccess.isAllowSysSettingsTemplates },
            {title: 'формы'    , onClick: gotoForms          , canDo: () => true },
            {title: 'свод'     , onClick: gotoResultTemplates, canDo: () => true },
        ]}

        onDelete     = {Company.delete}

        canDelete    = {(item) => (item['applications_count'] === 0) && (item['categories_count'] === 0)}

        textOnDelete = {(item) => <>Удалить организацию <b>{item?.name ?? ""}</b>?</>}
    />

};

export default SettingsCompanyList;
