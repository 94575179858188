// prettier-ignore-start
import React from "react";

import { useHistory, useParams } from 'react-router-dom';
import {
    getPath, Template,
    TemplateType
} from "../../../../api/methods";
import { PATHS }            from "../../../../constants/RoutesPaths";
import List                 from "../../../../components/Lists/List";
import LIF                  from "../../../../components/Lists/ListItemFormats";
import LIT                  from "../../../../components/Lists/ListItemTypes";
import { isNotEmpty }       from "../../../../functions/common";
import { isValidJsonCheck } from "../../../../functions/validators";
import { useLoadRequest }   from "../../../../hooks/useDataRequest";


const SettingsSystemTemplateTypeTemplatesList = () => {

    const { template_type_id } = useParams();

    const template_type = useLoadRequest({
        request     : TemplateType.get,
        requestData : { template_type_id },
    })

    const history = useHistory();

    const gotoTemplateFiles      = (id) => history.push( getPath( PATHS.settings.systemTemplateTypeTemplateFiles, { template_type_id, template_id: id } ) );


    return <List
        title        = {'Шаблоны типа "' +(template_type.data?.title ?? '')+ '"'}
        popupPrefix  = {'systemTemplatesList'}
        getListData  = {(d, s, f) => Template.list({ type_id: template_type_id }, s, f)}

        buttons      =  {[
            {type: 'group', buttons: [
                    {type: 'back', to: PATHS.settings.systemTemplateTypes},
                ]},
            {type: 'new'},
        ]}

        cols         = {[
            {name: 'id'                  , title: 'ID'               , format: LIF.linkEdit },
            {name: 'is_active'           , title: 'активность'       , format: LIF.check   , type: LIT.check   , canAddAndEdit: true , default: 1    },
            {name: 'title'               , title: 'Название'         , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: ''   , align: 'left', isValid: (val) => isNotEmpty(val) },
            {name: 'props'               , title: 'параметры'        , format: LIF.hidden  , type: LIT.textarea, canAddAndEdit: true , default: '{}' , isValid: (val) => isValidJsonCheck(val) },

            {name: 'assigned_count'      , title: 'исп'              , format: LIF.usageCounter},
            {name: 'template_files_count', title: 'файлов'           , format: LIF.usageCounter},
        ]}

        actions = {[
            {title: 'файлы', onClick: gotoTemplateFiles, canDo: () => true },
        ]}

        onNew        = {(d, s, f) => Template.create({ ...d, type_id: template_type_id }, s, f)}
        onEdit       = {Template.update}
        onDelete     = {Template.delete}

        canDelete    = { (item) => (item['assigned_count'] === 0) && (item['template_files_count'] === 0) }

        textOnDelete = { (item) => <>Удалить шаблон <b>{item?.title ?? ''}</b>?</> }
    />

};

export default SettingsSystemTemplateTypeTemplatesList;
