import React, {useState, useEffect} from 'react'

import {useHistory}                     from "react-router-dom";

import { Bonuses }                      from "../../api/methods";
import {useAppDispatch, useAppSelector} from "../../hooks/useRedux";
import {setBonusesStatus}               from "../../store/bonuses/actions";
import {setPopUpFields}                 from "../../store/bonusesPopUp/actions";

import {historyItem, receivedHistoryItem,
                   transferHistoryItem} from "../../models/bonuses.model";
import { yellow }                       from '../../constants/Colours'
import Stack                            from '../../components/UI-kit/layout/Stack'
import Title                            from '../../components/UI-kit/Title'
import Tooltip                          from '../../components/UI-kit/Tooltip'
import Button                           from "../../components/UI-kit/Buttons/Button";
import {Preloader}                      from "../../components/UI-kit/Preloader/Preloader";
import Subtitle                         from '../../components/UI-kit/Subtitle'
import History                          from './History'
import * as styles                      from './Bonuses.styles'
import Form                             from './Form'
import TransferBonusesTable             from './TransferBonusesTable'
import ReceiveBonusesTable              from './ReceivedBonusesTable'



export default function BonusesComponent() {

    const dispatch      = useAppDispatch()
    const routerHistory = useHistory    ()

    const [history        , setHistory        ] = useState<historyItem[]>        ([]);
    const [receivedBonuses, setReceivedBonuses] = useState<receivedHistoryItem[]>([]);
    const [transferHistory, setTransferHistory] = useState<transferHistoryItem[]>([]);

    const executeFunc = useAppSelector(state => state.bonusesPopUp.executeFunc);
    const status      = useAppSelector(state => state.bonuses.status);

    const fetchData = () => {
        Bonuses.getHistory                 ({}, (data) => setHistory        (data.data));
        Bonuses.getIncomingTransfersList   ({}, (data) => setReceivedBonuses(data.data));
        Bonuses.getOutgoingTransfersHistory({}, (data) => setTransferHistory(data.data));
        Bonuses.getStatus                  ({}, (data) => dispatch(setBonusesStatus(data.data)));
    }

    useEffect(() => {
        if (executeFunc) fetchData()
        dispatch(setPopUpFields({ executeFunc: false}))
    }, [executeFunc])

    useEffect(() => {
        if (!executeFunc) fetchData()
    }, [])


    if (! status                        ) return <Preloader data-testid="loader"/>
    if (! status.is_bonus_program_member) return <Subtitle>Вы не являетесь участником бонусной программы </Subtitle>

    return <styles.Bonuses>
        <Button
            style   = {{marginBottom: 25}}
            onClick = {() => routerHistory.push("/")}
        >
            {"<<"} К списку заявок
        </Button>

        <styles.Header>
            <Stack>
                <Title margin="0">{status.f_i_o_short}</Title>
                <Tooltip label="ваш статус" position="down">
                    {status.bonus_program?.name
                        ? <styles.Chip color={yellow} withoutMargin>{status.bonus_program.name}</styles.Chip>
                        : null
                    }
                </Tooltip>
                <Tooltip label="ваша скидка" position="down">
                    <styles.Chip withoutMargin>{status.bonus_program.value}%</styles.Chip>
                </Tooltip>
            </Stack>
        </styles.Header>

        <Subtitle>
            <a href={'/storage/documents/bonus_program_v2.docx'}>Условия бонусной программы</a>
        </Subtitle>

        <ReceiveBonusesTable receivedBonuses={receivedBonuses}/>

        <TransferBonusesTable transferHistory={transferHistory}/>

        {status.bonuses_count > 0 && <Form balance={status.bonuses_count} />}

        <History history={history}/>

    </styles.Bonuses>

}
