// prettier-ignore-start
import {T} from "../../../../../lang/ru";
import {defaultResult} from "./Common";


export const CategoryForm = () => {

/*
    <th>{T.filterNames.objectType}</th>
    <td style={{whiteSpace: 'normal', minWidth: '400px'}}>{application.object_name}</td>
*/

    return {
        head : (rowData) => T.filterNames.objectType,

        body : (rowData) => rowData.form_name ?? '',

        bodyProps : (rowData) => ({
            ...defaultResult.bodyProps(rowData),

            whiteSpace: 'normal',
            minWidth  : '400px',
        })
    };

};