// prettier-ignore-start
import React from "react";

import { useHistory, useParams }    from "react-router-dom";

import { Company, getPath }         from "../../../api/methods";
import { useLoadRequest }           from "../../../hooks/useDataRequest";
import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";
import { ifNotEmptyPostfix }        from "../../../functions/common";
import { PATHS }                    from "../../../constants/RoutesPaths";
import LIF                          from "../../../components/Lists/ListItemFormats";
import List                         from "../../../components/Lists/List";
import LIT                          from "../../../components/Lists/ListItemTypes";
import BackTitleButton              from "../../Orders/BackTitleButton";


const SettingsCompanyForms = () => {

    const history        = useHistory();
    const userAccess     = useUserAccessRightChecks();
    const { company_id } = useParams();

    const companyInfo = useLoadRequest({
        request    : Company.get,
        requestData: { id: company_id },
    })

    const isForm         = (type) => (type === 'form');

    const gotoTemplates = (id, item) => isForm( item?.type )
        ? history.push( getPath( PATHS.settings.companyFormTemplates    , { company_id, form_id    : item.form_id     } ) )
        : history.push( getPath( PATHS.settings.companyCategoryTemplates, { company_id, category_id: item.category_id } ) );

    const name = (item) => isForm( item?.type )
        ? (item?.form_name     ?? '-')
        : (item?.category_name ?? '-');

    const formatName = (id, item, itemProps) => isForm( item?.type )
            ? <div style={{marginLeft: '32px'}}>{item?.form_name ?? '-'}</div>
            : <strong>{item?.category_name ?? '-'}</strong>;

    const formatNameInfo = (item) => item?.id +": "+ (name(item) ?? '(удалено)');
    const formatType     = (item) => isForm(item?.type) ? 'Форма' : 'Категория';


    return <>
        <List
            popupPrefix  = {'companyFormsList'}
            getListData  = {(d, s, f) => Company.formsList({...d, company_id}, s, f)}
            title        = {<BackTitleButton title={ifNotEmptyPostfix(companyInfo?.data.name, '. ', '') + 'Доступные формы'} backTo={PATHS.settings.company}/>}

            cols         = {[
                {name: 'id'             , title: 'id'               , format: LIF.linkEdit },
                {name: 'is_active'      , title: 'доступ'           , format: LIF.check   , type: LIT.check, canAddAndEdit: true , default: 1    },
                {name: 'type'           , title: ''                 , format: LIF.hidden  , type: LIT.info , canAddAndEdit: true ,                 customFormatHandler: formatType     },
                {name: 'name'           , title: 'Категория / Форма', format: LIF.hidden  , type: LIT.info , canAddAndEdit: true ,                 customFormatHandler: formatNameInfo },
                {name: 'name'           , title: 'Категория / Форма', format: LIF.linkEdit,                  canAddAndEdit: false, align: 'left' , customFormatHandler: formatName     },
                {name: 'category_id'    , title: 'категория'        , format: LIF.hidden  ,                  canAddAndEdit: false },
                {name: 'form_id'        , title: 'форма'            , format: LIF.hidden  ,                  canAddAndEdit: false },

                {name: 'templates_count', title: 'шаблоны', format: LIF.usageCounter},
            ]}

            actions = {[
                {title: 'шаблоны'  , onClick: gotoTemplates, canDo: (item) => (! item.is_broken) && userAccess.isAllowSysSettingsTemplates },
            ]}

            onEdit = {(d, s, f) => isForm(d.type)
                    ? Company.assign2Form    ({company_id,     form_id: d.form_id    , is_active: d.is_active}, s, f)
                    : Company.assign2Category({company_id, category_id: d.category_id, is_active: d.is_active}, s, f)
                }
        />
    </>

};

export default SettingsCompanyForms;
