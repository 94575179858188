// prettier-ignore-start
import React from 'react';

import { useParams } from 'react-router-dom';
import Questionnaire from "../../Forms/Questionnaire/Questionnaire";
import { getPath }   from "../../../api/methods";
import { PATHS }     from "../../../constants/RoutesPaths";


type paramsType = {
    payer_id       : string,
    category_id    : string,
    form_id        : string,
    application_id : string
}

type propsType = {
    match: {
        params: paramsType;
    };
};


const OrderEditForm: React.FC<propsType> = (props) => {
    const { form_id, application_id }: paramsType = useParams();

    return <Questionnaire
        isPreview      = { false }
        autosave       = { false }

        form_id        = { form_id }
        application_id = { application_id }

        nextTitle      = { 'Сохранить' }
        nextTo         = { getPath(PATHS.orderInfo, { order_id: application_id }) }

        backTitle      = { 'назад' }
        backTo         = { getPath(PATHS.orderInfo, { order_id: application_id }) }
    />

};

export default OrderEditForm;
