// prettier-ignore-start
import React from "react";

import settingsTabType           from "../tabType";
import SettingsSystemActionsList from "./SettingsSystemActionsList";
import SettingsSystemActionMails from "./SettingsSystemActionMails";


const SettingsSystemActions = ({ sub = settingsTabType.systemActions }) => {

    if (sub === settingsTabType.systemActionMails) return <SettingsSystemActionMails/>

    return <SettingsSystemActionsList />

};

export default SettingsSystemActions;
