// prettier-ignore-start
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Element }     from '../api/methods';


const useIsFieldEmpty = (id, element = []) => {
  const emptyFields = useSelector((state) => state.emptyFieldsIds);

  let isEmpty = false;
  emptyFields.forEach((i) => (i === id ? (isEmpty = true) : null));
  return isEmpty;
};

export function useOnScreen(ref, id) {}
const useSystemNames = () => {
  const [systemNames, setSystemNames] = useState([]);

  useEffect(() => {
    let storedSystemNames = localStorage.getItem('systemNames');

    if (storedSystemNames === null) {
      loadSystemNames();
    } else {
      setSystemNames(storedSystemNames);
    }
  }, []);

  const loadSystemNames = () => {
    Element.getSystemNames({}, loadSystemNamesSuccess);
  };

  const loadSystemNamesSuccess = ({ data }) => {
    localStorage.setItem('systemNames', data);
    setSystemNames(data);
  };

  return systemNames;
};

const useCheckbox = () => {
  const [value, setValue] = useState(false);

  const switchValue = () => setValue(!value);

  return [value, switchValue];
};

export default {
  useSystemNames,
  useCheckbox,
  useIsFieldEmpty,
  useOnScreen,
};
