// prettier-ignore-start
import React, { useEffect, useState } from 'react';

import { useHistory, useParams }    from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row }                 from 'styled-bootstrap-grid';
import ym                           from 'react-yandex-metrika';
import ReactGA                      from 'react-ga';

import { Application, Delivery, User, Payers,
    Promocode, getPath }  from '../../../api/methods';
import { isValidEmailCheck,
     isValidPhoneCheck }  from '../../../functions/validators';
import { isEmptyString }  from "../../../functions/common/common";
import { useSaving }      from "../../../hooks/useSaving";
import { T }              from '../../../lang/ru';
import { setActiveStep }  from '../../../store/activeStep/actions';


import checkSuccessImg    from '../../../assets/svg/check.svg';
import checkFailImg       from '../../../assets/svg/close.svg';

import { PATHS }          from '../../../constants/RoutesPaths';

import FloatingSelect     from '../../../components/UI-kit/FloatingSelect';
import Checkbox           from '../../../components/UI-kit/Checkbox';
import { CheckBoxLabel }  from '../../../components/UI-kit/CheckBoxLabel';
import Button             from '../../../components/UI-kit/Buttons/Button';
import SecondOrderButton  from '../../../components/UI-kit/Buttons/SecondOrderButton';
import FloatingInput      from '../../../components/UI-kit/FloatingInput';
import InputCheckbox      from '../../../components/UI-kit/InputCheckbox';
import Radio              from '../../../components/UI-kit/Radio';
import Subtitle2          from '../../../components/UI-kit/Subtitle2';
import Title              from '../../../components/UI-kit/Title';
import DeliveryFormFields from "../Info/Delivery/DeliveryFormFields";
import {useDataRequest, useLoadRequest, useSaveRequest} from "../../../hooks/useDataRequest";
import {WaitSpinner} from "../../../components/UI-kit/Preloader/WaitSpinner";
import {useValidation} from "../../../hooks/useValidation";


const CheckSuccess = () => <img src={checkSuccessImg} alt="" style={{width:16, height:16}} />;
const CheckFail    = () => <img src={checkFailImg   } alt="" style={{width:16, height:16}} />;

const Check        = (isFail) => {
    if (isFail === null) return null;
    if (isFail         ) return null; // CheckFail();
                         return CheckSuccess();
}

const OrderNewSt5Delivery = () => {

    const {
        payer_id,
        category_id,
        form_id,
        application_id
    } = useParams();

    const dispatch      = useDispatch();
    const history       = useHistory ();
    const bonusesStatus = useSelector(state => state.bonuses?.status);

    const [useBonuses, setUseBonuses] = useState(false);
    const [wrongPromo, setWrongPromo] = useState(null );

    const [ values, setValues ] = useState({
        contact_name  : '',
        contact_phone : '',
        contact_email : '',
        promo         : '',
        bonuses       : 0,
        wishes        : '',
        is_agree      : false,
    });

    const {
        contact_name,
        contact_phone,
        contact_email,
        promo,
        bonuses,
        wishes,
        is_agree,
    } = values;

    const delivery = DeliveryFormFields(
        application_id,
        true,
        true,
        false,
        true
    );

    const data = {
        appSave: useSaveRequest({
            request       : Application.finish,
            requestData   : {
                id                              : application_id,

                delivery_is_needed              : delivery.data.delivery_is_needed,
                ...( (! delivery.data.delivery_is_needed) ? {} : {
                    delivery_method_id              : delivery.data.delivery_method_id,
                    delivery_copies_count           : delivery.data.delivery_copies_count,
                    delivery_region_id              : delivery.data.delivery_region_id,
                    delivery_town_id                : delivery.data.delivery_town_id,
                    delivery_price_calculated       : delivery.data.delivery_price_calculated,
                    delivery_print_price_calculated : delivery.data.delivery_print_price_calculated,
                    delivery_time_calculated        : delivery.data.delivery_time_calculated,
                    delivery_address_is_from_payer  : delivery.data.delivery_address_is_from_payer,
                    delivery_address                : delivery.data.delivery_address,
                } ),

                contact_name                    : contact_name,
                contact_phone                   : contact_phone,
                contact_email                   : contact_email,

                promocode                       : promo,
                bonuses_to_use                  : useBonuses ? bonuses : 0,
                wishes                          : wishes,
            },
            onSuccess     : () => {
                ym('reachGoal', 'send1');
                ReactGA.ga('send', 'event', 'send1', 'submit');

                dispatch(setActiveStep(4));

                history.push(PATHS.orderNewSt6Success);
            }
        }),

        user: useLoadRequest({
            request       : User.getCurrent,
            onSuccess     : ({data}) => setValues((prev) => { return {
                ...prev,
                contact_name  : data.surname + ' ' + data.name + ' ' + data.middlename,
                contact_phone : data.phone,
                contact_email : data.email,
                bonuses       : 0, //data.bonuses_count
            }; })
        }),

        checkPromo: useDataRequest({
            request     : Promocode.isExist,
            requestData : { name: values.promo },
            onSuccess   : () => setWrongPromo(false),
            onFail      : () => setWrongPromo(true ),
            doNow       : false,
        })
    }

    const validator = useValidation({
        contact_email : (value) => isValidEmailCheck(value) || !value,
        contact_phone : (value) => isValidPhoneCheck(value) || !value,
        is_agree      : [ (value) => value === true, false ],
    });


    useEffect(() => {
        dispatch(setActiveStep(3));
    }, []);


    useEffect(() => {
        if (useBonuses && (values.bonuses === 0))
            setValues((state) => ({ ...state, bonuses: (bonusesStatus.bonuses_count ?? 0) }) );
    }, [useBonuses]);


    const onChange = (e) => {
        let { value, name, type, checked } = e.target;
        if (type === 'checkbox') value = checked;

        validator.check(name, value);

        if (name === 'bonuses' && value.match(/^[0-9]*$/)) {
            if (value > bonusesStatus?.bonuses_count) {
                setValues((state) => ({ ...state, [name]: +bonusesStatus?.bonuses_count }) );
            } else {
                setValues((state) => ({ ...state, [name]: +value }) );
            }
        } else {
            setValues((state) => ({ ...state, [name]: value }) );
        }
    };



    // promo
    const isValidPromo = (wrongPromo === null) || isEmptyString(values.promo) || (! wrongPromo);

    const checkPromo = () => {
        if (isEmptyString(values.promo)) {
            setWrongPromo(null);
        } else {
            data.checkPromo.doRequest();
        }
    }

    // flags
    const isLoading              = data.user.isLoading || delivery.isLoading;
    const isActing               = isLoading || data.appSave.isSaving || data.checkPromo.isRequesting;
    const isDisabledSubmitButton = !isValidPromo || isActing || !validator.isValid() || !delivery.isValid();

    // actions
    const onClickSend = () => {
        if (! isDisabledSubmitButton) data.appSave.do();
    };

    const goToRequestCategories = () => history.push(
        getPath( PATHS.orderNewSt4Form, {payer_id, category_id, form_id, application_id})
    );


    return <>
        <Title>{T.filterNames.delivery}</Title>

        {delivery.form(isLoading)}

        {!isLoading && <>
            <Subtitle2>{T.deliveryContact}</Subtitle2>
            <FloatingInput
                placeholder  = {T.deliveryContactName}
                value        = {contact_name}
                name         = "contact_name"
                onChange     = {onChange}
            />
            <FloatingInput
                placeholder  = {T.deliveryContactPhone}
                value        = {contact_phone}
                name         = "contact_phone"
                onChange     = {onChange}
                wrong        = {! validator.isValid('contact_phone')}
                wrongMessage = {T.inCorrectPhone}
            />
            <FloatingInput
                placeholder  = {T.deliveryContactEmail}
                value        = {contact_email}
                name         = "contact_email"
                onChange     = {onChange}
                wrong        = {! validator.isValid('contact_email')}
                wrongMessage = {T.inCorrectEmail}
            />

            {bonusesStatus?.is_bonus_program_member && <div style={{display: "flex"}}>
                <Subtitle2>{T.deliveryBonuses}</Subtitle2>
                <Radio
                    disabled   = {bonusesStatus?.bonuses_count === 0}
                    radios     = {T.bonuses}
                    nameRadio  = "useBonuses"
                    setValue   = {(value) => setUseBonuses(value === 'true')}
                    radioValue = {useBonuses ? 'true' : 'false'}
                    margin     = "20px 0 0"
                />
                {useBonuses && <FloatingInput
                    // style={{margin: 0}}
                    placeholder = {"Сумма бонусов"}
                    value       = {values.bonuses}
                    name        = "bonuses"
                    onChange    = {onChange}
                />}
            </div>}

            <Subtitle2>{T.youHavePromo}</Subtitle2>
            <Row style={{ display: 'flex', alignItems: 'center'}}>
                <Col col="auto">
                    <FloatingInput
                        placeholder  = {T.promo}
                        value        = {promo}
                        name         = "promo"
                        wrong        = {wrongPromo}
                        wrongMessage = "Неверно введен промокод"
                        onChange     = {onChange}
                        onBlur       = {checkPromo}
                    />
                </Col>
                <Col col="auto" style={{paddingTop: '10px'}}>
                    {data.checkPromo.isRequesting ? <WaitSpinner /> : Check(wrongPromo)}
                </Col>
            </Row>

            <Subtitle2>{T.yourWishes}</Subtitle2>
            <FloatingInput
                isTextarea
                value    = {wishes}
                name     = "wishes"
                onChange = {onChange}
                width    = "500px"
            />

            <Subtitle2>{T.overall}</Subtitle2>

            <CheckBoxLabel
                style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
            >
                <Checkbox
                    name     = "is_agree"
                    checked  = {is_agree}
                    onChange = {onChange}
                />
                <span style={{ marginLeft: 13, fontSize: 12, lineHeight: '200%' }}>
                Нажимая кнопку «Отправить» я соглашаюсь с обработкой моих персональных
                данных и подтверждаю осведомленность о недопустимости размещения в
                личном кабинете сервиса «Фабрика Расчетов» документов, сведений или
                информации, относящихся к государственной, коммерческой, служебной и
                (или) иной охраняемой законом тайне, а также персональных данных
                других физических лиц в соответствии с Федеральным законом «О
                персональных данных» от 27.07.2006 № 152-ФЗ.
            </span>
            </CheckBoxLabel>

        </>}

        <Button disabled={isDisabledSubmitButton} onClick={onClickSend}>
            {data.appSave.isSaving ? <WaitSpinner /> : T.send}
        </Button>

        {(! data.appSave.isSaving) && <SecondOrderButton marginLeft onClick={goToRequestCategories}>
            {T.back}
        </SecondOrderButton>}
    </>

};

export default OrderNewSt5Delivery;
