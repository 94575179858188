// prettier-ignore-start
import ActionCommentChatPrivate from "./Base/ActionCommentChatPrivate";
import ActionMessageForm        from "./Base/Forms/ActionMessageForm";
import ActionFormType           from "./ActionFormType";


const ActionMessageFormCommentChatPrivate = () => {

    const data     = ActionCommentChatPrivate().data;
    const formCols = ActionCommentChatPrivate().formCols;

    const form = (props) => ActionMessageForm().form(props, formCols);

    return {
        type    : ActionFormType.popupForm,

        data     : data,
        formCols : formCols,
        form     : form,
    }

};

export default ActionMessageFormCommentChatPrivate;
