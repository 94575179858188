// prettier-ignore-start
import React from "react";

import Stack          from "../../components/UI-kit/layout/Stack";
import Title          from "../../components/UI-kit/Title";
import ButtonGroup    from "../../components/UI-kit/Buttons/ButtonGroup";
import BackButton     from "./BackButton";
import { isNotEmpty } from "../../functions/common";


const BackTitleButton = ({title = '', backTo = '' }) =>
    <Stack style={{margin: "30px 0 15px"}}>
        {isNotEmpty(backTo) && <ButtonGroup><BackButton buttonType={0} to={backTo} /></ButtonGroup>}
        <Title margin="0">{title}</Title>
    </Stack>

export default BackTitleButton;
