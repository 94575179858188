// prettier-ignore-start
import { Application } from "../../../../api/methods";

import LIT             from "../../../../components/Lists/ListItemTypes";
import FormElement          from "./FormElement";


const FormElementApp = ({
    canChange = true,
    title = '',
    name = '',
    value = '',
    text = '',
    id = 0,
}) => {

    const popupName = 'changePopup_' + name;

    const formElement = FormElement({
        popupName, canChange, title, name, value, text,
        action     : Application.update,
        actionData : { id },
        cols       : [{ name: name, title: title, type: LIT.textarea }],
    });

    return {
        openForm : formElement.openForm,
        text     : formElement.text,
        form     : formElement.form,
    }

};

export default FormElementApp;
