import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import questionnaireFunc from "../../../../../functions/questionnaireFunc";
import { getPreviewMode } from "../../../../../store/previewMode/selectors";
import { setUserAnswer } from "../../../../../store/userAnswers/actions";
import { getUserAnswers } from "../../../../../store/userAnswers/selectors";
import Button from "../../../../../components/UI-kit/Buttons/Button";
import FloatingInput from "../../../../../components/UI-kit/FloatingInput";
import InputButtonGroup from "../../../../../components/UI-kit/InputButtonGroup";
import { isEmptyListArray } from "../../../../../functions/common";
import hooks from "../../../../../functions/hooks";

const ComplexListPart = ({ element }) => {
  const { id, name } = element;
  const key = id;

  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);

  const userAnswers = useSelector(getUserAnswers);
  const isEmpty = hooks.useIsFieldEmpty(id);
  const userAnswer = userAnswers[key]?.answer;
  const hasUserAnswer = isEmptyListArray(userAnswer);
  const [newListElement, setNewListElement] = useState("");
//  const [isInputWarning, setIsInputWarning] = useState(false);
  const [saveData, setSaveData] = useState(false);

  const [list, setList] = useState([""]);

  useEffect(() => {
    saveAnswer();
  }, [list]);

  useEffect(() => {
    if (!hasUserAnswer) setList(userAnswer);
  }, [hasUserAnswer]);

  const saveAnswer = (save = false) => {
    const userAnswer = questionnaireFunc.getAnswerInfo(element, list);
    if (save) {
      dispatch(setUserAnswer(key, userAnswer, true));
    }
    dispatch(setUserAnswer(key, userAnswer, saveData));

    if (saveData) {
      setSaveData(false);
    }
  };

  const type = (e) => {
    const { name, value } = e.target;
    setList((list) => {
      const listCopy = [...list];
      listCopy[name] = value;
      return listCopy;
    });
    // const userAnswer = questionnaireFunc.getAnswerInfo(element, e.target.value);
    // dispatch(setUserAnswer(key, userAnswer, false));
  };

  const deleteListElement = (index) => {
    const buf = [...list];
    buf.splice(index, 1);
    setList(buf);
    setSaveData(true);
  };

  const addAnswer = () => {
    setList([...list, ""]);
    clearNewListElement();
  };

//  const setInputWarning = () => setIsInputWarning(true);

  const clearNewListElement = () => setNewListElement("");

//  const typeNewElement = (e) => setNewListElement(e.target.value);

  if (!isPreviewMode) return null;

  return (
    <>
      {list.map((answer, index) => (
        <InputButtonGroup key={index}>
          <FloatingInput
            onChange={(e) => type(e)}
            onBlur={() => saveAnswer(true)}
            value={answer}
            name={index}
          ></FloatingInput>
          <Button
            isDanger
            onClick={() => deleteListElement(index)}
            margin={"10px 0"}
          >
            Удалить
          </Button>
        </InputButtonGroup>
      ))}
      <InputButtonGroup>
        <Button onClick={addAnswer} margin={"10px 0"} empty={isEmpty}>
          Добавить ответ
        </Button>
      </InputButtonGroup>
    </>
  );
};

export default ComplexListPart;
