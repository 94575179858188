// prettier-ignore-start
import React, { useEffect, useState } from "react";

import { Application } from "../../../../api/methods";
import ApplicationInfo from "./ApplicationInfo";
import { Preloader }   from "../../../../components/UI-kit/Preloader/Preloader";

type Props = {
  id: any;
};


const OrderObject: React.FC<Props> = ({ id }) => {

  const [application, setApplication] = useState(null);

  useEffect(() => {
    Application.getAnswer({ id }, ({ data }: any) => setApplication(data));
  }, []);

  return (application === null)
      ? <Preloader />
      : <ApplicationInfo application={application}></ApplicationInfo>;

};

export default OrderObject;
