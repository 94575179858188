// prettier-ignore-start
import React from "react";

import styled                               from "styled-components";
import { useParams }                        from "react-router-dom";
import BackTitleButton                      from "../../Orders/BackTitleButton";
import { Company, getPath, Template }       from "../../../api/methods";
import { useLoadRequest, useUpdateRequest } from "../../../hooks/useDataRequest";
import { PATHS }                            from "../../../constants/RoutesPaths";
import { isNotEmpty }                       from "../../../functions/common";
import { WaitSpinner }                      from "../../../components/UI-kit/Preloader/WaitSpinner";
import Button                               from "../../../components/UI-kit/Buttons/Button";
import Title                                from "../../../components/UI-kit/Title";


const WrapperRow = styled.div`
  display        : flex;
  width          : 100%;
  align-self     : stretch;
  align-items    : flex-start;
  justify-content: stretch;
  flex-direction : row;
`;
/*
  border         : 1px solid blue;
  margin         : 5px;

  border         : 1px solid red;
  margin         : 5px;
*/

const WrapperColumn = styled.div`
  display        : flex;
  width          : 100%;
  align-self     : stretch;
  align-items    : flex-start;
  justify-content: stretch;
  flex-direction : column;
`;


const SettingsCompanyResultTemplates = () => {

    const { company_id } = useParams();

    const companyTemplates = useLoadRequest({
        request    : Template.listCompanyResult,
        requestData: { company_id },
    })

    const company = useLoadRequest({
        request    : Company.get,
        requestData: {id: company_id},
    })

    const result = useUpdateRequest({
        request    : Template.updateCompanyResult,
        requestData: { company_id },
        onSuccess  : () => companyTemplates.reload()
    })

    const priority = {
        10: { name: 'форма компании' , path: (category_id, form_id) => getPath(PATHS.settings.companyFormTemplates    , { company_id, form_id     }) },
        20: { name: 'катег. компании', path: (category_id, form_id) => getPath(PATHS.settings.companyCategoryTemplates, { company_id, category_id }) },
        30: { name: 'компания'       , path: (category_id, form_id) => getPath(PATHS.settings.companyTemplates        , { company_id  }) },
        40: { name: 'форма'          , path: (category_id, form_id) => getPath(PATHS.settings.categoryFormTemplates   , { form_id     }) },
        50: { name: 'категория'      , path: (category_id, form_id) => getPath(PATHS.settings.categoryTemplates       , { category_id }) },
        60: { name: 'система'        , path: (category_id, form_id) => getPath(PATHS.settings.common                  , {             }) },
    };


    const outElement = (key, id, parent_id, priority_name, priority_path, data = null, file = null, margin = 0, style = {}) => (
        <WrapperRow key={key} style={{borderBottom: '1px solid lightgrey', borderRadius: "5px", padding: '3px', ...style}}>
            <WrapperColumn>
                <div style={{marginLeft: (margin+15) + "px"}}>
                    {data}
                </div>
            </WrapperColumn>
            <WrapperColumn>
                {file}
            </WrapperColumn>
            <WrapperColumn style={{width: "200px", textAlign: 'center'}}>
                <div style={{marginLeft: Math.round((margin+15)/3) + "px"}}>
                    {parent_id}
                </div>
            </WrapperColumn>
            <WrapperColumn style={{width: "200px", textAlign: 'center'}}>
                {id && <>[ {id} ]</>}
            </WrapperColumn>
            <WrapperColumn style={{width: "420px", textAlign: 'left'}}>
                {isNotEmpty(priority_name) && <a href={priority_path}>{priority_name}</a>}
            </WrapperColumn>
        </WrapperRow>
    );

    let current_category_id = -1;
    let current_form_id     = -1;
    let current_type_id     = -1;

    const outTitle = () => outElement('title_key', 'id', 'p_id', null, null,'Категория / Форма / Тип', 'Шаблон (файл)', 0, {backgroundColor: 'lightgray', fontWeight: 'bold', padding: '8px 3px 8px 3px'});

    const outItem = (item) => {
        const {
            id,
            category_id,
            category_title,

            form_id,
            form_title,

            template_id,
            template_title,

            type_id,
            type_title,

            format_title,
            file_name,

            priority_id,
        } = item;

        let category = null;
        if (category_id !== current_category_id) {
            current_category_id = category_id;
            current_form_id     = -1
            current_type_id     = -1;
            category = outElement( id+'c', null, category_id, null, null, <strong>{category_title}</strong>, null, 0, {backgroundColor: '#F5F5F5'});
        }

        let form = null;
        if (form_id !== current_form_id) {
            current_form_id = form_id;
            current_type_id = -1;
            form = outElement( id+'f', null, form_id, null, null, form_title, null, 32);
        }

        let type = null;
        if (type_id !== current_type_id) {
            current_type_id = type_id;
            type = outElement( id+'t', null, type_id, null, null, type_title, null, 64);
        }

        const template = outElement( id, id, template_id, priority[ priority_id]?.name ?? null, priority[ priority_id]?.path(category_id, form_id) ?? null, format_title, <i>{template_title} ({file_name})</i>, 96);

        return <>{category}{form}{type}{template}</>;
    }

    const outList = companyTemplates.hasFails
        ? '[ ! ] Сбой загрузки списка шаблонов'
        : ( (companyTemplates.data.length > 0)
            ? <>
                {outTitle()}
                {companyTemplates.data?.map( outItem )}
            </>
            : 'Список пуст'
        );

    return <>
        <BackTitleButton title={company.data?.name ?? ''} backTo={PATHS.settings.company}/>

        <Title>Свод шаблонов для форм и категорий</Title>

        <Button
            onClick  = { () => result.update() }
            disabled = {result.isUpdating}
        >
            {result.isUpdating
                ? <WaitSpinner />
                : "Обновить"
            }
        </Button><br/><br/>
        {companyTemplates.isLoading
            ? <WaitSpinner/>
            : ((companyTemplates.isLoaded && (! result.isUpdating)) && outList)
        }
    </>

};

export default SettingsCompanyResultTemplates;
