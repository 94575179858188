// prettier-ignore-start
import React from "react";

import {isNotEmpty} from "../../../functions/common";


const ListFormat_Color = (
    color = '',
) => isNotEmpty(color)
    ? <div
        style = {{
            backgroundColor: '#'+color,
            border         : '1px solid #CCCCCC',
            borderRadius   : '5px',
            minWidth       : 25,
            minHeight      : 25,
        }}
    ></div>
    : '-';

export default ListFormat_Color;
