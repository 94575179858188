// prettier-ignore-start
import React from "react";

import settingsTabType              from "../tabType";
import SettingsListsDeliveryMethods from "./SettingsListsDeliveryMethods";
import SettingsListsDeliveryRegions from "./SettingsListsDeliveryRegions";
import SettingsListsDeliveryTowns   from "./SettingsListsDeliveryTowns";


const SettingsListsDeliveries = ({ sub = settingsTabType.deliveryMethods }) => {

    if (sub === settingsTabType.deliveryRegions ) return <SettingsListsDeliveryRegions />
    if (sub === settingsTabType.deliveryTowns   ) return <SettingsListsDeliveryTowns   />

    return <SettingsListsDeliveryMethods />

};

export default SettingsListsDeliveries;
