// prettier-ignore-start
import React from "react";

import { useHistory }    from "react-router-dom";
import styles            from "./OrderNewSt6Success.scss"
import { T }             from "../../../lang/ru";

import { PATHS }         from "../../../constants/RoutesPaths";
import { isProPBSystem } from "../../../constants/SystemType";
import { NewButton }     from "../../../components/UI-kit/Buttons/NewButton/NewButton";
import Subtitle          from "../../../components/UI-kit/Subtitle";
import Text              from "../../../components/UI-kit/Text";
import Title             from "../../../components/UI-kit/Title";


const OrderNewSt6Success = () => {
    const history = useHistory()

    const resultTitile = isProPBSystem ? 'проект' : 'расчет';

    const goToMain = () => history.push(PATHS.default)

    return <div style={{textAlign: 'center'}}><center>
        <Title className={styles.title}>Спасибо, Ваш заказ принят!</Title>

        <Text className={styles.text} style={{textAlign: 'center'}}>
            Видеть ход работ и скачать готовый {resultTitile} можно будет в личном кабинете
        </Text>

        <div className={styles.backButtons}>
            <NewButton onClick={goToMain} type="fill">Перейти в личный кабинет</NewButton>
        </div>

        <div className={styles.techicalSupportBlock}>

            <Subtitle className={styles.subtitle}> Техническая поддержка</Subtitle>
            <div className={styles.buttonRow}>
                <a href={"tel:" + T.phoneNumber.trim()} className={styles.noUnderline}>
                    <NewButton style={{ width: 280, height: 83 }} type="fill">
                        <div>
                            <a className={styles.href}>{T.phoneNumber}</a>
                            <span className={styles.freeCall}>Бесплатный звонок по всей России</span>
                        </div>
                    </NewButton>
                </a>
                <a href={"mailto:" + T.emailAddress} className={styles.noUnderline}>
                    <NewButton style={{ width: 280, height: 83 }} type="fill">
                        <div>
                            <a className={styles.href}>{T.emailAddress}</a>
                        </div>
                    </NewButton>
                </a>
            </div>
            <div className={styles.time}>Понедельник – Воскресенье с 6 до 21 (время московское)</div>
        </div>

    </center></div>
}

export default OrderNewSt6Success;