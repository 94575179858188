import T from "./types";

const initialState = 1;

export default function activeStep(state = initialState, action) {
  switch (action.type) {
    case T.SET_ACTIVE_STEP:
      return action.activeStep;
    default:
      return state;
  }
}
