import { AnyAction } from "redux";
import { bonuses, bonusesStatus } from "../../models/bonuses.model";
import { cloneDeep } from "lodash";
import types from "./types";


export default function bonusesSlice(state: bonuses = {}, action: AnyAction) {
    const stateCopy = cloneDeep(state)
    switch (action.type) {
        case types.SET_STATUS:
            stateCopy.status = action.payload
            return stateCopy
        default: 
            return stateCopy
    }
}