// prettier-ignore-start
import React from "react";

import { Switch, Route }       from "react-router-dom";
import styled                  from "styled-components";

import { useSupportedBrowser } from "../hooks/useSupportedBrowser";

import { device }              from "../constants/Breakpoints";
import ScrollTop               from "../components/ScrollTop";
import { PATHS }               from "../constants/RoutesPaths";

import Login                   from "./Login";
import NotFound                from "./NotFound";
import Register                from "./Register";
import RegisterSuccess         from "./RegisterSuccess";
import Agreement               from "./Agreement";
import NotSupportedBrowserPage from "./NotSupportedBrowserPage";
import ForgotPassword          from "./ForgotPassword";
import ResetPassword           from "./ResetPassword";

import Settings                from "./Settings/Settings";
import settingsTabType         from "./Settings/tabType";
import SettingsCompanyForm     from "./Settings/Company/SettingsCompanyForm";
import orderTabType            from "./Orders/orderTabType";
import adminPanelTabType       from "./Orders/List/AdminPanel/OrdersListAdminPanelTabType";
import Orders                  from "./Orders/List/Orders";
import Order                   from "./Orders/Order";
import OrderDelivery           from "./Orders/Info/Delivery/OrderDelivery";
import OrderNewSt6Success      from "./Orders/New/OrderNewSt6Success";
import OrderNewSt1SetPayer     from "./Orders/New/OrderNewSt1SetPayer";
import OrderNewSt1SetPayerNew  from "./Orders/New/OrderNewSt1SetPayerNew";
import OrderNewSt2SetCategory  from "./Orders/New/OrderNewSt2SetCategory";
import OrderNewSt3SetForm      from "./Orders/New/OrderNewSt3SetForm";
import orderNewSt4Form         from "./Orders/New/OrderNewSt4Form";
import orderNewSt5Delivery     from "./Orders/New/OrderNewSt5Delivery";
import OrderEditForm           from "./Orders/Edit/OrderEditForm";
import Editor                  from "./Forms/Editor/FormsList/Editor";
import EditorPreview           from "./Forms/Editor/EditorPreview";
import EditorFormsList         from "./Forms/Editor/FormsList/EditorFormsList";
import PayerInfo               from "./Payer/PayerInfo";
import Bonuses                 from "./Bonuses/Bonuses";
import OrderExecutorDistributionUsers from "./Orders/Info/OrderExecutorDistributionUsers";
import SettingsCompanyTemplates from "./Settings/Company/SettingsCompanyTemplates";
import SettingsCompanyForms from "./Settings/Company/SettingsCompanyForms";
import SettingsCompanyCategoryTemplates from "./Settings/Company/SettingsCompanyCategoryTemplates";
import SettingsCompanyFormTemplates from "./Settings/Company/SettingsCompanyFormTemplates";
import SettingsCategoryTemplatesList from "./Settings/Categories/SettingsCategoryTemplatesList";
import SettingsCompanyResultTemplates from "./Settings/Company/SettingsCompanyResultTemplates";
import SettingsCategoryFormsList from "./Settings/Categories/SettingsCategoryFormsList";
import SettingsCategoryFormTemplatesList from "./Settings/Categories/SettingsCategoryFormTemplatesList";


const MainDiv = styled.main`
    width: 100%;
    @media ${device.tablet} {
        overflow-x: hidden;
        margin-top: 45px;
    }
`;

const authConfig: any = [
    { path: PATHS.forgotPassword                       , component: ForgotPassword          },
    { path: PATHS.resetPassword                        , component: ResetPassword           },
    { path: PATHS.login                                , component: Login                   },
    { path: PATHS.register                             , component: Register                },
    { path: PATHS.agreement                            , component: Agreement               },
    { path: PATHS.registerSuccess                      , component: RegisterSuccess         },
];

const commonConfig: any = [
    { path: PATHS.default                              , component: Orders                  },
    { path: PATHS.orders                               , component: Orders                  , componentProps: {tabs:[ adminPanelTabType.ordersList ]} },
    { path: PATHS.users                                , component: Orders                  , componentProps: {tabs:[ adminPanelTabType.usersList, adminPanelTabType.usersList         ]} },
    { path: PATHS.userCategories                       , component: Orders                  , componentProps: {tabs:[ adminPanelTabType.usersList, adminPanelTabType.userCategories    ]} },
    { path: PATHS.userCategoryForms                    , component: Orders                  , componentProps: {tabs:[ adminPanelTabType.usersList, adminPanelTabType.userCategoryForms ]} },
    { path: PATHS.userBonuses                          , component: Orders                  , componentProps: {tabs:[ adminPanelTabType.usersList, adminPanelTabType.userBonuses       ]} },

    { path: PATHS.bonuses                              , component: Bonuses                 },

    { path: PATHS.formEditSetCategory                  , component: EditorFormsList         },
    { path: PATHS.formEdit                             , component: Editor                  },
    { path: PATHS.formEditPreview                      , component: EditorPreview           }, //Questionnaire

    { path: PATHS.orderEdit                            , component: OrderEditForm           },

    { path: PATHS.orderNewSt6Success                   , component: OrderNewSt6Success      }, // спасибки здесь, иначе путь ловит другой компонент))
    { path: PATHS.orderNewSt1SetPayer                  , component: OrderNewSt1SetPayer     },
    { path: PATHS.orderNewSt1SetPayerNew               , component: OrderNewSt1SetPayerNew  },
    { path: PATHS.orderNewSt2SetCategory               , component: OrderNewSt2SetCategory  },
    { path: PATHS.orderNewSt3SetForm                   , component: OrderNewSt3SetForm      },
    { path: PATHS.orderNewSt4Form                      , component: orderNewSt4Form         },
    { path: PATHS.orderNewSt5Delivery                  , component: orderNewSt5Delivery     },

    { path: PATHS.orderInfo                            , component: Order                   , componentProps: {tabs:[ orderTabType.orderInfo       ]} },
    { path: PATHS.orderInfoDistributionExecutor        , component: OrderExecutorDistributionUsers                                                   },
    { path: PATHS.orderChat                            , component: Order                   , componentProps: {tabs:[ orderTabType.orderChat       ]} },
    { path: PATHS.orderForm                            , component: Order                   , componentProps: {tabs:[ orderTabType.orderForm       ]} },
    { path: PATHS.orderHistory                         , component: Order                   , componentProps: {tabs:[ orderTabType.orderHistory    ]} },
    { path: PATHS.orderDelivery                        , component: OrderDelivery           },
    { path: PATHS.orderPayerInfo                       , component: PayerInfo               },

    { path: PATHS.payerInfo                            , component: PayerInfo               },

    { path: PATHS.settings.default                     , component: Settings                                                                                                                                             },
    { path: PATHS.settings.categories                  , component: Settings                         , componentProps: {tabs:[ settingsTabType.categories                                                            ] } },
    { path: PATHS.settings.categoryTemplates           , component: SettingsCategoryTemplatesList    , componentProps: {                                                                                               } },
    { path: PATHS.settings.categoryForms               , component: SettingsCategoryFormsList        , componentProps: {                                                                                               } },
    { path: PATHS.settings.categoryFormTemplates       , component: SettingsCategoryFormTemplatesList, componentProps: {                                                                                               } },

    { path: PATHS.settings.company                     , component: Settings                         , componentProps: {tabs:[ settingsTabType.company, settingsTabType.companyList                                  ] } },
    { path: PATHS.settings.companyNew                  , component: SettingsCompanyForm              , componentProps: {mode : 'new'                                                                                   } },
    { path: PATHS.settings.companyEdit                 , component: SettingsCompanyForm              , componentProps: {mode : 'edit'                                                                                  } },
    { path: PATHS.settings.companyTemplates            , component: SettingsCompanyTemplates         , componentProps: {                                                                                               } },
    { path: PATHS.settings.companyForms                , component: SettingsCompanyForms             , componentProps: {                                                                                               } },
    { path: PATHS.settings.companyFormTemplates        , component: SettingsCompanyFormTemplates     , componentProps: {                                                                                               } },
    { path: PATHS.settings.companyCategoryTemplates    , component: SettingsCompanyCategoryTemplates , componentProps: {                                                                                               } },
    { path: PATHS.settings.companyResultTemplates      , component: SettingsCompanyResultTemplates   , componentProps: {                                                                                               } },

    { path: PATHS.settings.executorGroups                 , component: Settings, componentProps: {tabs:[ settingsTabType.executorGroups, settingsTabType.executorGroupsList                                          ] } },
    { path: PATHS.settings.executorGroupUsers             , component: Settings, componentProps: {tabs:[ settingsTabType.executorGroups, settingsTabType.executorGroupUsersList                                      ] } },
    { path: PATHS.settings.lists                          , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.promocodes                                                           ] } },
    { path: PATHS.settings.listsPromocodes                , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.promocodes                                                           ] } },
    { path: PATHS.settings.listsBonuses                   , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.bonuses                                                              ] } },
    { path: PATHS.settings.listsSources                   , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.sources                                                              ] } },
    { path: PATHS.settings.listsDeliveryMethods           , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.delivery, settingsTabType.deliveryMethods                            ] } },
    { path: PATHS.settings.listsDeliveryRegions           , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.delivery, settingsTabType.deliveryRegions                            ] } },
    { path: PATHS.settings.listsDeliveryTowns             , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.delivery, settingsTabType.deliveryTowns                              ] } },
    { path: PATHS.settings.listsSoftwareProducts          , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.softwareProducts                                                     ] } },
    { path: PATHS.settings.listsRatings                   , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.ratings                                                              ] } },
    { path: PATHS.settings.listsRatingRoles               , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.ratings, settingsTabType.ratingRoles                                 ] } },
    { path: PATHS.settings.listsRatingScores              , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.ratings, settingsTabType.ratingScores                                ] } },
    { path: PATHS.settings.listsRatingScoreValues         , component: Settings, componentProps: {tabs:[ settingsTabType.lists, settingsTabType.ratings, settingsTabType.ratingScoreValues                           ] } },
    { path: PATHS.settings.common                         , component: Settings, componentProps: {tabs:[ settingsTabType.common                                                                                      ] } },
    { path: PATHS.settings.system                         , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStructure                                                     ] } },
    { path: PATHS.settings.systemCommon                   , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemCommon                                                        ] } },
    { path: PATHS.settings.systemStructure                , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStructure                                                     ] } },

    { path: PATHS.settings.systemTemplateTypes            , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemTemplates   , settingsTabType.systemTemplateTypes             ] } },
    { path: PATHS.settings.systemTemplateTypeTemplates    , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemTemplates   , settingsTabType.systemTemplateTypeTemplates     ] } },
    { path: PATHS.settings.systemTemplateTypeTemplateFiles, component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemTemplates   , settingsTabType.systemTemplateTypeTemplateFiles ] } },
    { path: PATHS.settings.systemTemplateGenerators       , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemTemplates   , settingsTabType.systemTemplateGenerators        ] } },
    { path: PATHS.settings.systemTemplateFormats          , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemTemplates   , settingsTabType.systemTemplateFormats           ] } },

    { path: PATHS.settings.systemRoles                    , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemRoles                                                         ] } },
    { path: PATHS.settings.systemRoleStatuses             , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemRoles       , settingsTabType.systemRoleStatuses              ] } },
    { path: PATHS.settings.systemRoleStatusActions        , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemRoles       , settingsTabType.systemRoleStatusActions         ] } },
    { path: PATHS.settings.systemRoleStatusAccessRights   , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemRoles       , settingsTabType.systemRoleStatusAccessRights    ] } },
    { path: PATHS.settings.systemRoleAccessRights         , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemRoles       , settingsTabType.systemRoleAccessRights          ] } },
    { path: PATHS.settings.systemAccessRights             , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemAccessRights                                                  ] } },
    { path: PATHS.settings.systemAccessRightValues        , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemAccessRights, settingsTabType.systemAccessRightValues         ] } },
    { path: PATHS.settings.systemAccessRightTargets       , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemAccessRights, settingsTabType.systemAccessRightTargets        ] } },
    { path: PATHS.settings.systemStatuses                 , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStatuses                                                      ] } },
    { path: PATHS.settings.systemStatusAccessRights       , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStatuses    , settingsTabType.systemStatusAccessRights        ] } },
    { path: PATHS.settings.systemStatusRoles              , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStatuses    , settingsTabType.systemStatusRoles               ] } },
    { path: PATHS.settings.systemStatusMails              , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStatuses    , settingsTabType.systemStatusMails               ] } },
    { path: PATHS.settings.systemStatusRoleActions        , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStatuses    , settingsTabType.systemStatusRoleActions         ] } },
    { path: PATHS.settings.systemStatusRoleAccessRights   , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemStatuses    , settingsTabType.systemStatusRoleAccessRights    ] } },
    { path: PATHS.settings.systemActions                  , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemActions                                                       ] } },
    { path: PATHS.settings.systemActionMails              , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemActions     , settingsTabType.systemActionMails               ] } },
    { path: PATHS.settings.systemEmails                   , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemEmails                                                        ] } },
    { path: PATHS.settings.systemUsers                    , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemUsers                                                         ] } },
    { path: PATHS.settings.systemUserAccessRights         , component: Settings, componentProps: {tabs:[ settingsTabType.system, settingsTabType.systemUsers       , settingsTabType.systemUserAccessRights          ] } },
];


const Main = () => {

    const isSupportedBrowser = useSupportedBrowser();

    const config = [...commonConfig, ...authConfig];


    return <MainDiv>
        {(isSupportedBrowser)
            ? <ScrollTop>
                <Switch>
                    {config.map( ({ path, component, componentProps }: any, index: number) =>
                        componentProps
                            ? <Route
                                exact
                                key       = {index}
                                path      = {path}
                                render    = {() => React.createElement(component, {...componentProps})}
                            />
                            : <Route
                                exact
                                key       = {index}
                                path      = {path}
                                component = {component}
                            />
                    )}
                    <Route path="*" component={NotFound} />
                </Switch>
            </ScrollTop>

            : <Route
                path      = "*"
                component = {NotSupportedBrowserPage}
            />
        }
    </MainDiv>

};

export default Main;
