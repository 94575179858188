// prettier-ignore-start
import React, {useEffect, useState} from "react";
import { useHistory }    from "react-router-dom";

import {
    getPath,
    SystemRoles,
    User }                from "../../../api/methods";
import List               from "../../../components/Lists/List";
import LIF                from "../../../components/Lists/ListItemFormats";
import LIT                from "../../../components/Lists/ListItemTypes";
import { useInputSelect } from "../../../hooks/useInputSelect";
import { PATHS }          from "../../../constants/RoutesPaths";
import ListFormat_2Lines  from "../../../components/Lists/Parts/ListFormat_2Lines";


const SettingsSystemUsersList = () => {

    const history = useHistory();

    const [key, setKey] = useState(1);

    const role = useInputSelect({
        name        : 'systemUserRole',
        title       : 'роль',
        defaultValue: 0,
        getOptions  : (data, success, fail) => SystemRoles.select(
            {hasUnset: 1},
            (data) => { data.data.unshift({id: 0, name: '(все роли)'}); success(data); },
            fail
        ),
        width: 350
    });

    useEffect(() => {
        if (! role.isLoading && role.isLoaded) setKey((prev) => prev + 1);
    }, [role.value]);

    const gotoUserAccessRights = (user_id) => history.push(getPath(PATHS.settings.systemUserAccessRights, {user_id}));

    const fioTitle  = ListFormat_2Lines('фамилия имя отчество', 'email, телефон');
    const fioJoin   = (item) => [item['surname'], item['name'], item['middlename']].join(' ');

    const formatFio  = (id, item, itemProps) => ListFormat_2Lines(fioJoin(item), [item['email'], item['phone']].filter((val) => (val && val !== '')).join(', '), true);
    const formatRole = (id, item, itemProps) => (! item['role']) ? '' : ListFormat_2Lines(item['role']['title'], item['role']['sysname']);

    const optionsRole = (success) => SystemRoles.select({hasUnset: 1}, ({data}) => success(data), );


    return <List
        key         = {'systemUsers' + key}
        popupPrefix = {'systemUsers'}
        getListData = {(d, s, f) => {
            const id = (role.value === '0') ? 'all' : role.value;

            User.listUsersByRoleId( {id} , s, f)
        }}
        cols        = {[
            {name: 'id'        , title: 'id'      , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: false},
            {name: 'fio'       , title: fioTitle  , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false,                align: 'left', customFormatHandler: formatFio },
            {name: 'role'      , title: 'роль'    , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false,                align: 'left', customFormatHandler: formatRole },
            {name: 'surname'   , title: 'фамилия' , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'name'      , title: 'имя'     , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'middlename', title: 'отчество', format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'email'     , title: 'email'   , format: LIF.hidden  , type: LIT.email , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'phone'     , title: 'телефон' , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'role_id'   , title: 'роль'    , format: LIF.hidden  , type: LIT.select, canAddAndEdit: true , default: null, align: 'left', getOptions: optionsRole },

            {name: 'applications_executor_count', title: 'испл', format: LIF.usageCounter},
            {name: 'applications_expert_count'  , title: 'эксп', format: LIF.usageCounter},
            {name: 'applications_count'         , title: 'пльз', format: LIF.usageCounter},
            {name: 'payers_count'               , title: 'плат', format: LIF.usageCounter},
            {name: 'access_rights_count'        , title: 'прав', format: LIF.usageCounter},
        ]}

        buttons     =  {[
            //{type: 'new'},
            {type: 'group', buttons: [
                {type: 'custom', out: role.select},
            ]},
        ]}

        actions = {[
            {title: 'права', onClick: gotoUserAccessRights, canDo: () => true },
        ]}

        //onNew       = {User.add   }
        onEdit      = {User.update}
        //onDelete    = {User.delete}

        canDelete   = {(item) => ( (item['applications_executor_count']+item['applications_expert_count']+item['applications_count']+item['payers_count']+item['access_rights_count']) === 0 && false )}

//            textOnDelete = {(item) => <>Удалить пользователя <b>{fioJoin(item)}</b>?</>}
    />

};

export default SettingsSystemUsersList;
