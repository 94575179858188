// prettier-ignore-start
import { useState } from "react";

import { usePrevious } from "./usePrevious";


export function useFilter(data) {

    const prepareData = () => data.reduce( (result, filterName) => ({...result, [filterName]: { active: false, value: -1 }}), {} )

    const [filters, setFilters] = useState(prepareData());
    const prevFilters = usePrevious(filters);

    const onChangeCheckbox = (e) => {
        const { name, checked } = e.target;

        setFilters({
            ...filters,
            [name]: { active: checked, value: filters[name].value },
        });
    };

    const onChangeSelect = (e) => {
        const { value, name } = e.target;

        setFilters({
            ...filters,
            [name]: { ...filters[name], value: Number(value) },
        });
    };

    const getValue = (filterName) => {
        const { active, value } = filters[filterName];

        return active ? value : '';
    };

    const isNeedToUpdate = () => {
        let isNeedUpdate = false;

        if (!prevFilters) return true;

        Object.keys(filters).forEach((name) => {
            const filter     = filters    [name];
            const prevFilter = prevFilters[name];

            const { active, value } = filter;

            const prevActive = prevFilter.active;
            const prevValue  = prevFilter.value;

            isNeedUpdate = isNeedUpdate || active !== prevActive || (active && prevValue !== value);
        });

        return isNeedUpdate;
    };



    return {
        data             : filters,

        onChangeCheckbox : onChangeCheckbox,
        onChangeSelect   : onChangeSelect,

        getValue         : getValue,
        isNeedToUpdate   : isNeedToUpdate
    }
}
