// prettier-ignore-start
import {T} from "../../../../../lang/ru";
import {PATHS} from "../../../../../constants/RoutesPaths";
import {defaultResult} from "./Common";
import {isEmpty} from "../../../../../functions/common";
import {getPath} from "../../../../../api/methods";


export const Payer = () => {

    return {
        head : (rowData) => T.payer,

        body : (rowData) => {
            const {payer_name_org='', payer_name_ip='', payer_surname='', payer_name='', payer_middlename=''} = rowData;

            if (! isEmpty(payer_name_org)) return payer_name_org;
            if (! isEmpty(payer_name_ip )) return payer_name_ip;

            if (isEmpty(payer_surname) || isEmpty(payer_name) || isEmpty(payer_middlename)) return `Не указано`;

            return [payer_surname, payer_name, payer_middlename].join(' ');
        },

        bodyProps : (rowData) => ({
            ...defaultResult.bodyProps(rowData),

            to     : getPath(PATHS.payerInfo, {payer_id: rowData.payer_id ?? 0}),
        })
    };

};