// prettier-ignore-start
import React from "react";

import CommonMessageConfirmForm from "./Base/Forms/CommonMessageConfirmForm";
import ActionFormType           from "./ActionFormType";


const ActionMessageConfirm = () => {

    return {
        type    : ActionFormType.popupForm,

        data    : () => ({}),
        form    : (props = {
            popupName : '',
            title     : '',
            reload    : () => {},
            run       : () => {},
            close     : () => {},
        }) => <CommonMessageConfirmForm
            {...props}
        />,
    }

};

export default ActionMessageConfirm;
