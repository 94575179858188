import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from '../../../../../api/methods';
import { green, lightgray } from '../../../../../constants/Colours';
import { goToHref } from '../../../../../functions/common/common';
import { pasteHref } from '../../../../../store/elements/actions';
import { getPreviewMode } from '../../../../../store/previewMode/selectors';
import SecondOrderButton from '../../../../../components/UI-kit/Buttons/SecondOrderButton';
import { SmallButton } from '../../../../../components/UI-kit/Buttons/SmallButton';
import MiniSelect from '../../../../../components/UI-kit/MiniSelect';
import { SmallInput } from '../../../../../components/UI-kit/SmallInput';
import ElementTopBar from './ElementTopBar';
import TopBarItem from './TopBarItem';
import {ifEmpty, isEmpty} from "../../../../../functions/common";

const types = {
  1: 'Поле для ввода',
  2: 'Один из списка',
  3: 'Несколько из списка',
  4: 'Вопрос с прикреплением файла',
  5: 'Группа вопросов',
  6: 'Важная информация',
  7: 'Поле для ввода списка',
  8: 'Список с повторяющимися вопросами',
  9: 'Элемент с супер-вложенностями',
};

const TopBar = ({
  element,
  type,
  deleteElement,
  isLast,
  isFirst,
  swapNext,
  makeSystemElement,
  swapPrevious,
  toggleFavorite,
  toggleRequired,
  deleteFromFavorite,
  systemNames,
  isElementFavorite,
}) => {
  const { href, href_name, required, system_name_id, button_name } = element;

  const initialHref     = ifEmpty(href     , '');
  const initialHrefName = ifEmpty(href_name, '');

  const isHrefEmpty     = isEmpty(href);


  const [newHref    , setNewHref    ] = useState(initialHref    );
  const [newHrefName, setNewHrefName] = useState(initialHrefName);

  const dispatch      = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);

  const [buttonName, setButtonName] = useState(button_name);

  const updateButtonName = () => {
    const { id } = element;
    Element.update({ id, button_name: buttonName });
  };

  const changeButtonName = (text) => {
    setButtonName(text);
  };

  const saveHref = () => {
    const { id, tip, name, required } = element;
    Element.update(
      { id, tip, name, href: newHref, href_name: newHrefName, required },
      () => {
        dispatch(pasteHref(element.id, newHref));
      }
    );
  };

  const removeHref = () => {
    const { id, tip, name, required } = element;
    Element.update({ id, tip, name, href: '', href_name: '', required }, () => {
      setNewHref('');
      setNewHrefName('');
      dispatch(pasteHref(element.id, ''));
    });
  };

  const changeHref = (e) => setNewHref(e.target.value);
  const changeHrefName = (e) => setNewHrefName(e.target.value);

  if (isPreviewMode) return null;

  const isFirstLevel = element.level === 1;

  const approveAction = (action, text) => {
    var answer = window.confirm(text);
    if (answer) action();
  };

  const deleteWithApprovement = () =>
    approveAction(deleteElement, 'Вы действительно хотите удалить элемент?');

  const requiredText = required === 0 ? 'Необязательное' : 'Обязательное';

  const systemText = system_name_id ? 'Системное' : 'Не системное';

  return (
    <>
      <ElementTopBar>
        {isFirst ? null : <TopBarItem onClick={swapPrevious}>Вверх</TopBarItem>}
        {isLast ? null : <TopBarItem onClick={swapNext}>Вниз</TopBarItem>}
        <TopBarItem>{types[type]}</TopBarItem>
        {isFirstLevel ? (
          <TopBarItem
            onClick={isElementFavorite ? deleteFromFavorite : toggleFavorite}
          >
            {isElementFavorite
              ? 'Убрать из избранного'
              : 'Добавить в избранное'}
          </TopBarItem>
        ) : null}

        {type === 9 || type === 6 || type === 5 ? null : (
          <TopBarItem onClick={toggleRequired}>{requiredText}</TopBarItem>
        )}

        <TopBarItem>
          <MiniSelect
            onChange={(e) => makeSystemElement(e.target.value)}
            value={system_name_id}
            title="Не системное"
            options={systemNames}
            style={{ width: 'fit-content' }}
            defaultValue={0}
          />
        </TopBarItem>
        <TopBarItem onClick={deleteWithApprovement}>Удалить элемент</TopBarItem>
      </ElementTopBar>

      {isHrefEmpty ? (
        <TopBarItem style={{ marginTop: 10, marginBottom: 10 }}>
          <SmallButton value="прикрепить" onClick={saveHref}>
            Прикрепить
          </SmallButton>
          <SmallInput
            placeholder = "Ссылка"
            value       = {newHref}
            onChange    = {changeHref}
          />
          <SmallInput
            placeholder = "Название ссылки"
            value       = {newHrefName}
            onChange    = {changeHrefName}
          />
        </TopBarItem>
      ) : (
        <>
          <TopBarItem>
            <SecondOrderButton
              style   = {{ padding: 0, marginRight: 10 }}
              onClick = {() => goToHref(element.href)}
            >
              {newHrefName === '' ? 'Дополнительные материалы' : newHrefName}
            </SecondOrderButton>

            {isPreviewMode ? null : (
              <SecondOrderButton style={{ padding: 0 }} onClick={removeHref}>
                Удалить ссылку
              </SecondOrderButton>
            )}
          </TopBarItem>
        </>
      )}
      {element.type === 9 ? (
        <TopBarItem style={{ marginTop: 10, marginBottom: 10 }}>
          <SmallInput
            style={{
              width      : 400,
              borderColor: buttonName ? green : lightgray,
            }}
            placeholder = "Название кнопки добавления"
            value       = {buttonName}
            onChange    = {(e) => changeButtonName(e.target.value)}
            onBlur      = {updateButtonName}
          />
        </TopBarItem>
      ) : null}
    </>
  );
};

export default TopBar;
