import styled from "styled-components";
import { accent } from "../../../constants/Colours";

export const WaitSpinner = styled.div`
  padding: 1px !important;
  border: 0px !important;
  text-align: center !important;
  vertical-align: middle;
  display: block;
  width: 12px;
  height: 12px;
  :after {
    text-align: center !important;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid ${accent};
    border-color: ${accent} transparent ${accent} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
