// prettier-ignore-start
import React from "react";

import { useHistory } from "react-router-dom";

import { getPath, SystemAccessRights } from "../../../api/methods";
import { PATHS }                       from "../../../constants/RoutesPaths";
import List                            from "../../../components/Lists/List";
import LIF                             from "../../../components/Lists/ListItemFormats";
import LIT                             from "../../../components/Lists/ListItemTypes";

const target_type_names = {
    'App\\Models\\User'                  : 'пользователь',
    'App\\Models\\Role'                  : 'роль',
    'App\\Models\\ApplicationStatus'     : 'статус',
    'App\\Models\\ApplicationRoleStatus' : 'статус на роли'
};


const SettingsSystemAccessRightsList = () => {

    const history = useHistory();

    const gotoAccessRightValues  = (id) => history.push(getPath(PATHS.settings.systemAccessRightValues , {access_right_id: id}));
    const gotoAccessRightTargets = (id) => history.push(getPath(PATHS.settings.systemAccessRightTargets, {access_right_id: id}));

    const formatDefaultValue = (id, item, itemProps) => (! item?.default_value_id) ? '-' : (item?.default_value?.name ?? '') +' ('+ (item?.default_value?.sysname ?? '') + ')';
    const formatValuesList   = (id, item, itemProps) => ((item?.values_active  ?? []).length === 0) ? '-' : item.values_active.map (({id, sysname    }) => (id === item.default_value_id) ? '['+sysname+']' : sysname ).join(', ');
    const formatTargetsList  = (id, item, itemProps) => ((item?.targets_active ?? []).length === 0) ? 'все' : item.targets_active.map(({id, target_type}) => target_type_names[target_type] ).join(', ');

    const optionsAccessRightValues = (success, f, data) => SystemAccessRights.getValuesSelect({id: data.id, hasUnset: 1}, ({data}) => success(data));


    return <List
        popupPrefix  = {'systemAccessRightsList'}
        getListData  = {SystemAccessRights.getAll}
        cols         = {[
          {name: 'id'              , title: 'id'           , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: true          , canEdit: false},
          {name: 'is_active'       , title: 'активность'   , format: LIF.check   , type: LIT.check , canAddAndEdit: true          , default: 1   },
          {name: 'sysname'         , title: 'системное имя', format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true          , default: ''  , align: 'left'},
          {name: 'name'            , title: 'имя'          , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true          , default: ''  , align: 'left'},
          {name: 'default_value_id', title: 'по-умолчанию' , format: LIF.hidden  , type: LIT.select, canAdd: false, canEdit: true , default: null, align: 'left', customFormatHandler: formatDefaultValue, getOptions: optionsAccessRightValues },
          {name: 'values_active'   , title: 'значения'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false,                         align: 'left', customFormatHandler: formatValuesList  },
          {name: 'targets_active'  , title: 'назначаемо на', format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false,                         align: 'left', customFormatHandler: formatTargetsList },
          {name: 'description'     , title: 'описание'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true          , default: ''  , align: 'left'},

          {name: 'assigned_count'  , title: 'исп'           , format: LIF.usageCounter},
          {name: 'targets_count'   , title: 'целей'         , format: LIF.usageCounter},
        ]}
        actions = {[
            {title: 'значения'  , onClick: gotoAccessRightValues , canDo: () => true },
            {title: 'цели'      , onClick: gotoAccessRightTargets, canDo: () => true },
        ]}
        buttons      =  {[
            {type: 'new'},
        ]}

        onNew        = {SystemAccessRights.add   }
        onEdit       = {SystemAccessRights.update}
        onDelete     = {SystemAccessRights.delete}

        canDelete    = {(item) => ((item['values_count'] + item['targets_count']) === 0)}

        textOnDelete = {(item) => <>Удалить права <b>{item?.name ?? ''}</b>?</>}
    />

};

export default SettingsSystemAccessRightsList;
