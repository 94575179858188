import T from "./types";

const initialState = false;

export default function isPreviewMode(state = initialState, action) {
  switch (action.type) {
    case T.OFF_PREVIEW_MODE:
      return false;
    case T.ON_PREVIEW_MODE:
      return true;
    default:
      return state;
  }
}
