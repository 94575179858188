import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {Chain, Element, Form, getPath} from "../../../../api/methods";
import CreateButtonsBlock from "../CreateButtonsBlock/CreateButtonsBlock";
import { FavoritesPanel } from "../FavoritesPanel";
import PullElements from "../PullElements";
import Button from "../../../../components/UI-kit/Buttons/Button";
import Text from "../../../../components/UI-kit/Text";
import Title from "../../../../components/UI-kit/Title";
import { PATHS } from "../../../../constants/RoutesPaths";
import { setElements } from "../../../../store/elements/actions";
import { setFavoriteElements } from "../../../../store/favoriteElements/actions";
import { previewModeOFF } from "../../../../store/previewMode/actions";
import BackButton from "../../../Orders/BackButton";
import {usePreLoader} from "../../../../hooks/usePreLoader";
import ButtonGroup from "../../../../components/UI-kit/Buttons/ButtonGroup";

const Editor = (props) => {

  const { form_id } = useParams();

  const history   = useHistory();
  const dispatch  = useDispatch();
  const preloader = usePreLoader( true );
  const elements  = useSelector((state) => state.elements);

  const [name              , setName              ] = useState("");
  const [selectedSection   , setSelectedSuction   ] = useState(0);
  const [sections          , setSections          ] = useState(["Раздел 1", "Раздел 2"]);
  let   [systemNamesOptions, setSystemNamesOptions] = useState([]);

  useEffect(() => {
    loadForm(form_id);
    disablePreviewMode();
    loadSystemNames();
  }, [form_id]);

  const loadSystemNames = () => {
    Element.getSystemNames({}, loadSystemNamesSuccess);
  };

  const loadSystemNamesSuccess = ({ data }) => {
    const options = data.map(({ id, title }) => {
      return { id, name: title };
    });
    setSystemNamesOptions(options);
  };

  const loadFavoritesSuccess = ({ data }) => {
    dispatch(setFavoriteElements(data));
  };

  const loadFavorites = () => {
    Element.getAllFavorites({}, loadFavoritesSuccess);
  };

  const disablePreviewMode = () => dispatch(previewModeOFF());

  const loadFormSuccess = ({ data }) => {
    // fixOrderIndex(data);
    preloader.deactivate();
    dispatch(setElements(data));
  };

  const loadFormFail = ({ data }) => {
    preloader.deactivate();
  };

  // TODO: чисто для разработки//////////////////////////////////////////////////////////////////////////////
  const fixOrderIndex = (elements = []) => {
    const orderIndexes = elements.map((element) => element.order_index);

    const isHasDuplicates = checkForDuplicates(orderIndexes);

    if (isHasDuplicates) {
      fixPoolIndexes(elements);
    }
    elements.forEach((element) => {
      const { childrens } = element;
      childrens.map(({ elems }) => {
        fixOrderIndex(elems);
      });
    });
  };

  const fixPoolIndexes = (elements = []) => {
    elements.forEach((element, index) => {
      Form.change({ id: element.id, form: form_id, index });
    });
  };

  function checkForDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  // //////////////////////////////////////////////////////////////////////////////

  const loadForm = (id) => {
    preloader.activate();
    Form.getById({ id }, loadFormSuccess, loadFormFail );
    loadFavorites();
  };

  const inputName = (e) => {
    setName(e.target.value);
  };

  const createFormOnAnotherForm = () => {
    console.log("Создать на основе другой формы");
  };

  const onSelectSection = (index) => {
    setSelectedSuction(index);
  };

  const addElement = (type_id) => {
    const order_index = getElementOrderIndex();
    Chain.add({ type: type_id, form_id: form_id, order_index }, addElementSuccess);
  };

  const addElementSuccess = () => loadForm(form_id);

  const getElementOrderIndex = () => {
    const isEmptyElements = elements.length == 0;

    if (isEmptyElements) return 0;

    const { order_index } = elements.pop();
    return order_index + 1;
  };

  const addElementFromFavorite = (element) => {
    const order_index = getElementOrderIndex();
    Chain.addFromFavorite(
      {
        id: element.id,
        type: element.type_id,
        form_id: form_id,
        order_index: order_index,
      },
      addElementSuccess
    );
  };

  const deleteFromFavorite = (element) =>
    Element.deleteFavorite({ id: element.id }, () => loadForm(form_id));

  const goToPreview = () => {
    history.push( getPath(PATHS.formEditPreview, {form_id}) );
  };

  const isEmptyElements = elements.length === 0;


  return (
    <>
      <FavoritesPanel
          deleteFromFavorite={deleteFromFavorite}
          addElementFromFavorite={addElementFromFavorite}
      />

      <Title>Создание опросной формы</Title>

      {/*<Button onClick={() => history.push( getPath(PATHS.admin.editorCreatingNew, {id: form_id}) );}>Новый редактор</Button>*/}

      <ButtonGroup>
        <BackButton buttonType={0} to={PATHS.formEditSetCategory} />
        {(! isEmptyElements) && <Button onClick={goToPreview} style={{marginLeft:'10px'}}>Просмотр</Button>}
      </ButtonGroup>
      {preloader.isActive
        ? preloader.wait()
        :<>
            {isEmptyElements ? (
                <Text>Для добавления вопроса, нажмите на одну из кнопок ниже</Text>
            ) : (
                <PullElements
                    systemNames={systemNamesOptions}
                    elements={elements}
                />
            )}

            <CreateButtonsBlock addElement={addElement} />
         </>
      }
    </>
  );
};

export default Editor;
