import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Application } from '../api/methods';
import { FullscreenPreloader } from '../components/UI-kit/Preloader/FullscreenPreloader';
import { PATHS } from '../constants/RoutesPaths';

const NotFound = () => {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const isEmptyLS = localStorage.length === 0;

  const isNotLoginScreen = history.location.pathname !== PATHS.login;
  const isNotRegister    = history.location.pathname !== PATHS.register;
  const isNotAgreement   = history.location.pathname !== PATHS.agreement;

  const isLoginScreen = history.location.pathname === PATHS.login;
  const isRegister    = history.location.pathname === PATHS.register;
  const isAgreement   = history.location.pathname === PATHS.agreement;

  const isShowAlways = isLoginScreen || isRegister || isAgreement;

  useEffect(() => {
    const isNeedRedirect =
      isEmptyLS && isNotLoginScreen && isNotRegister && isNotAgreement;

    if (isNotLoginScreen && isNotRegister && isNotAgreement) {
      let isReturn = false;

      Application.list(
        {},
        () => {
          setIsLoading(false);
        },
        (res) => {
          const isAuthError = JSON.stringify(res).includes('401');

          if (isAuthError) {
            history.push(PATHS.login);
          } else {
            // history.push("/");
          }
        }
      );
    }
  }, []);

  if (isLoading) {
    return <FullscreenPreloader />;
  }

  return <Redirect to="/" />;
};

export default NotFound;
