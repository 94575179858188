import styled from "styled-components";

// prettier-ignore
export const TrBody = styled.tr`
    td {
        text-align: center;
    }
    td:first-child {
        text-align: left;
    }
`;