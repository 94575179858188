import React from 'react';
import UnlockedSvg from './UnlockedSvg';
import LockedSvg from './LockedSvg';
import { TextareaAutoResize } from './TextareaAutoResize';
import styled from 'styled-components';

const Icon = styled.div`
  width    : ${(props) => props.width}px;
  position : absolute;
  right    : 7px;
  top      : 50%;
  transform: translateY(-50%);
  cursor   : pointer;
`;

export default function SendMessage({ allowPrivateMsg, privateMsg, changePrivateMsg, ...rest }) {
    return (
        <div
            style = {{
                position: 'relative',
                flexGrow: 1,
                margin  : '10px 0px 6px 0px'
            }}
        >
            {allowPrivateMsg && (
                <div onClick={changePrivateMsg}>
                    {privateMsg
                        ? <Icon width={19}><LockedSvg   /></Icon>
                        : <Icon width={25}><UnlockedSvg /></Icon>
                    }
                </div>
            )}

            <TextareaAutoResize {...rest} privateMsg={privateMsg} />
        </div>
    );
}
