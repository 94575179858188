// prettier-ignore-start
import React from "react";

import { useHistory } from "react-router-dom";

import { PATHS }                  from "../../../constants/RoutesPaths";
import { getPath, SystemActions } from "../../../api/methods";

import List            from "../../../components/Lists/List";
import LIF             from "../../../components/Lists/ListItemFormats";
import LIT             from "../../../components/Lists/ListItemTypes";


const SettingsSystemActions = () => {

    const systemActions = [ // no mail events
        '_splitter'
    ];

    const history = useHistory();

    const gotoActionMails = (action_id) => history.push(getPath(PATHS.settings.systemActionMails, {action_id}));


    return <List
        popupPrefix  = {'systemActions'}
        getListData  = {SystemActions.getAll}
        cols         = {[
          {name: 'id'                 , title: 'id'           , format: LIF.linkEdit, type: LIT.number, canAdd: true , canEdit: false},
          {name: 'is_active'          , title: 'активность'   , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0},
          {name: 'sysname'            , title: 'системное имя', format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'title'              , title: 'название'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'description'        , title: 'описание'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: '', align: 'left'},
          {name: 'role_statuses_count', title: 'роль/статусов', format: LIF.usageCounter},
          {name: 'mails_count'        , title: 'писем'        , format: LIF.usageCounter},
        ]}
        actions = {[
            {title: 'письма', onClick: gotoActionMails, canDo: (item) => systemActions.indexOf(item?.sysname) === -1 },
        ]}
        buttons      =  {[
            {type: 'new'},
        ]}

        onNew        = {SystemActions.add   }
        onEdit       = {SystemActions.update}
        onDelete     = {SystemActions.delete}

        canDelete    = {(item) => (item['role_statuses_count'] + item['mails_count']) === 0}

        textOnDelete = {(item) => <>Удалить действие <b>{item?.title ?? ''}</b>?</>}
    />

};

export default SettingsSystemActions;
