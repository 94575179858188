// prettier-ignore-start
import React, {useState} from "react";

import {useDispatch} from "react-redux";

import {Application, SystemEmails} from "../../../../api/methods";
import { openPopup }               from "../../../../store/popups/actions";

import LIT                         from "../../../../components/Lists/ListItemTypes";
import PopupEdit                   from "../../../../components/Lists/PopupEdit";


const MailTestSend = ({
    name = 'systemTestSendEmail',
    action = SystemEmails.testSend,
}) => {

    const dispatch = useDispatch();

    const [data, setData] = useState(null);

    const optionsApplications = (success) => Application.list(
        { pageLimit: 25 },
        ({ data }) => success(
            data.data.map( (item) => ({
                id  : item.id,
                name: item.contract_number
            }))
        )
    );


    return {
        openForm : (id, to) => {
            setData({ id, to, application_id: null });
            dispatch( openPopup ( name ));
        },

        form : <PopupEdit
            isNewPopup  = {true}
            popupName   = {name}
            key         = {name}
            title       = ''
            doText      = 'Отправить'
            item        = {data}
            setItem     = {setData}
            cols        = {[
                { name: 'application_id', title: 'базовая заявка', type: LIT.select, getOptions: optionsApplications },
                { name: 'to'            , title: 'адресат'       , type: LIT.text  },
            ]}
            action      = {action}
        />
    }

};

export default MailTestSend;
