// prettier-ignore-start
import React from "react";
import {T} from "../../../../../lang/ru";
import {dateTranslate} from "../../../../../functions/common";
import {Empty} from "../../../../../components/Empty";


export const AssignedWorkers = () => {

/*
  const handleSelectExpertChange = (e, aplicationId) => {
    const { value } = e.target;
    startUpdateApplication();
    Application.setExpert(
      { app_id: aplicationId, expert_id: value },
      finishUpdateApplication
    );
  };

  <th>{T.expert}</th>
      <td>
        <OrderTableSelect
          name            = "expert_id"
          selectedValue   = {expert_id}
          options         = {experts}
          onChange        = {(e) => handleSelectExpertChange(e, id)}
          placeholder     = {T.empty.expert}
        />
      </td>

  <th>{T.getToWork}</th>
      <td>
        {expert_work_status_date ? formatDate(expert_work_status_date) : '–'}
      </td>

  <th>{T.executor}</th>
      <td>
        <OrderTableSelect
          name          = "executor_id"
          onChange      = {(e) => handleSelectPaymentMenChange(e, id)}
          options       = {executors}
          selectedValue = {application.executor_id}
          placeholder   = {T.empty.executor}
        />
      </td>

  <th>{T.getToWork}</th>
      <td>
        {executor_work_status_date
          ? formatDate(executor_work_status_date)
          : '–'}
      </td>

*/

    const outWorker = (id, fio, workDate) => ( (id)
        ? <>
            <nobr>{fio}</nobr><br /><small>{(workDate)
                ? dateTranslate(workDate)//+' '+timeTranslate(workDate)
                : '(не принял)'
            }</small>
        </>
        : <Empty text='не назначен' />
    );



    return {
        head : (rowData) => [T.expert, T.executor],

        body : (rowData) => {
            const {
                expert_id  , expert_fio  , expert_work_status_date  ,
                executor_id, executor_fio, executor_work_status_date,
            } = rowData;

            return [
                outWorker(expert_id  , expert_fio  , expert_work_status_date  ),
                outWorker(executor_id, executor_fio, executor_work_status_date)
            ];
        },

        headProps : (rowData) => ({ className: "fixed-row short" }),

    };

};