// prettier-ignore-start
import {T} from "../../../../../lang/ru";
import {defaultResult} from "./Common";
import {PATHS} from "../../../../../constants/RoutesPaths";
import {getPath} from "../../../../../api/methods";


export const Delivery = () => {

/*
  const PATH_GETTERS = {
    orderDelivery: () => PATHS.admin.orderDelivery.replace(':id', id),
  };

  <th>{T.filterNames.delivery}</th>
      <td>
        <Link
          className = "link"
          style     = {{ minWidth: 200 }}
          to        = {PATH_GETTERS.orderDelivery(id)}
        >
          {is_needed === 1 ? T.filterNames.delivery : T.noDelivery}
        </Link>
      </td>
*/


    return {
        head : (rowData) => T.filterNames.delivery,

        body : (rowData) => ((rowData.delivery_is_needed ?? 0) === 1) ? T.filterNames.delivery : T.noDelivery,

        bodyProps : (rowData) => ({
            ...defaultResult.bodyProps(rowData),

            to     : getPath(PATHS.orderDelivery, {order_id: rowData.id ?? 0, back_type: 'list'}),
            style  : {minWidth: '200px'},
        })
    };

};