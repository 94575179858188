import React, { useState } from 'react'
import Stack from '../../components/UI-kit/layout/Stack'
import FloatingInput from '../../components/UI-kit/FloatingInput'
import Button from '../../components/UI-kit/Buttons/Button'
import { isValidEmailCheck } from '../../functions/validators'
import { isEmpty } from '../../functions/common'

import { Bonuses } from '../../api/methods'
import styled from 'styled-components'
import {useAppDispatch} from "../../hooks/useRedux";
import {setPopUpFields} from "../../store/bonusesPopUp/actions";
import Subtitle from "../../components/UI-kit/Subtitle";


const FormStack = styled(Stack)`
    flex-wrap: wrap;
    & > * {
        flex-grow: 1;
    }
    input {
        width: 100%;
    }
`

interface dataTypes {
    email      : string,
    value      : string,
    [k: string]: string,
}

type wrongFieldsType = {
    email      : boolean,
    value      : boolean,
    [k: string]: boolean,
}

const initialFieldsStatus = {email: false, value: false}
const initialDataStatus   = {email: "", value: "", msg: ""};


export default function Form({balance} : {balance: number}) {

    const dispatch = useAppDispatch()

    const [data       , setData       ] = useState<dataTypes>(initialDataStatus)
    const [wrongFields, setWrongFields] = useState<wrongFieldsType>(initialFieldsStatus)
    const [blurFields , setBlurFields ] = useState<wrongFieldsType>(initialFieldsStatus)

    const invalidAmount = (value: number) => {
        return (value > +balance) || (value < 0)
    }

    const isEmailValid = () => {
        setBlurFields(prev => ({
            ...prev,
            email: true
        }))
        setWrongFields(prev => ({
            ...prev,
            email: !isValidEmailCheck(data.email)
        }))
    }

    const isValueValid = () => {
        setBlurFields(prev => ({
            ...prev,
            value: true
        }))
        setWrongFields(prev => ({
            ...prev,
            value: isEmpty(data.value) || invalidAmount(+data.value)
        }))
    }

    const changeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        if (name === 'value' && isNaN(+value)) {
            return
        }

        setData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const onBonusesTransfer = (data: any) => {
        if (data?.data?.message) {
            setData(initialDataStatus)
        }

        dispatch(setPopUpFields({
            visible    : true,
            title      : 'Передача бонусов',
            executeFunc: true,
            msg        : data?.response?.data?.message || data?.data?.message
        }))
    }

    const submitForm = (e: any) => {
        e.preventDefault()
        isEmailValid()
        isValueValid()
        if (Object.keys(wrongFields).every((key) => !wrongFields[key]) && 
            Object.keys(blurFields).every(key => data[key])) {
            Bonuses.sendBonuses(
                {count: data.value, email: data.email, message: data.msg}, 
                onBonusesTransfer, onBonusesTransfer)
        } 
    }

    return (<>
        <Subtitle>Вы можете передать бонусы другим пользователям:</Subtitle>
        <form style={{width:"75%"}}>
            <FormStack>
                <FloatingInput 
                    name         = "email"
                    placeholder  = "email"
                    value        = {data.email}
                    wrong        = {wrongFields.email}
                    wrongMessage = 'неправильно введем email'
                    onChange     = {changeField}
                    onBlur       = {isEmailValid}
                />
                <FloatingInput 
                    name         = "value"
                    placeholder  = "сумма отправки"
                    wrongMessage = 'Неправильно введена сумма'
                    wrong        = {wrongFields.value}
                    onChange     = {changeField}
                    onBlur       = {isValueValid}
                    value        = {data.value}
                />
            </FormStack>
            <FloatingInput
                style       = {{maxWidth: 'unset', width: "100%"}}
                name        = "msg"
                placeholder = "сообщение получателю"
                onChange    = {changeField}
                value       = {data.msg}
            />

            <Button
                style   = {{marginTop: 20}}
                type    = "submit"
                onClick = {submitForm}
            >
                Отправить
            </Button>
        </form>
    </>)
}
