// prettier-ignore
const tabType = {
    categories                      : 'categories',
    promocodes                      : 'promocodes',
    bonuses                         : 'bonuses',
    lists                           : 'lists',
    executorGroups                  : 'executor_groups',
    executorGroupsList              : 'executor_groups_list',
    executorGroupUsersList          : 'executor_group_users_list',
    sources                         : 'sources',
    delivery                        : 'delivery',
    deliveryMethods                 : 'delivery_methods',
    deliveryRegions                 : 'delivery_regions',
    deliveryTowns                   : 'delivery_towns',
    softwareProducts                : 'software_products',
    company                         : 'company',
    companyList                     : 'company_list',
    companyNew                      : 'company_new',
    companyEdit                     : 'company_edit',
    companyTemplates                : 'company_templates',
    companyForms                    : 'company_forms',
    common                          : 'common',
    ratings                         : 'ratings',
    ratingRoles                     : 'rating_roles',
    ratingScores                    : 'rating_scores',
    ratingScoreValues               : 'rating_score_values',
    system                          : 'system',
    systemCommon                    : 'system_common',
    systemStructure                 : 'system_structure',
    systemTemplates                 : 'system_templates',
    systemTemplateTypes             : 'system_template_types',
    systemTemplateTypeTemplates     : 'system_template_type_templates',
    systemTemplateTypeTemplateFiles : 'system_template_type_template_files',
    systemTemplateGenerators        : 'system_template_generators',
    systemTemplateFormats           : 'system_template_formats',
    systemRoles                     : 'system_roles',
    systemRoleAccessRights          : 'system_role_access_rights',
    systemRoleStatuses              : 'system_role_statuses',
    systemRoleStatusActions         : 'system_role_status_actions',
    systemRoleStatusAccessRights    : 'system_role_status_access_rights',
    systemAccessRights              : 'access_rights',
    systemAccessRightValues         : 'access_right_values',
    systemAccessRightTargets        : 'access_right_targets',
    systemStatuses                  : 'system_statuses',
    systemStatusMails               : 'system_status_mails',
    systemStatusRoles               : 'system_status_roles',
    systemStatusAccessRights        : 'system_status_access_rights',
    systemStatusRoleActions         : 'system_status_role_actions',
    systemStatusRoleAccessRights    : 'system_status_role_access_rights',
    systemActions                   : 'system_actions',
    systemActionMails               : 'system_action_mails',
    systemUsers                     : 'system_users',
    systemUserAccessRights          : 'system_user_access_rights',
    systemEmails                    : 'system_emails',
};

export default tabType;
