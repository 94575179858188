import styled from 'styled-components'
import Table from '../../components/UI-kit/Table/Table'
import Text from '../../components/UI-kit/Text'
import {accent, green, lightaccent, white, red} from '../../constants/Colours'


export const Bonuses = styled.section`
    margin: 25px 0 ;
`
export const Header = styled.header`
    display: flex; 
    justify-content: space-between;
    margin-bottom: 15px;
`

export const Chip = styled(Text)`
    padding: 3px 13px;
    color: ${white};
    background: ${ (props) => props?.color ? props.color : accent};
    border-radius: 100px;
`


export const BonusesTable = styled(Table)`

    width: 100%;
    margin: 15px 0 30px;

// , thead 
    tbody{
        //display   : block;
        max-height: 600px;
        overflow-y: scroll;
        overflow-y: overlay;
    }
    thead, tr {
        //display: flex;
    }
    th, td {
        //flex: 1 1 0;
    }
    .income {
        text-align: center;
        color: ${green};
        font-weight: 600;
    }
    .outcome {
        text-align: center;
        color: ${red};
        font-weight: 600;
    }
    .comment {
        font-size: 13px;
    }
    & thead {
        background: ${lightaccent};
        color: ${white};
    }
    & :is(th, td) {
        border-right: 4px solid ${white};
        text-align: left;
        padding: 10px 20px;
    }

`