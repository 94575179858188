// prettier-ignore-start
import React from "react";

import { useParams }                   from "react-router-dom";
import { SystemActions, SystemEmails } from "../../../api/methods";
import { useLoadRequest }              from "../../../hooks/useDataRequest";
import { PATHS }                       from "../../../constants/RoutesPaths";
import SettingsSystemMailsAssigned     from "./SettingsSystemMailsAssigned";


const SettingsSystemStatusMails = () => {

    const { action_id } = useParams();

    const action = useLoadRequest({
        request     : SystemActions.getById,
        requestData : { id: action_id },
    });


    return <SettingsSystemMailsAssigned
        types       = {[ {id: 1, name: 'Действие выполнено'} ]}
        title       = {<>Действие "{action.data.title ?? '-'}" / Уведомления:</>}
        backTo      = {PATHS.settings.systemActions}
        getListData = {(data, success, fail) => SystemEmails.listAssignedToAction({         action_id}, success, fail)}
        onNew       = {(data, success, fail) => SystemEmails.assignToAction      ({...data, action_id}, success, fail)}
    />

};

export default SettingsSystemStatusMails;
