// prettier-ignore-start
import React from "react";

import CompanyTemplatesList   from "./CompanyTemplatesList";
import { Template }           from "../../../api/methods";
import { PATHS }              from "../../../constants/RoutesPaths";


const SettingsCompanyTemplates = () => {

    return <CompanyTemplatesList
        popupPrefix  = { 'companyTemplatesList'  }
        backTo       = { PATHS.settings.company  }
        title        = { 'Базовые шаблоны'       }
        getListData  = { Template.listCompany    }
        onNew        = { Template.assign2Company }
    />

};

export default SettingsCompanyTemplates;
