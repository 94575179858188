// prettier-ignore-start
import {useState} from "react";


export const SystemNames = () => {

    const [count, setCount] = useState(0);

/*

  const systemNamesTitles = systemNames.map(({ title }) => <th>{title}</th>);


  const handleSendDocs = () => {
    if (window.confirm('Вы действительно хотите отправить документы?'))
      Application.sendDocs({ id: application.id }, successSendDocs);
  };

  const successSendDocs = () => {
    alert('Документы были успешно отправлены');
  };

    {systemNames?.map((systemName) => {
        let userAnswer = {};
        system_name_answers.find((answer) => {
            Object.entries(answer).map(([id, info]) => {
                if (info.name === systemName.title) {
                    userAnswer = {
                        elementId: id,
                        name     : info.name,
                        answer   : info.answer,
                    };
                }
            });
        });
        return (
            <SystemNameCell
                cellName      = {systemName.title}
                userAnswer    = {userAnswer}
                style         = {fixedColumn}
                applicationId = {id}
                setIsUpdating = {setIsLoadingData}
            />
        );
    })}
    {/!* {systemNames?.map(({ title }) => {
        if (!system_name_answers) return null;

        const answer = system_name_answers[title];
        return <td style={fixedColumn}>{answer}</td>;
      })} *!/}
*/


    return {
        head : (rowData) => {
            const data = rowData['system_names'] ?? [];
            const doSetCount = (count === 0) && (data.length > 0);
            const isSetCount = (count > 0) || doSetCount;

            if (doSetCount) setCount(data.length);

            return (isSetCount)
                ? rowData['system_names'].map( ({ name  }) => name )
                : [];
        },

        body : (rowData) => {
            const {system_names = null} = rowData;

            let result = [];
            for (let i=0; i<count; i++) result[i] = system_names[i]?.value ?? '';

            return result
        },
    }

};
