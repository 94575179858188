import React from 'react'
import Subtitle from '../../components/UI-kit/Subtitle'
import { BonusesTable } from './Bonuses.styles'
import Button from '../../components/UI-kit/Buttons/Button'
import { Bonuses } from '../../api/methods'
import {setPopUpFields} from "../../store/bonusesPopUp/actions";
import {useAppDispatch} from "../../hooks/useRedux";
import {transferHistoryItem} from "../../models/bonuses.model";
import {dateTranslate, timeTranslate} from "../../functions/common";

export default function TransferBonusesTable({transferHistory} : {transferHistory: transferHistoryItem[]}) {
    const dispatch = useAppDispatch()

    const cancelOutgoingTransfer = (id: number) => {
        Bonuses.cancelOutgoingTransfer({id},
            () => dispatch(setPopUpFields({
                visible    : true,
                title      : "Отправленные бонсы",
                msg        : "Вы отменили отправку",
                executeFunc: true
            })))
    }

    return (
        transferHistory.length
            ? <>
                <Subtitle>Отправленные другим пользователям:</Subtitle>
                <BonusesTable>
                    <thead>
                        <tr>
                            <th style={{width: '10%'}}>Дата</th>
                            <th style={{width: '10%'}}>Сумма</th>
                            <th style={{width: '25%'}}>Получатель</th>
                            <th style={{width: '35%'}}>Сообщение</th>
                            <th style={{width: '20%'}}>Статус</th>
                        </tr>
                    </thead>

                    <tbody>
                        {transferHistory.map(item => <tr key={item.id}>
                            <td><p style={{margin: '0px'}}>{dateTranslate(item.created_at)}</p><p style={{margin: '0px', color: 'grey'}}>{timeTranslate(item.created_at)}</p></td>
                            <td className="outcome">{item.count}</td>
                            <td>{item.receiver_email}</td>
                            <td>{item.message}</td>
                            <td style={{textAlign: 'center'}}>
                                <Button onClick={() => cancelOutgoingTransfer(item.id)}>Отменить</Button>
                            </td>
                        </tr>)}
                    </tbody>
                </BonusesTable>
              </>
            : null
    );

}
