import React from "react";
import styled from "styled-components";
import {accent, gray} from "../../constants/Colours";
import { device } from "../../constants/Breakpoints";

// prettier-ignore-start

type wrapperProps = {
    disabled: boolean
}

const Wrapper = styled.div`
    display      : flex;
    align-items  : center;
    position     : relative;
    width        : fit-content;
    height       : 23px;
    margin-bottom: 15px;
    & label::after {
      ${(props: wrapperProps) => props.disabled ? `background-color: ${gray} !important` : null};
    }
    & label::before {
      ${(props: wrapperProps) => props.disabled ? `border-color: ${gray}` : null};
    }
  @media ${device.laptop} {
        width: 100%;
    }
`;

const Input = styled.input`
    appearance: none;
    margin    : 0;
    position  : absolute;
`;

const Label = styled.label`
    cursor      : pointer;
    padding-left: 35px;
    margin-right: 25px;
    
    &::before {
        content      : "";
        display      : inline-block;
        width        : 23px;
        height       : 23px;
        position     : absolute;
        left         : 5px;
        border-radius: 50%;
        border       : 1px solid ${accent};
        bottom       : -1px;
    }
    
    ${Input}:checked ~ &::after {
        content         : "";
        display         : inline-block;
        width           : 11px;
        height          : 11px;
        position        : absolute;
        left            : 12px;
        bottom          : 6px;
        border-radius   : 50%;
        background-color: ${accent};
    }
    
    @media ${device.laptop} {
        margin-right: 0;
    }
`;

type InputRadioType = {
    value       : string;
    id          : string;
    name?       : string;
    onClick     : (e: React.MouseEvent<HTMLInputElement>) => void;
    placeholder : string;
    checked     : boolean;
    tabIndex?   : number;
    disabled    : boolean
};

const InputRadio: React.FC<InputRadioType> = ({
    value           = "",
    id           = "",
    name = "",
    onClick            = () => {},
    placeholder  = "",
    checked    = false,
    tabIndex= 0,
    disabled            = false
}) => {
    return (
        <Wrapper disabled={disabled}>
            <Input
                id       = {id}
                type     = "radio"
                value    = {value}
                name     = {name == undefined ? '' : name }
                checked  = {checked}
                onClick  = {onClick}
                tabIndex = {tabIndex == undefined ? 0 : tabIndex }
            />
            <Label htmlFor={id}>
                {placeholder}
            </Label>
        </Wrapper>
    );
}

export default InputRadio;
