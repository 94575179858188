// prettier-ignore-start
import React from 'react';

import {useDispatch}              from "react-redux";

import { T }                      from '../../../lang/ru';
import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";
import {setIsShowPopup}           from "../../../store/popups/actions";
import {isEmpty}                  from "../../../functions/common";

import SecondOrderButton          from "../../../components/UI-kit/Buttons/SecondOrderButton";
import OrderDeletePopup           from "./OrderDeletePopup";

type Props = {
    application_id: string,
}


const OrderDeleteButton: React.FC<Props> = ({
    application_id = '',
}) => {

    const dispatch: any   = useDispatch();
    const userAccess: any = useUserAccessRightChecks();

    if (! userAccess.isAllowAppDelete) return null;

    const onClickDelete = () => dispatch(setIsShowPopup('isShowDeleteApplication', true));


    return <>
        <SecondOrderButton
            onClick   = {onClickDelete}
            marginTop = "15px"
            className = "delete-button"
            disabled  = {isEmpty(application_id)}
        >
            {T.delete}
        </SecondOrderButton>
        <OrderDeletePopup id={application_id}></OrderDeletePopup>
    </>

};

export default OrderDeleteButton;
