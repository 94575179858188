import React from 'react';

// prettier-ignore-start

import { useDispatch, useSelector } from 'react-redux';
import styled                       from 'styled-components';
import * as Scroll                  from 'react-scroll';
import TopBar                       from './TopBar/TopBar';
import youtubeImg                   from '../../../../assets/svg/youtube.svg';
import { Element }                  from '../../../../api/methods';
import { black, lightgray, yellow } from '../../../../constants/Colours';
import { typeName, typeTip }        from '../../../../store/elements/actions';
import { setFavoriteElements }      from '../../../../store/favoriteElements/actions';
import { getPreviewMode }           from '../../../../store/previewMode/selectors';
import SecondOrderButton            from '../../../../components/UI-kit/Buttons/SecondOrderButton';
import FloatingInput                from '../../../../components/UI-kit/FloatingInput';
import Subtitle                     from '../../../../components/UI-kit/Subtitle';
import { TextEditor }               from '../../../../components/UI-kit/TextEditor/TextEditor';
import Title                        from '../../../../components/UI-kit/Title';
import { goToHref }                 from '../../../../functions/common/common';
import hooks                        from '../../../../functions/hooks';
import { ifEmpty, isEmpty }         from "../../../../functions/common";


const Wrapper = styled.div`
    ${(props) =>
        props.isImportantInfo
            ? `
                padding: 20px 40px 40px 50px;
                border: 1px solid ${lightgray};
                ${Title}{
                  max-width:fit-content;
                }
            `
            : ``}
`;

const TitleWrapper = styled(Scroll.Element)`
    color: ${(props) => (props.$empty ? yellow : black)};
`;

const ImgYouTube = () => <img src={youtubeImg} alt="" style={{width:20, height:20, position: "relative", top: "4px"}} />;

const CommonElementsPart = ({
    element,
    deleteElement,
    isLast,
    isFirst,
    swapNext,
    swapPrevious,
    systemNames,
    deleteFromFavorite,
    toggleFavorite,
    isElementFavorite,
    loadForm,
}) => {

    let { type, tip = '', name, href, href_name, required } = element;

    href_name = ifEmpty(href_name, 'Дополнительные материалы');
    tip       = ifEmpty(tip, '');

    const dispatch      = useDispatch();
    const isPreviewMode = useSelector(getPreviewMode);

    const isFieldEmpty = hooks.useIsFieldEmpty(element.id);

    const loadFavoritesSuccess = ({ data }) => {
        dispatch(setFavoriteElements(data));
    };

    const loadFavorites = () => {
        Element.getAllFavorites({}, loadFavoritesSuccess);
    };

    const updateElement = () => {
        const {
            id,
            tip = '',
            name = '',
            href = '',
            href_name = '',
            required,
        } = element;
        Element.update(
            { id, name, tip, href, href_name, required },
            elementUpdateSuccess
        );
    };

    const elementUpdateSuccess = (res) => {
        loadFavorites();
    };

    const toggleRequired = () => {
        const { id } = element;
        const isRequired = !element.required ? 1 : 0;
        Element.update({ id, required: isRequired }, loadForm);
    };

    const makeSystemElement = (newSystemNameId) => {
        const { id } = element;
        Element.update({ id, system_name_id: newSystemNameId }, loadForm);
    };

    const handleChangeName = (e) =>
        dispatch(typeName(element.id, e.target.value));

    const handleChangeTip = (value) => {
        dispatch(typeTip(element.id, value));
        updateElement();
    };

    const handleDeleteElement = () => deleteElement(element.id);

    const isImportantInfo = type === 6;

    const isTipEmpty  = isEmpty(element.tip);
    const isHrefEmpty = isEmpty(element.href);

    const isYoutubeHref = isHrefEmpty ? false : (href.includes('youtube.com/watch') || href.includes('//youtu.be/'));

    const hrefName = (isYoutubeHref)
        ? <><ImgYouTube /> {href_name} (просмотреть) </>
        : href_name;

    let elementTitle = ifEmpty(name, 'Отсутствует имя элемента');

    if (required) elementTitle += ' *';


    return (
        <Wrapper isImportantInfo={isImportantInfo}>
            <TopBar
                isFirst            = {isFirst}
                isLast             = {isLast}
                toggleRequired     = {toggleRequired}
                swapNext           = {swapNext}
                swapPrevious       = {swapPrevious}
                makeSystemElement  = {makeSystemElement}
                systemNames        = {systemNames}
                element            = {element}
                required           = {required}
                deleteElement      = {handleDeleteElement}
                type               = {type}
                isElementFavorite  = {isElementFavorite}
                toggleFavorite     = {toggleFavorite}
                deleteFromFavorite = {deleteFromFavorite}
            />

            {isPreviewMode
                ? <TitleWrapper name={String(element.id)} $empty={isFieldEmpty}>
                    {isImportantInfo ? (
                        <Title>{elementTitle} </Title>
                    ) : (
                        <Subtitle style={{ marginBottom: 7 }}>{elementTitle}</Subtitle>
                    )}
                </TitleWrapper>
                : <FloatingInput
                    value       = {name == null ? '' : name}
                    onChange    = {handleChangeName}
                    onBlur      = {updateElement}
                    placeholder = {isImportantInfo ? 'Заголовок' : 'Вопрос'}
                    style       = {{ fontWeight: 'bold' }}
                />
            }

            {!isPreviewMode && <TextEditor
                onChange    = {handleChangeTip}
                placeholder = {isImportantInfo ? 'Содержание информации' : ''}
                element     = {element}
            />}

            {isPreviewMode && <>
                {!isHrefEmpty && (
                    <SecondOrderButton
                        style   = {{ padding: 0, marginBottom: 10 }}
                        onClick = {() => goToHref(href)}
                    >
                        {hrefName}
                    </SecondOrderButton>
                )}

                {!isTipEmpty && (
                    <TextEditor
                        onChange    = {handleChangeTip}
                        placeholder = {isImportantInfo ? 'Содержание информации' : ''}
                        element     = {element}
                    />
                )}
            </>}
        </Wrapper>
    );
};

export default CommonElementsPart;
