import { getParentId } from "../../functions/common";

export const getUserAnswers = (state) => state.userAnswers;

export const getUserAnwer = (id) => (state) => {
  return state.userAnswers[id];
};

export const getAnswerCount = (id) => (state) => {
  let result = 0;

  Object.keys(state.userAnswers).forEach((answerId) => {
    const parentId = getParentId(answerId, state.userAnswers[answerId]["parentId"])
    if (
      parentId == id &&
      answerId.includes("_")

    ) {
      const answerIdCount = +answerId.split("_").pop() + 1;
      if (answerIdCount > result) result = answerIdCount;
    } 
  });

  return result;
};
