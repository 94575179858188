import React from 'react'
import Subtitle from '../../components/UI-kit/Subtitle'
import { historyItem } from '../../models/bonuses.model'
import Text from '../../components/UI-kit/Text'
import { BonusesTable } from './Bonuses.styles'
import {dateTranslate, timeTranslate} from "../../functions/common";

export default function History({history, id}: {history: historyItem[], id?: string}) {

    return <>
        <Subtitle>История начислений:</Subtitle>
        {history?.length
            ? <div style={{display: 'block', maxHeight: '600px', overflowY: 'scroll'}}>
                <BonusesTable>
                        <thead>
                            <th style={{width: '10%'}}>Дата</th>
                            <th style={{width: '10%'}}>Сумма</th>
                            <th style={{width: id ? '40%' : '80%'}}>Описание</th>
                            {id && <th style={{width: '40%'}}>Внутренний комментарий</th>}
                        </thead>
                        <tbody>
                            {history.map(i => (
                                <tr key={i.id}>
                                    <td><p style={{margin: '0px'}}>{dateTranslate(i.created_at)}</p><p style={{margin: '0px', color: 'grey'}}>{timeTranslate(i.created_at)}</p></td>
                                    <td className={i.count > 0 ? "income" : "outcome"}>{(i.count >= 0) ? <>&nbsp;{i.count}</> : i.count}</td>
                                    <td>{i.description}</td>
                                    {id && <td>{i.admin_comment}</td>}
                                </tr>
                            ))}
                            {/* <tr>
                                <td>12.12.13</td>
                                <td className="income">+3000</td>
                                <td>Иванов И. И.</td>
                            </tr> */}
                        </tbody>
                </BonusesTable>
              </div>
            : <Text style={{margin: "15px 0 15px 20px"}}>
                {id ? "У пользователя отсутствует история" : "Отсутствует"}
              </Text>
        }
    </>
}
