// prettier-ignore-start
import React from "react";

import { useParams }          from "react-router-dom";

import { SystemAccessRights } from "../../../api/methods";
import { useLoadRequest }     from "../../../hooks/useDataRequest";
import { PATHS }              from "../../../constants/RoutesPaths";
import List                   from "../../../components/Lists/List";
import LIF                    from "../../../components/Lists/ListItemFormats";
import LIT                    from "../../../components/Lists/ListItemTypes";


const SettingsSystemAccessRightTargets = () => {

    const { access_right_id } = useParams();

    const accessRight = useLoadRequest({
        request     : SystemAccessRights.getById,
        requestData : { id: access_right_id },
    });

    const optionsAccessRightTargets = (s, f, item) => SystemAccessRights.listTargetsSelect({}, ({data}) => s(data), f);


    return <List
        title        = {<>{accessRight.data.name ?? 'Доступ'} ({accessRight.data.sysname ?? 'name'})</>}
        popupPrefix  = {'systemAccessRightTargetsList'}
        getListData  = {(data, success, fail) => SystemAccessRights.listTargetsAssigned({access_right_id}, success, fail)}

        cols         = {[
            {name: 'id'         , title: 'id'           , format: LIF.linkEdit, type: LIT.number  , canAddAndEdit: false        , canEdit: false},
            {name: 'is_active'  , title: 'активность'   , format: LIF.check   , type: LIT.check   , canAddAndEdit: true         , default: 1},
            {name: 'target_type', title: 'назначаемо на', format: LIF.linkEdit, type: LIT.select  , canAddAndEdit: true         , default: '', align: 'left', getOptions: optionsAccessRightTargets},
            //{name: 'priority_id', title: 'приоритет'    , format: LIF.linkEdit, type: LIT.number  , canAdd: false, canEdit: true, default: 0},
        ]}

        buttons      =  {[
            {type: 'group', buttons: [
                {type: 'back', to: PATHS.settings.systemAccessRights},
            ]},
            {type: 'new'},
        ]}

        onNew        = {(data, s, f) => SystemAccessRights.addTargetAssigned({...data, access_right_id}, s, f)   }
        onEdit       = {SystemAccessRights.updateTargetAssigned}
        onDelete     = {SystemAccessRights.deleteTargetAssigned}

        canDelete    = {(item) => true}

        textOnDelete = {(item) => <>Удалить <b>{item?.target_type ?? ''}</b>?</>}
    />

};

export default SettingsSystemAccessRightTargets;
