import React, { useEffect, useState } from 'react';

import { useHistory }          from 'react-router-dom';
import { YMInitializer }       from 'react-yandex-metrika';
import ReactGA                 from 'react-ga';

import { PATHS }               from './constants/RoutesPaths';
import { isProPBSystem }       from './constants/SystemType';
import Main                    from './routes/Main';
import { Application }         from './api/methods';
import LayoutForRoutes         from './components/Navigation/LayoutForRoutes/LayoutForRoutes';
import { FullscreenPreloader } from './components/UI-kit/Preloader/FullscreenPreloader';
import PopUp                   from "./components/Alert/PopUp";


function App() {
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const { pathname } = history.location;

    const disableRedirectRoutes = [
      PATHS.login,
      PATHS.register,
      PATHS.agreement,
      PATHS.forgotPassword,
    ];

    const isDisabledRedirectRoute = disableRedirectRoutes.includes(pathname) || pathname.includes('reset-password');
    const isEmptyLS = localStorage.length === 0;

    const isLoginScreen = pathname === PATHS.login    ;
    const isRegister    = pathname === PATHS.register ;
    const isAgreement   = pathname === PATHS.agreement;
    const isShowAlways  = isLoginScreen || isRegister || isAgreement;

    useEffect(() => {
        const isNeedRedirect = isEmptyLS && !isDisabledRedirectRoute;

        if (!isDisabledRedirectRoute) {
            Application.list(
                { pageLimit: '1' },
                () => {
                    setIsLoading(false);
                },
                (res) => {
                    const stringRes   = JSON.stringify(res);
                    const isAuthError = stringRes.includes('401');

                    if (isAuthError) history.push(PATHS.login);
                    setIsLoading(false);
                }
            );
        }

        if (isNeedRedirect) {
            setIsLoading(false);
            history.push(PATHS.login);
        }
        setIsLoading(false);
        initReactGA();
    }, []);

    const initReactGA = () => {
        const GA_ID = isProPBSystem ? 'UA-209496319-1' : 'UA-185644476-1';
        ReactGA.initialize(GA_ID);
    };


    if (isLoading && !isShowAlways) return <FullscreenPreloader />;
    return (
        <LayoutForRoutes>
            <YMInitializer
                accounts = {
                    isProPBSystem
                        ? [85767538]
                        : [46951317]
                }
                options  = {{
                    clickmap            : true,
                    trackLinks          : true,
                    accurateTrackBounce : true,
                    webvisor            : true,
                    ecommerce           : 'dataLayer',
                }}
            />
            <Main />
            <PopUp />
        </LayoutForRoutes>
    );

}

export default App;
