import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Element, Form } from '../../../../api/methods';
import {
  deleteStructElement,
  setElements,
  setStructToElement,
} from '../../../../store/elements/actions';
import { setFavoriteElements } from '../../../../store/favoriteElements/actions';
import { getFavoriteElements } from '../../../../store/favoriteElements/selectors';
import { getPreviewMode } from '../../../../store/previewMode/selectors';
import CommonElementsPart from './CommonElementsPart';
import CheckBoxPart from './CustomPart/CheckBoxPart';
import ComplexListPart from './CustomPart/ComplexListPart';
import GroupPart from './CustomPart/GroupPart';
import InputPart from './CustomPart/InputPart';
import RadioPart from './CustomPart/RadioPart';
import RepeatListPart from './CustomPart/RepeatListPart';
import UploadFilePart from './CustomPart/UploadFilePart';
import Wrapper from './Wrapper';

// 1: "Поле для ввода",
// 2: "Один из списка",
// 3: "Несколько из списка",
// 4: "Вопрос с прикреплением файла",
// 5: "Группа вопросов",
// 6: "Важная информацию",
// 7: "Поле для ввода списка",
// 8: "Список с повторяющимися вопросами",
// 9: "Супер вложенный элемент",

const MainElement = ({
  element,
  isFirst,
  isLast,
  swapNext,
  swapPrevious,
  systemNames,
}) => {
  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);
  const favoriteElements = useSelector(getFavoriteElements);

  const { type } = element;

  const [struct, setStruct] = useState(element.struct);
  const isWithoutCustomPart = type === 6;

  const form_id = document.location.pathname.split('/').pop();

  const successLoadForm = ({ data }) => {
    dispatch(setElements(data));
    loadFavorites();
  };

  const loadForm = (id) => Form.getById({ id }, successLoadForm);

  const addStructSuccess = ({ data }) => {
    const newStruct = data.map(({ id, value }) => {
      return { id, name: value };
    });
    dispatch(setStructToElement(element.id, newStruct));
    setStruct(newStruct);
    loadForm(form_id);
  };

  useEffect(() => {
    const { type, struct, id } = element;
    if ((type === 5 || type === 9) && struct.length === 0) addStruct(id, 'empty');
  }, []);

  const addStruct = (elementId, name) => {
    Element.addStructElement({ name, id: elementId }, addStructSuccess);
  };

  const deleteStuctSuccess = (elementId, structId) => {
    dispatch(deleteStructElement(elementId, structId));
  };

  const deleteStruct = (elementId, structId) => {
    Element.deleteStructElement({ id: structId }, () =>
      deleteStuctSuccess(elementId, structId)
    );
  };

  const loadFormWithFormId = () => {
    loadForm(form_id);
  };

  const loadFavoritesSuccess = ({ data }) => {
    dispatch(setFavoriteElements(data));
  };

  const loadFavorites = () => {
    Element.getAllFavorites({}, loadFavoritesSuccess);
  };

  const deleteElement = () =>
    Element.deleteElement({ id: element.id }, loadFormWithFormId);

  const toggleFavorite = () =>
    Element.toggleFavorite(
      { elem: JSON.stringify(element) },
      loadFormWithFormId
    );

  const deleteFromFavorite = () =>
    Element.deleteFavorite({ id: element.id }, loadFormWithFormId);

  const isElementFavorite =
    favoriteElements.filter((favorite) => favorite.element_id === element.id)
      .length !== 0;

  const BasicElems = isWithoutCustomPart ? null : type === 2 ? (
    <RadioPart
      struct       = {struct}
      element      = {element}
      addStruct    = {addStruct}
      deleteStruct = {deleteStruct}
    />
  ) : type === 3 ? (
    <CheckBoxPart
      struct       = {struct}
      element      = {element}
      addStruct    = {addStruct}
      deleteStruct = {deleteStruct}
    />
  ) : type === 4 ? (
    <UploadFilePart
      struct       = {struct}
      element      = {element}
      addStruct    = {addStruct}
      deleteStruct = {deleteStruct}
    />
  ) : type === 5 ? (
    <GroupPart
        struct     = {struct}
        element    = {element}
    />
  ) : type === 1 ? (
    <InputPart
      struct       = {struct}
      addStruct    = {addStruct}
      deleteStruct = {deleteStruct}
      element      = {element}
    />
  ) : type === 7 ? (
    <ComplexListPart
        element    = {element}
    />
  ) : type === 8 ? (
    <RepeatListPart
      struct       = {struct}
      element      = {element}
      addStruct    = {addStruct}
      deleteStruct = {deleteStruct}
    />
  ) : null;

  return (
    <Wrapper
      className     = "element"
      data-id       = {element.id}
      isPreviewMode = {isPreviewMode}
    >
      {/*//TODO if you delete empty first element second empty element not rendering correctly*/}
      <CommonElementsPart
        element            = {element}
        systemNames        = {systemNames}
        isFirst            = {isFirst}
        isLast             = {isLast}
        swapNext           = {swapNext}
        swapPrevious       = {swapPrevious}
        toggleFavorite     = {toggleFavorite}
        deleteFromFavorite = {deleteFromFavorite}
        deleteElement      = {deleteElement}
        loadForm           = {loadFormWithFormId}
        isElementFavorite  = {isElementFavorite}
      />
      {BasicElems}
    </Wrapper>
  );
};

export default MainElement;
