import styled from "styled-components";


// prettier-ignore-start

export const TableSubTitle = styled.span`
  display  : inline-block;
  margin   : 0px 0px 0px 0px !important;
  padding  : 0px 0px 0px 0px !important;
  font-size: smaller;
`;
