import React from "react";
import {Preloader} from "../components/UI-kit/Preloader/Preloader";
import {useActive} from "./useActive";

// prettier-ignore
export function usePreLoader() {

  const active = useActive(true);

  const wait = () => <Preloader />

  return {
    ...active,

    wait       : wait,
  };
}
