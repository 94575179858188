
type ObjectWithKey = {
  key: string;
};
type Result = {
  [key: string]: string;
};

export const formDataFromInputInfoForState: (
  fields: ObjectWithKey[]
) => Result = (fields) => {
  const data: Result = {};

  fields.forEach(({ key }) => {
    if (key) {
      data[key] = '';
    }
  });

  return data;
};

export const goToHref = (href: string) => window.open(href);

export const checkFullnessFields = (array: []) => array.some((i) => !i);

export const formatDate = (date: string): string => {
  if (!date) return '';

  let newDate = date.split(' ')[0];
  const dateObj = new Date(Date.parse(newDate));
  newDate = dateObj.toLocaleDateString('ru-RU', {
    year: 'numeric', //
    month: '2-digit', // long
    day: '2-digit', // numeric
  });

  return newDate;
};

export const formatDateToApi = (date: string): string =>
  date.split('.').reverse().join('-');

export function debounce(f: any, ms: number) {
  let isCooldown = false;

  return function (this: any) {
    if (isCooldown) return;

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);
  };
}

export function isNullOrUndefined(entity: any) {
  return entity === undefined || entity === null;
}
export function isEmptyString(str: string) {
  return str.match(/^\s+$|^$/gi); // /^\s+$|^$/gi    /[\S\s]+[\S]+/
}
