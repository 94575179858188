import React from 'react'
import Subtitle from '../../components/UI-kit/Subtitle'
import { BonusesTable } from './Bonuses.styles'
import Button from '../../components/UI-kit/Buttons/Button'
import { Bonuses } from '../../api/methods'
import {useAppDispatch} from "../../hooks/useRedux";
import {setPopUpFields} from "../../store/bonusesPopUp/actions";
import {receivedHistoryItem} from "../../models/bonuses.model";
import {dateTranslate, timeTranslate} from "../../functions/common";
import ButtonGroup from "../../components/UI-kit/Buttons/ButtonGroup";



export default function ReceivedBonusesTable({receivedBonuses}: {receivedBonuses: receivedHistoryItem[]}) {
    const dispatch = useAppDispatch()

    const acceptIncomingBonuses = (id: number) => {
        Bonuses.acceptIncomingTransfer({id}, 
            () => dispatch(setPopUpFields({
                    visible: true,
                    title: "Ваш подарок",
                    msg: "Вы приняли подарок",
                    executeFunc: true
                })))
    }
    const rejectIncomingBonuses = (id: number) => {
        Bonuses.rejectIncomingTransfer({id},
            () => dispatch(setPopUpFields({
                visible: true,
                title: "Ваш подарок",
                msg: "Вы оклонили подарок",
                executeFunc: true
            })))
    }


  return (
    <>
    {receivedBonuses.length ?
        <>
            <Subtitle>Подарки вам:</Subtitle>
            <BonusesTable>
                <thead>
                    <tr>
                        <th style={{width: '10%'}}>Дата</th>
                        <th style={{width: '10%'}}>Сумма</th>
                        <th style={{width: '25%'}}>Отправитель</th>
                        <th style={{width: '35%'}}>Сообщение</th>
                        <th style={{width: '20%'}}> </th>
                    </tr>
                </thead>

                <tbody>
                    {receivedBonuses.map(item => <tr key={item.id}>
                        <td><p style={{margin: '0px'}}>{dateTranslate(item.created_at)}</p><p style={{margin: '0px', color: 'grey'}}>{timeTranslate(item.created_at)}</p></td>
                        <td className='income'>{item.count}</td>
                        <td>{item.receiver_email}</td>
                        <td>{item.message}</td>
                        <td style={{textAlign: 'center'}}>
                            <ButtonGroup><Button onClick={() => acceptIncomingBonuses(item.id)}>Принять</Button> <Button onClick={() => rejectIncomingBonuses(item.id)} title='Отклонить'>X</Button></ButtonGroup>
                        </td>
                    </tr>)}
                </tbody>
            </BonusesTable>
        </>
            : null}
    </>
  )
}
