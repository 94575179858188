// prettier-ignore-start
import React from "react";
import {T} from "../../../../../lang/ru";
import {useUserAccessRightChecks} from "../../../../../hooks/useUserAccessRightChecks";
import {numbToLocale} from "../../../../../functions/common";
import {TableSubTitle} from "../TableSubTitle";


export const Price = () => {

    const userAccess = useUserAccessRightChecks();

/*
  const handleSelectPaymentMenChange = (e, aplicationId) => {
    const { value } = e.target;
    startUpdateApplication();
    Application.setExecutor(
      { app_id: aplicationId, executor_id: value },
      finishUpdateApplication
    );
  };

  <th className="short">{T.sum}</th>
  <th className="short">{T.prepayment}</th>
  <th>{T.payment}</th>
  <th>{T.dateOfPayment}</th>
  <th>{T.paymentStatus}</th>
  <th>{T.arrears}</th>
  <th>{T.bonusesTitle}</th>

    <td className="input short">
        {hasPromocode && <br />}
        <OrderTableInput
            name        = "price"
            value       = {price}
            onChange    = {handleChangeInput}
            onBlur      = {updateInput}
            width       = {150}
            placeholder = {T.sum}
        />
        {(price !== price_details.final_price) && (
            <>
                <br />
                <TableSubTitle>к оплате: {NumbToLocale(price_details.final_price)} ₽</TableSubTitle>
            </>
        )}
    </td>

    <td className="short">
        <OrderTableInput
            name        = "prepayment"
            value       = {application.prepayment}
            onChange    = {(e) => handleChangeInput(e, [0, 100])}
            onBlur      = {(e) => updateInputLimited(e, 0)}
            width       = {150}
            placeholder = {T.prepayment}
        />
    </td>

    <td>
        <OrderTableInput
            name        = "paid"
            value       = {application.paid}
            onChange    = {handleChangeInput}
            onBlur      = {updateInput}
            placeholder = {T.payment}
        />
    </td>

    <td>
        {/!* {formatDate(application.date_of_payment)} *!/}
        {isHasPrepayment && !isFullPrepayment ? (
            <>
            <span
                style={{
                    width         : '100%',
                    padding       : 0,
                    display       : 'flex',
                    flexDirection : 'row',
                    justifyContent: 'space-between',
                }}
            >
              <span style={{ padding: 0 }}>
                {T.dateOfPrepaymentShort}:&nbsp;
              </span>
              <OrderTableInput
                  name        = "prepayment_date"
                  value       = {application.prepayment_date}
                  type        = "date"
                  onChange    = {handleChangeInput}
                  onBlur      = {updateInput}
                  placeholder = {T.dateOfPrepayment}
              />
            </span>

                <span
                    style={{
                        width         : '100%',
                        padding       : 0,
                        display       : 'flex',
                        flexDirection : 'row',
                        justifyContent: 'space-between',
                        marginTop     : 5,
                    }}
                >
              <span style={{ padding: 0 }}>
                {T.dateOfFullPaymentShort}:&nbsp;
              </span>
              <OrderTableInput
                  name        = "date_of_payment"
                  value       = {application.date_of_payment}
                  type        = "date"
                  onChange    = {handleChangeInput}
                  onBlur      = {updateInput}
                  placeholder = {T.dateOfPayment}
              />
            </span>
            </>
        ) : (
            <OrderTableInput
                name              = "date_of_payment"
                value             = {application.date_of_payment ?? ''}
                type              = "date"
                onChange          = {handleChangeInput}
                onBlur            = {updateInput}
                placeholder       = {T.dateOfPayment}

            />
        )}
    </td>

    <td style={{ cursor: 'pointer' }}>{payedStatus}</td>

    <td>
        {NumbToLocale(arrears)} ₽
    </td>

    <td align='center'>
        {(price_details.bonuses_to_spent+bonuses_to_use > 0) && <p className="admin-registry-text">-{price_details.bonuses_to_spent}{(price_details.bonuses_to_spent !== bonuses_to_use) && <>/{bonuses_to_use}</>}</p>}
        <p className="admin-registry-text">+{bonuses_received}</p>
    </td>

*/


    return {
        head : (rowData) => ( (userAccess.isAllowAppListPriceTotal)
            ? T.sum
            : [
                T.sum,
                T.prepayment,
                T.payment,
                T.arrears,
                T.bonusesTitle,
            ]
        ),

        body : (rowData) => {
            const {price=0, prepayment=0, paid=0, arrears=0, price_details={}, bonuses_to_use=0, bonuses_received=0} = rowData;
            const {final_price=0, bonuses_to_spent=0} = price_details;

            const hasDifPrice     = price !== final_price;
            const hasBonusesUsage = (bonuses_to_spent + bonuses_to_use) > 0;
            const hasDifBonuses   = bonuses_to_spent !== bonuses_to_use;

            return ( (userAccess.isAllowAppListPriceTotal)
                ? numbToLocale(final_price)+' ₽'
                : [
                    <>
                        {hasDifPrice && <br />}
                        {numbToLocale(price)} ₽
                        {hasDifPrice && <>
                            <br />
                            <nobr><TableSubTitle>конечная: {numbToLocale(final_price)} ₽</TableSubTitle></nobr>
                        </>}
                    </>,
                    prepayment+'%',
                    numbToLocale(paid   )+' ₽',
                    numbToLocale(arrears)+' ₽',
                    <>
                        {hasBonusesUsage && <p className="admin-registry-text">- {bonuses_to_spent}{hasDifBonuses && <>/{bonuses_to_use}</>}</p>}
                        <p className="admin-registry-text">+ {bonuses_received}</p>
                    </>
                ]
            );
        },

        headProps : (rowData) => ({ className: "fixed-row short" }),

    };

};