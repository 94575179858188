// prettier-ignore-start
import React from "react";
import {Application} from "../../../../api/methods";
import List from "../../../../components/Lists/List";
import LIF from "../../../../components/Lists/ListItemFormats";
import {dateTranslate, timeTranslate} from "../../../../functions/common";


const OrderHistoryLog = ({
    appId = null,
}) => {

    const formatMain      = (content) => <p style={{margin: '0px'}}>{content}</p>;
    const formatSub       = (content) => <p style={{margin: '0px', color: 'grey'}}><small>{content}</small></p>;
    const formatMainSub   = (contentMain, contentSub) => <>{formatMain(contentMain)}{formatSub(contentSub)}</>;
    const formatMainSubId = (contentMain, contentSub) => formatMainSub(contentMain, '{'+contentSub+'}');
    const formatDate      = (date) => formatMainSub(dateTranslate(date), timeTranslate(date));

    const statusFormat    = (id, item, itemProps) =>
        (item?.new_status_id === item?.old_status_id)
            ? formatMainSubId(item?.old_status?.title ?? '-', item?.old_status_id)
            : formatMainSubId( (item?.old_status?.title ?? '-') +' > '+ (item?.new_status?.title ?? '-'), (item?.old_status_id ?? '-') +' > '+ (item?.new_status_id ?? '-'));
    const userFormat      = (id, item, itemProps) => formatMainSubId(item?.user?.surname +' '+ item?.user?.name +' '+ item?.user?.middlename, item?.user_id);
    const actionFormat    = (id, item, itemProps) => formatMainSubId(item?.action?.title, item?.action_id);
    const dateFormat      = (id, item, itemProps) => formatDate(item?.created_at);

    return ( <>
            <List
                popupPrefix  = {'orderHistoryLog'}
                getListData  = {(d, s, f) => Application.log({id: appId}, s, f)}
                canDelete    = {() => false}
                cols         = {[
                    {name: 'is_success'   , title: 'успех'                , format: LIF.check, align: 'left'},
                    {name: 'created_at'   , title: 'дата'                 , format: LIF.text , align: 'left', customFormatHandler: dateFormat     },
                    {name: 'user_id'      , title: 'пользователь'         , format: LIF.text , align: 'left', customFormatHandler: userFormat     },
                    {name: 'action'       , title: 'действие'             , format: LIF.text , align: 'left', customFormatHandler: actionFormat   },

                    {name: 'status'       , title: 'статус старый > новый', format: LIF.text , align: 'left', customFormatHandler: statusFormat},
                    //{name: 'old_status_id', title: 'старый статус'        , format: LIF.text , align: 'left', customFormatHandler: oldStatusFormat},
                    //{name: 'new_status_id', title: 'новый статус'         , format: LIF.text , align: 'left', customFormatHandler: newStatusFormat},
                    //{name: 'system_info'  , title: 'системная информация', format: LIF.text , align: 'left'},
                    {name: 'comment'      , title: 'описание'             , format: LIF.text , align: 'left'},
                ]}
            />
        </>
    );

};

export default OrderHistoryLog;
