import { element } from 'models/elements.model';

const getParent = (
  elements: element[],
  element: element,
  realParent: element | undefined = undefined
): element | undefined => {
  if (element && elements?.length && !realParent) {
    const { id } = element;

    elements.forEach( (item) => {
      item.childrens.forEach( (child) => {
        if (child.elems.length && !realParent) {
          child.elems.forEach((elem) => {
            if (elem.id === id) {
              realParent = item;
            }
          });

          if (!realParent) {
            realParent = getParent(child.elems, element, realParent);
          }
        }
      });
    });
    return realParent;
  }
};

export default getParent;
